window.MenuLists = {
    cross: { title: '국내형', icon: 'fab fa-korvue', href: '/cross', isMobile: true},
    overseas: { title: '해외형', icon: 'fas fa-globe-americas', href: '/overseas', isMobile: true},
    special: { title: '스페셜', icon: 'fas fa-star', href: '/special', isMobile: true},
    special2: { title: '스페셜2', icon: 'fas fa-star', href: '/special2', isMobile: true},
    // inplay: { title: '인플레이', icon: 'fas fa-dice-d6', href: '/inplay', isMobile: true},
    casino: { title: '카지노/슬롯', icon: 'fas fa-chess-queen', href: '/casino/home', isMobile: true},
    'skypark-casino': { title: '스카이파크', icon: 'fas fa-chess-rook', href: '/skypark-casino/baccarat', isMobile: true},
    minigame: { title: '미니게임', icon: 'fas fa-gamepad', href: '/minigame/eos_powerball5', isMobile: true},
    bettinglist: { title: '배팅내역', icon: 'fas fa-list', href: '/bettinglist'},
    inquery: { title: '고객센터', icon: 'fas fa-comment', href: '/inquery'},
    notice: { title: '공지사항', icon: 'fas fa-clipboard', href: '/notice'},
    event: { title: '이벤트', icon: 'fas fa-gift', href: '/event'},
}

window.LeftMenuTop = {
}

window.DepositRolling = {
    overseas: { bonus: 100, noBonus: 100},
    overseas1: { bonus: 500, noBonus: 100},
    cross: { bonus: 100, noBonus: 100},
    cross1: { bonus: 500, noBonus: 100},
    special: { bonus: 200, noBonus: 100},
    special1: { bonus: 200, noBonus: 100},
    minigame: { bonus: 300, noBonus: 100},
}

window.CasinoMenus = {
    evolution: { img: '/img/home/title01_01.png' },
    pragmatic_casino: { img: '/img/home/title01_02.png' },
    TOMHORN_VIVO: { img: '/img/home/title01_03.png' },
    TOMHORN_PROVISION: { img: '/img/home/title01_04.png' },
    cq9_casino: { img: '/img/home/title01_05.png' },
    // SABONG: { img: '/img/home/title01_06.png', isExchange: true },
    // VOTA: { img: '/img/home/title01_07.png', isExchange: true },
    SEXYBCRT: { img: '/img/home/title01_08.png' },
}

window.SlotMenus = {
    netent: { text: '넷엔트', img: '/img/home/title02_01.png' },
    redtiger: { text: '레드타이거', img: '/img/home/title02_02.png' },
    pragmatic_slot: { text: '프라그매틱 슬롯', img: '/img/home/title02_03.png' },
    // 'pragmatic_fishing-game': { text: '프라그매틱 피싱게임', img: '/img/home/title02_04.png' },
    cq9: { text: '써큐나인', img: '/img/home/title02_05.png' },
    TOMHORN_PROVISION: { text: '프로비젼', img: '/img/home/title02_06.png', isExchange: true },
    PLAYNGO: { text: '플레이앤고', img: '/img/home/title02_07.png', isExchange: true },
    AWS: { text: '섹시', img: '/img/home/title02_08.png' },
}
