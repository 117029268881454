<template>
    <div class="top-menu-mobile-wrap">
        <button type="button" class="top-menu-prev"><i class="fas fa-chevron-left"></i></button>
        <button type="button" class="top-menu-next"><i class="fas fa-chevron-right"></i></button>
        <slick ref="slick" :options="slickOptions" class="top-menu-mobile">
            <template v-for="(menu, key) in menus">
                <a :key="key" v-if="menu.isMobile" :href="menu.href" :class="_get_active(key)">
                    {{ menu.title }}
                </a>
            </template>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        data: function () {
            return {
                menus : MenuLists,
                slickOptions: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: false,
                    nextArrow: '.top-menu-next',
                    prevArrow: '.top-menu-prev',
                    responsive: [
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4,
                            }
                        }
                    ],
                    // Any other options that can be got from plugin documentation
                },
            }
        },
        computed: {
            path1: function(){
                return location.pathname.split('/')[1];
            },
        },
        watch:{
        },
        mounted: function(){
        },
        methods: {
            _get_active: function(_path) {
                return this.path1 == _path ? 'active' : '';
            },
        },
        created () {
        },
        beforeDestroy () {
        },
        components: {
            Slick,
        },
    }
</script>
