<template>

    <div class="inquery-list">
        <ul @click="_getContent">
            <li><input type="checkbox"  :value="data.ID" @click.stop="" @change="_check($event)" :checked="_checked" ></li>
            <li><!-- <i class="far fa-file-alt notice" v-if="data.isNotice"></i> -->
                <span class="notice" v-if="data.isNotice">공지</span>
                {{ data.title }}<span class="answer-status" :class="_answerStatusClass" v-if="data.sendUser">{{ _answerStatus }}</span>
            </li>
            <li>{{ _showTime }}</li>
            <li :class="_statusClass">{{ _showStatus }}</li>
        </ul>
        <transition name="fade">
            <div class="content-wrp" v-show="showContent">
                <p v-html="content"></p>
                <p v-if="data.sendUser == 1" :class="[_canAnswer ? '' : 'answer-wait', 'answer']" v-html="_canAnswer ? answer : '답변 대기중입니다.'"></p>
            </div>
        </transition>
    </div>

</template>

<script>


    export default {
        props: ['data', 'selected'],
        data: function () {
            return {
                showContent: false, content: '', answer: '',
            }
        },
        computed: {
            _showStatus: function () {
                if(this.data.isRead == 1)
                    return '읽음';
                else if(this.data.isRead == 0)
                    return '읽지않음';
                else
                    return '답변대기';
            },
            _statusClass: function () {
                if(this.data.isRead == 1)
                    return 'blue-color';
                else if(this.data.isRead == 0)
                    return 'red-color';
            },
            _showTime: function () {
                return datetimeToMDHM(this.data.createdAt);
            },
            _checked: {
                get: function () {
                    return _.includes(this.selected, this.data.ID);
                },
            },
            _answerStatus: function () {
                if(this.data.isAnswer)
                    return '답변완료';
                else
                    return '답변대기';
            },
            _answerStatusClass: function () {
                if(this.data.isAnswer)
                    return 'blue';
                else
                    return 'red';
            },
            _canAnswer : function () {
                if(this.answer)
                    return true;
                else
                    return false;
            },
        },
        methods: {
            _check: function(event) {
                this.$emit('childs-check-change', event.target.checked, event.target.value);
            },
            _selectAll: function() {
                this.selected = [];
                if (!this.selectAll) {
                    this.selected = _.map(this.userdata.subData, 'ID');
                }
            },
            // _execDeposit: function(){
            //     var _plusCash = parseInt(this.plusCash.toString().replace(/[^0-9]/g, ''));

            //     if(!_plusCash || _plusCash < 5000)
            //         alert('충전금액을 재대로 입력해 주세요.');
            //     else{
            //         axios.post('/deposit', { plusCash: _plusCash, isBonus: this.isBonus })
            //         .then((response)=>{
            //             //this.console.log(response);
            //         })
            //     }
            // },
            _getContent: function(){

                if(!this.showContent){
                    showLoading();
                    axios.get(`/inquery/content/${this.data.ID}`)
                    .then((response)=>{
                        if(response.data.content){
                            this.content = response.data.content;
                            this.answer = response.data.answer;
                            this.data.isRead = 1;
                        }
                        else{
                            Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.'})
                        }
                        this.showContent = !this.showContent;
                        hideLoading();
                    })
                }else{
                    this.showContent = !this.showContent;
                }
            },
            closeForDelete: function(){
                // if(_.includes(this.selected, this.data.ID))
                    this.showContent = false;
            },
        },
        watch:{
        },
    }
</script>
