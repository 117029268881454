<template>
<div class="">
    <!-- <div class="box-header">
        <span class="header_title">
            <i class="fas fa-chess-queen"></i> 슬롯
        </span>
    </div> -->

    <div class="minigame-menu">
        <div class="minigame-main-menu">
            <ul>
                <li :class="{active: _mainMenu == 'casino'}">
                    <a href="/casino/home"><img src="/img/icon/sport/casino.png" width="19" /> 카지노</a>
                </li>
                <li :class="{active: _mainMenu == 'slot'}">
                    <a href="/slot/home"><img src="/img/icon/sport/slot.png" width="17" /> 슬롯</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="slot-search">
        <select v-model="selectedSlot" @change="selectedSlotChange" class="slot-select">
            <option value="all">벤더선택</option>
            <option v-for="(_list, key) in slotMenus" :value="key" :key="key">
                {{ _list.text }}
            </option>
        </select>

        <input type="text" v-model="search_text" placeholder="게임명"/>
        <button @click="exec_search"><i class="fas fa-search"></i></button>
    </div>

    <div class="casino-lists">
        <div v-for="(_list, key) in slotLists" :key="key" @click="_getJoinLink(_list[0], key)">
            <img :src="slotImgSrc(_list[4], _list[0], key)" :alt="_list[2]" @error="imgError">
            <div class="entering">입장하기</div>
        </div>
    </div>
</div>
</template>

<style>

</style>

<script>
    export default {
        data: function () {
            return {
                userData : userData,
                slotMenus : SlotMenus,
                slotData : null,
                selectedSlot: 'all',
                slotLists : null,
                search_text: '',
            }
        },
        components: {
        },
        computed: {
            _mainMenu: function(){
                return location.pathname.split('/')[1];
            },
        },
        methods:{
            _cash: function (_cash) {
                return numberWithComma(_cash);
            },
            _getJoinLink: function(_id, _key){
                SlotJoinLink(_id, { game_key : _key});
            },
            selectedSlotChange: function () {
                if(this.selectedSlot == 'all'){
                    // this.slotLists = [];
                    // _.forEach(this.slotData, (_val1, _key1) => {
                    //     if(_key1 in this.slotMenus){
                    //         _.forEach(_val1, (_val2) => {
                    //             this.slotLists.push(_val2);
                    //         });
                    //     }
                    // });
                    this.slotLists = this.slotData['pragmatic_slot'];

                }
                else
                    this.slotLists = this.slotData[this.selectedSlot];
            },
            slotImgSrc: function (_src, _vendor, _game_key) {
                if(_src)
                    return _src;

                return `/img/slot/${_vendor}/${_game_key}.png`;
            },
            imgError : function(_event){
                console.log(_event.target.alt);
                console.log(_event.target.parentElement);

                var parentTag = _event.target.parentElement.innerHTML
                var brokenImageTag = _event.target.outerHTML
                var _title = _event.target.alt;
                var _fontsize = '3vw';
                if(_title.length > 25)
                    _fontsize = '1.5vw';
                else if(_title.length > 10)
                    _fontsize = '2vw';

                _event.target.parentElement.innerHTML = parentTag.replace(brokenImageTag, `<div class="empty-img" style="font-size: ${_fontsize}">${_title}</div>`)
                // _event.target.parentElement.innerHTML = parentTag.replace(brokenImageTag, `<svg viewBox="0 0 56 18"><text x="0" y="15">${_title}</text></svg>`)
            },
            exec_search : function(){
                if(this.search_text){
                    this.slotLists = {};
                    _.forEach(this.slotData, (_val1, _key1) => {
                        if(_key1 in this.slotMenus){
                            let _ret = _.pickBy(_val1, (o) => {
                                // console.log(o[2]);
                                return o[2].toLowerCase().includes(this.search_text.toLowerCase()) || o[3].toLowerCase().includes(this.search_text.toLowerCase());
                            });
                            _.assign(this.slotLists, _ret);
                            // _.forEach(_val1, (_val2) => {
                            //     this.slotLists.push(_val2);
                            // });
                        }
                    });
                }else{
                    this.selectedSlotChange();
                }
            },
        },
        watch: {
            search_text: {
                handler: _.throttle(function() {
                    this.exec_search();
                }, 150),
            },
        },
        created: function(){
            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('s'))
                this.selectedSlot = urlParams.get('s');
            else
                this.selectedSlot = 'all';
        },
        mounted: function () {
            axios.get("/data/game.txt")
            .then((response) => {
                this.slotData = response.data;
                this.selectedSlotChange();
            })
        }
    }
</script>
