<template>
<div class="gamelists">
    <div class="box-header"><i class="fas fa-stream"></i> 인플레이</div>
    <SlickSport></SlickSport>
    <div class="search">
        <select @change="maintype_change" v-model="maintype">
            <option value="1" selected>승무패</option>
            <option value="2">핸디캡</option>
            <option value="3">언더오버</option>
        </select>
        <input type="text" v-model.lazy="search_text" placeholder="리그명 또는 팀명"/>
        <button @click="_exec_get"><i class="fas fa-search"></i></button>
    </div>
    <div class="inplay" ref="inplay">
        <div class="inplay-first">
            <Inplay v-if="selectedGameList" ref="inplayShow" :data="selectedGameList" :extData="getExtData(selectedGameList.ID)"></Inplay>
            <div class="select-list"  v-if="selectedGameList" >
                <GameBetList v-for="bet in selectedGameListbetGroup" :key="bet.betKind" :data="bet" :gameList="selectedGameList" :iconType="2"></GameBetList>
            </div>
        </div>
        <div>
            <GameList v-for="(d) in data.data" @selectGameList="selectGameList" :key="d.ID" :data="d" :extData="getExtData(d.ID)"  :maintype="maintype" :plusSec="plusSec"></GameList>
            <div class="empty" v-show="data.data.length == 0">
                진행중인 경기가 없습니다
            </div>
        </div>
    </div>

    <pagination :pagination="this.data.meta" @paginate="_exec_get()"></pagination>
</div>
</template>

<script>
    import GameList from './List';
    import SlickSport from '../SlickSport';

    export default {
        props: ['plusSec'],
        data: function () {
            return {
                data,
                stats: null,
                ani_num: true,
                search_text: '',
                selectedGameList : null,
                maintype: 1,
                rcount:0,
            }
        },
        components: {
            GameList, SlickSport
        },
        computed: {
            _gameLists: function(){
                return _.filter(this.data.data, function(gameList) { return gameList.game_count >= 0; });;
            },
            selectedGameListbetGroup: function(){
                return _.chain(_.reject(this.selectedGameList.games, {'vi' : 0}))
                .groupBy("betKind")
                .map((value, key) => ({ betKind: key, games: value }))
                .value();
            },
        },
        methods: {
            _exec_get: function() {
                if(!this.getting){
                    this.getting = true;
                    showLoading();
                    axios.get(`${location.pathname}?page=${this.data.meta.current_page}${this.search_text ? '&search_text='+this.search_text : ''}`)
                    .then((response)=>{
                        if(response.data.meta)
                            this.data = response.data;
                    })
                    .finally(() => {
                        this.getting = false;
                        hideLoading();
                        window.scrollTo(null, 0);
                    });
                }
            },
            selectGameList: function(_gameLIst) {

                if(this.selectedGameList != _gameLIst)
                {
                    // showLoading();
                    if(this.selectedGameList)
                        this.$refs['inplayShow'].inplayLeave();
                    this.$set(_gameLIst, 'stats', null);
                    this.selectedGameList = _gameLIst;
                    axios.get(`/games/${this.selectedGameList.ID}`)
                    .then((response)=>{
                        //console.log(response);
                        if(response.data.result == 1){
                            this.selectedGameList.games = response.data.data;

                            if(response.data.stats)
                                this.$refs['inplayShow'].PushStat(response.data.stats);

                            this.scrollMeTo('inplay');
                        }else {
                            Swal.fire({icon: 'error', title: '데이터를 가져오지 못했습니다.'})
                        }
                    })
                    // .finally(() => {
                    //     hideLoading();
                    // });
                }
            },
            getExtData: function(_gameListID){
                return _.find(this.data.ext, {'ID' : _gameListID});
            },
            scrollMeTo(refName) {
                let _el = this.$refs[refName];
                if(_el)
                    _el.scrollIntoView({ behavior: 'smooth' })
            },
            maintype_change: function() {
                this.$cookie.set('inplay.maintype', this.maintype);
            },
        },
        watch: {
            search_text: {
                handler: _.throttle(function() {
                    this.data.meta.current_page = 1;
                    this._exec_get();
                }, 150),
            },
        },
        created: function () {

            this.maintype = this.$cookie.get('inplay.maintype');
            if(this.maintype == null)
                this.maintype = 1;

            Echo.channel('updated').listen('.inplay', (e) => {

                _.forEach(e.data, (dVal, dKey)=> {
                    console.log(++this.rcount);
                    // console.log(dKey, dVal);
                    if(dKey == 'GL'){
                        _.forEach(dVal, (val)=> {
                            if(val.st == 'D'){
                                let idx = _.findIndex(this.data.data, _gamelist => _gamelist.ID ==  val.ID);
                                if(idx > -1){
                                    this.data.data.splice(idx, 1);
                                    if(this.selectedGameList && this.selectedGameList.ID == val.ID)
                                        this.selectedGameList = null;
                                }
                            }
                        });
                    }
                    else if(dKey == 'GA'){
                        _.forEach(dVal, (value)=> {
                            // console.log(value);
                            let gameList = _.find(this.data.data, (gameList) => { return gameList.ID == value.PID; });
                            if(gameList){
                                let game_idx = _.findIndex(gameList.games, (_game) => { return _game.ID == value.ID; });
                                if(game_idx > -1 && (value.vi == 0 || value.vi == false)){
                                    gameList.games.splice(game_idx, 1);
                                }else if(game_idx > -1){
                                    gameList.games[game_idx].oddsHome = typeof value.oddsHome == 'number' ? value.oddsHome.toFixed(2) : value.oddsHome;
                                    gameList.games[game_idx].oddsDraw = typeof value.oddsDraw == 'number' ? value.oddsDraw.toFixed(2) : value.oddsDraw;
                                    gameList.games[game_idx].oddsAway = typeof value.oddsAway == 'number' ? value.oddsAway.toFixed(2) : value.oddsAway;

                                }else{
                                    gameList.games.push(value);
                                }
                            }

                            let cart_idx = _.findIndex(cartData.cart, (_cart) => { return _cart.game.ID == value.ID; });
                            if(cart_idx > -1 && (value.vi == 0 || value.vi == false)){
                                cartData.cart.splice(cart_idx, 1);
                            }else if(cart_idx > -1){
                                cartData.cart[cart_idx].game.oddsHome = typeof value.oddsHome == 'number' ? value.oddsHome.toFixed(2) : value.oddsHome;
                                cartData.cart[cart_idx].game.oddsDraw = typeof value.oddsDraw == 'number' ? value.oddsDraw.toFixed(2) : value.oddsDraw;
                                cartData.cart[cart_idx].game.oddsAway = typeof value.oddsAway == 'number' ? value.oddsAway.toFixed(2) : value.oddsAway;
                            }
                        });
                    }else if(dKey == 'ST'){
                        if(this.selectedGameList){
                            let _stats = _.find(dVal, { 'ID' : this.selectedGameList.ID });
                            if(_stats)
                                this.$refs['inplayShow'].PushStat(_stats);
                        }
                    }else if(dKey == 'SC'){
                        _.forEach(dVal, (val) => {
                            let _ext = _.find(this.data.ext, (ext) => { return ext.ID == val.ID; });

                            if(_ext){
                                _ext.D = val.D;
                                _ext.M = val.M;
                                _ext.P = val.P;
                                _ext.S = val.S;
                                _ext.SC = val.SC;
                                _ext.SS = val.SS;
                                _ext.T = val.T;
                                _ext.PE = val.PE;
                                _ext.A = val.A;
                            }
                        });
                    }
                });

            });


        }
    }
</script>
