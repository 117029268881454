<template>
    <div class="gamelist">
        <div class="league">
            <span v-if="_isMinigame">
                <img width="12" :src="_imageUrl" /> {{ _miniGameName }}
                <i class="fas fa-angle-double-right"></i> {{ data.GameList.sportSubID }} 회차
            </span>
            <span v-else>
                <img width="12" :src="`/img/icon/sport/${data.GameList.sportID}.png`" /> {{ _showSportName }}
                <i class="fas fa-angle-double-right"></i>
                <div class="flag">
                    <img class="leg_img" width="18" :src="_imageUrl" />
                    <div  class="flag-wave"></div>
                </div>
                {{ data.GameList.leagueName.name }}
                <!-- <i class="fas fa-angle-double-right"></i>
                {{ data.GameList.homeName.name }} <b>VS</b> {{ data.GameList.awayName.name }} -->
                <b v-if="_getFinishResult">점수 {{ _getFinishResult }}</b>
            </span>

            <span class="time">{{ _startTime }}</span>
        </div>

        <GameBetList :data="{'betKind': data.betKind, games: [data]}" :gameList="data.GameList" :isBettingList="true" :isMainBet="true" :showBetName="true" :iconType="2"></GameBetList>
    </div>

</template>

<script>

    export default {
        props: ['data', 'plusSec'],
        computed: {
            _showSportName: function () {
                return getSportName(this.data.GameList.sportID);
            },
            _imageUrl: function(){
                if(this._isMinigame){
                    if(this.data.GameList.sportID == 601 || this.data.GameList.sportID == 602 || this.data.GameList.sportID == 603 || this.data.GameList.sportID == 604)
                        return '/img/icon/sport/ntry.png';
                    else if(this.data.GameList.sportID == 611 || this.data.GameList.sportID == 612)
                        return '/img/icon/sport/livescore.png';
                    else if(this.data.GameList.sportID == 621 || this.data.GameList.sportID == 622)
                        return '/img/icon/sport/eos.svg';
                    else if(this.data.GameList.sportID == 701)
                        return '/img/icon/sport/powerballspeed.png';
                }else{
                    return '/img/flag/league/' + ((this.data.GameList.leagueName.flag == null) ? 'world.svg' : this.data.GameList.leagueName.flag);
                }
                return '';
            },
            _startTime: function () {
                return getStartTime(this.data.GameList.startTime, this.data.GameList.sportID, this.plusSec[this.data.GameList.sportID]);
            },
            _isMinigame: function () {
                if(getMiniGameName('', this.data.GameList.sportID))
                    return true;
                else
                    return false;
            },
            _miniGameName: function () {
                return getMiniGameName('', this.data.GameList.sportID);
            },
            _getFinishResult: function(){
                let _result =  this.data.GameListResult;

                if(_result && _result.home != null && _result.away != null)
                    return ' ' + _result.home + ' : ' + _result.away + ' ';
            },
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToMDHM(_time);
            },
            _betActive: function(_gameID, _betTeam){
                return _.some(cartData.cart, { gameID : _gameID, betTeam : _betTeam });
            },
            _classBet: function(_odds){
                return _odds != null && _odds != 0;
            },
            _getOddsName: function (_game, _betTeam) {
                //getOddsName = function (sportID, betKind, betTeam, homeName, awayName, point){

                return getOddsName(this.data.GameList.sportID, this.data.betKind, _betTeam, '홈', '원정', _game.point, _game.etc);
            },
            _sameScore: function(_score){
                var splitScore = _.split(_score, '-');
                if(splitScore.length == 2){
                    if(splitScore[0] == splitScore[1])
                        return 'same';
                    else
                        return 'noSame';
                }
                return '';
            },
            _showOdds: function(_data, _betTeam){
                if(_betTeam == 1)
                    return _data.oddsHome;
                else if(_betTeam == 2)
                    return _data.oddsDraw;
                else if(_betTeam == 3)
                    return _data.oddsAway;
            },
            _replaceScore: function(_score, _betTeam){
                if(_betTeam == 3){
                    var splitScore = _.split(_score, '-');
                    if(splitScore.length == 2){
                        return splitScore[1] + ':' + splitScore[0];
                    }
                }
                else
                    return _.replace(_score, '-', ':');
                return '';
            },
            _bettingResultToClass: function(_result){
                if(_result == null)
                    return "wait";
                else if(_result == 1){
                    return "hit";
                }else if(_result == 2){
                    return "nohit";
                }else if(_result == 4){
                    return "cancle";
                }else{
                    return "";
                }
            },
            _bettingResultToString: function(_result){
                if(_result == null)
                    return "대기중";
                else if(_result == 1){
                    return "적중";
                }else if(_result == 2){
                    return "미적중";
                }else if(_result == 4){
                    return "적특";
                }else{
                    return "대기중";
                }
            },
        },
        components: {
        },
    }
</script>
