<template>
    <div class="">
        <div class="box-header"><i class="far fa-question-circle"></i> 고객센터</div>

        <div class="userinfo-sub inquery-lists">
            <div class="inquery-list title">
                <ul>
                    <li><input type="checkbox" v-model="selectAll" @click="_selectAll"></li>
                    <li>제목</li>
                    <li>작성일자</li>
                    <li>상태</li>
                </ul>
            </div>
            <InqueryList v-for="(data, index) in userdata.subData.data" :data="data" :selected="selected" :key="index" @childs-check-change="childsCheckChange" ref="inqueries"></InqueryList>
            <div class="empty" v-show="userdata.subData.length == 0">
                문의 내역이 없습니다.
            </div>
        </div>

        <pagination :pagination="this.userdata.subData.meta" @paginate="_exec_get()"></pagination>

        <div class="bottom-btn-wrap">
            <div class="left-btn-wrap">
                <button class="blue" type="button" @click="_execAllRead()"><i class="far fa-envelope-open"></i> 전체읽기</button>
                <button class="red" type="button" @click="_execDelete()"><i class="far fa-trash-alt"></i> 선택삭제</button>
            </div>
            <div class="right-btn-wrap">
                <a href="/inquery/write">
                    <button class="blue" type="button"><i class="far fa-edit"></i> 문의하기</button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

    import InqueryList from './InqueryList';

    export default {

        data: function () {
            return {
                userdata: data, selected: [], selectAll: false
            }
        },
        computed: {
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _showStatus: function (_data) {
                if(_data.isRead == 1)
                    return '읽음';
                else if(_data.isRead == 0)
                    return '읽지않음';
                else
                    return '답변대기';
            },
            _selectAll: function() {
                this.selected = [];
                if (!this.selectAll) {
                    this.selected = _.map(this.userdata.subData.data, 'ID');
                }
            },
            childsCheckChange: function(checked, value){
                if(checked)
                    this.selected.push(parseInt(value));
                else{
                    const idx = this.selected.indexOf(parseInt(value));
                    if (idx > -1)
                        this.selected.splice(idx, 1)
                }

            },
            _execAllRead: function() {
                showLoading();
                axios.patch('/inquery/read/all')
                .then((response)=>{
                    if(response.data.result == 1){
                        if(response.data.data)
                            this.userdata.subData = response.data.data;
                        Swal.fire({icon: 'success', title: '전체 읽기 완료'})
                    }
                    else
                    {
                        Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[001]'})
                    }
                })
                .catch(function () {
                    Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[002]'})
                })
                .finally(function () {
                    hideLoading();
                });
            },
            _execDelete: function() {
                showLoading();
                axios.delete('/inquery', { data: {inqueryIDs: this.selected} })
                .then((response)=>{
                    if(response.data.result == 1){
                        this.$refs.inqueries.forEach(element => {
                            element.closeForDelete();
                        });
                        data.subData = response.data.data;
                        this.userdata = data;
                        Swal.fire({icon: 'success', title: '선택 삭제 완료'})
                    }
                    else
                    {
                        Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[001]'})
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[002]'})
                })
                .finally(function () {
                    hideLoading();
                });
            },
            _getContent: function(_data){
                _data.showContent = false;
                _data.showContent = true;
                // axios.get(`/inquery/content/${_data.ID}`)
                // .then((response)=>{

                // })
            },
             _exec_get: function() {
                showLoading();
                    axios.get('/inquery/pgae?page=' + this.userdata.subData.meta.current_page)
                .then((response)=>{
                    //console.log(response);
                    data.subData = response.data;
                    this.userdata = data;
                    hideLoading();
                })
            },

        },
        watch:{
        },
        components: {
            InqueryList,
        },
    }
</script>
