<template>
    <svg class="lm_iceHockey_svg" viewBox="0 0 400 185"  style="width:100%" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <radialGradient id="black-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
            <stop offset="0%" style="stop-color: #000;stop-opacity: 0.1;" />
            <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
        </radialGradient>
        <filter id='inset-shadow'>
            <feOffset dx='0' dy='0' />
            <feGaussianBlur stdDeviation='10' result='offset-blur' />
            <feComposite operator='out' in='SourceGraphic' in2='offset-blur' result='inverse' />
            <feFlood flood-color='black' flood-opacity='.85' result='color' />
            <feComposite operator='in' in='color' in2='inverse' result='shadow' />
            <feComposite operator='over' in='shadow' in2='SourceGraphic' />
        </filter>
    </defs>


    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
        <rect fill="#f0faff" x="1.7" y="1.5" width="396.7" height="182.1" rx="34" filter="url(#inset-shadow)"></rect>
        <rect id="rink" fill="none" x="0.5" y="0.5" width="399" height="184" rx="35" style="stroke-width: 2px; stroke: rgb(170 203 69);"></rect>
        <rect fill="#df7a8fcc" x="199" width="2" height="182" y="1.5" style=""></rect>
        <circle stroke="#4096d3cc" cx="200" cy="92.5" r="31"></circle>
        <circle cx="200" cy="92.5" r="2.5" fill="#4096d3cc"></circle>
        <g>
            <rect y="1.5" width="2" height="182" x="153" fill="#4096d3cc"></rect>
            <path d="M 24 3.109 L 26 2.5 L 26 182.5 L 24 181.891 L 24 3.109 Z" fill="#df7a8fcc"></path>
            <circle id="ml17-FaceOff_Position3Markings" fill="#df7a8fcc" cx="166.5" cy="46.5" r="2.5"></circle>
            <circle id="ml17-FaceOff_Position4Markings" fill="#df7a8fcc" cx="166.5" cy="139" r="2.5" style=""></circle>
            <g transform="matrix(1, 0, 0, 1, 16, 3.5)" style="">
                <path id="ml17-Penalty_Team2Goal" d="M9,83 L9,81 L3,81 C1.3,81 0,82 0,84 L0,94 C0,95.7 1,97 3,97 L9,97 L9,95 L3,95 C2.5,95 2,95 2,94 L2,84 C2,83.4 2.5,83 3,83 L9,83 Z" fill="#df7a8f80"></path>
                <path id="ml17-Penalty_Team2Area" style="stroke: #df7a8f80; fill: #4096d34d;" d="M 10 102 C 16.6 102 21.3 103.5 21.34 89.22 C 21.3 74.9 16.6 76 10 76"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 43, 11)" style="">
                <circle fill="#df7a8fcc" cx="31.5" cy="36" r="2.5"></circle>
                <circle stroke="#df7a8fcc" cx="31.5" cy="36" r="31"></circle>
                <path stroke="#df7a8fcc" d="M 19.595 32.492 L 27.335 32.5 L 27.335 28"></path>
                <path stroke="#df7a8fcc" d="M 35.67 28.008 L 43.41 28 L 43.41 32.5" transform="matrix(-1, 0, 0, -1, 79.079998, 60.5)"></path>
                <path stroke="#df7a8fcc" d="M 19.5 38.49 L 27.24 38.482 L 27.24 42.982"></path>
                <path stroke="#df7a8fcc" d="M 35.575 42.974 L 43.315 42.982 L 43.315 38.482" transform="matrix(-1, 0, 0, -1, 78.889999, 81.463997)"></path>
                <path stroke="#df7a8fcc" d="M 27.435 0.2 L 27.475 5.225"></path>
                <path stroke="#df7a8fcc" d="M 35.7 0.2 L 35.74 5.225"></path>
                <path stroke="#df7a8fcc" d="M 27.295 66.905 L 27.335 71.93"></path>
                <path stroke="#df7a8fcc" d="M 35.5 66.9 L 35.54 71.925"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 43, 103)" style="">
                <circle fill="#df7a8fcc" cx="31.5" cy="36" r="2.5"></circle>
                <circle stroke="#df7a8fcc" cx="31.5" cy="36" r="31"></circle>
                <path stroke="#df7a8fcc" d="M 19.595 32.492 L 27.335 32.5 L 27.335 28"></path>
                <path stroke="#df7a8fcc" d="M 35.67 28.008 L 43.41 28 L 43.41 32.5" transform="matrix(-1, 0, 0, -1, 79.079998, 60.5)"></path>
                <path stroke="#df7a8fcc" d="M 19.5 38.49 L 27.24 38.482 L 27.24 42.982"></path>
                <path stroke="#df7a8fcc" d="M 35.575 42.974 L 43.315 42.982 L 43.315 38.482" transform="matrix(-1, 0, 0, -1, 78.889999, 81.463997)"></path>
                <path stroke="#df7a8fcc" d="M 27.435 0.2 L 27.475 5.225"></path>
                <path stroke="#df7a8fcc" d="M 35.7 0.2 L 35.74 5.225"></path>
                <path stroke="#df7a8fcc" d="M 27.295 66.905 L 27.335 71.93"></path>
                <path stroke="#df7a8fcc" d="M 35.5 66.9 L 35.54 71.925"></path>
            </g>
        </g>
        <g transform="matrix(-1, 0, 0, 1, 400, 0)">
            <rect y="1.5" width="2" height="182" x="153" fill="#4096d3cc"></rect>
            <path d="M 24 3.109 L 26 2.5 L 26 182.5 L 24 181.891 L 24 3.109 Z" fill="#df7a8fcc"></path>
            <circle id="circle-1" fill="#df7a8fcc" cx="166.5" cy="46.5" r="2.5"></circle>
            <circle id="circle-2" fill="#df7a8fcc" cx="166.5" cy="139" r="2.5" style=""></circle>
            <g transform="matrix(1, 0, 0, 1, 16, 3.5)" style="">
                <path id="path-1" d="M9,83 L9,81 L3,81 C1.3,81 0,82 0,84 L0,94 C0,95.7 1,97 3,97 L9,97 L9,95 L3,95 C2.5,95 2,95 2,94 L2,84 C2,83.4 2.5,83 3,83 L9,83 Z" fill="#df7a8f80"></path>
                <path id="path-2" style="stroke: #df7a8f80; fill: #4096d34d;" d="M 10 102 C 16.6 102 21.3 103.5 21.34 89.22 C 21.3 74.9 16.6 76 10 76"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 43, 11)" style="">
                <circle fill="#df7a8fcc" cx="31.5" cy="36" r="2.5"></circle>
                <circle stroke="#df7a8fcc" cx="31.5" cy="36" r="31"></circle>
                <path stroke="#df7a8fcc" d="M 19.595 32.492 L 27.335 32.5 L 27.335 28"></path>
                <path stroke="#df7a8fcc" d="M 35.67 28.008 L 43.41 28 L 43.41 32.5" transform="matrix(-1, 0, 0, -1, 79.079998, 60.5)"></path>
                <path stroke="#df7a8fcc" d="M 19.5 38.49 L 27.24 38.482 L 27.24 42.982"></path>
                <path stroke="#df7a8fcc" d="M 35.575 42.974 L 43.315 42.982 L 43.315 38.482" transform="matrix(-1, 0, 0, -1, 78.889999, 81.463997)"></path>
                <path stroke="#df7a8fcc" d="M 27.435 0.2 L 27.475 5.225"></path>
                <path stroke="#df7a8fcc" d="M 35.7 0.2 L 35.74 5.225"></path>
                <path stroke="#df7a8fcc" d="M 27.295 66.905 L 27.335 71.93"></path>
                <path stroke="#df7a8fcc" d="M 35.5 66.9 L 35.54 71.925"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 43, 102.8)" style="">
                <circle fill="#df7a8fcc" cx="31.5" cy="36" r="2.5"></circle>
                <circle stroke="#df7a8fcc" cx="31.5" cy="36" r="31"></circle>
                <path stroke="#df7a8fcc" d="M 19.595 32.492 L 27.335 32.5 L 27.335 28"></path>
                <path stroke="#df7a8fcc" d="M 35.67 28.008 L 43.41 28 L 43.41 32.5" transform="matrix(-1, 0, 0, -1, 79.079998, 60.5)"></path>
                <path stroke="#df7a8fcc" d="M 19.5 38.49 L 27.24 38.482 L 27.24 42.982"></path>
                <path stroke="#df7a8fcc" d="M 35.575 42.974 L 43.315 42.982 L 43.315 38.482" transform="matrix(-1, 0, 0, -1, 78.889999, 81.463997)"></path>
                <path stroke="#df7a8fcc" d="M 27.435 0.2 L 27.475 5.225"></path>
                <path stroke="#df7a8fcc" d="M 35.7 0.2 L 35.74 5.225"></path>
                <path stroke="#df7a8fcc" d="M 27.295 66.905 L 27.335 71.93"></path>
                <path stroke="#df7a8fcc" d="M 35.5 66.9 L 35.54 71.925"></path>
            </g>
        </g>
        <circle cx="200" cy="92" r="70" fill="url(#black-rg)"></circle>
        <circle cx="79" cy="47" r="60" fill="url(#black-rg)"></circle>
        <circle cx="79" cy="139" r="60" fill="url(#black-rg)"></circle>
        <circle cx="320" cy="47" r="60" fill="url(#black-rg)"></circle>
        <circle cx="320" cy="139" r="60" fill="url(#black-rg)"></circle>
    </g>
</svg>
</template>

<script>
    export default {
        props: ['stats'],
        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {
        },
        watch: {
        },
    }
</script>
