<template>
    <div class="home-content">
        <slick ref="slick" :options="slickOptions" class="home-slider">
            <div class="item">
                 <img src="/img/home/main_bg_01.jpg" alt="">
                 <div class="img_box_wap">
                    <div class="img_bt_box">
                        <h3>
                           EVENT
                            <strong>다양한 이벤트</strong>
                        </h3>
                        <p>
                           7일출석이벤트 /20일출석이벤트<br>
                            지인추천이벤트/위로금이벤트
                            한폴낙 이벤트 등<br>
                        </p>
                        <a href="/event">이벤트 바로가기</a>
                    </div>
                 </div>
            </div>
            <div class="item">
                <img src="/img/home/main_bg_02.jpg" alt="">
                <div class="img_box_wap">
                  <div class="img_bt_box">
                        <h3>
                            ACCOUNT
                            <strong>충전 계좌 변경</strong>
                        </h3>
                        <p><br>
                            충전계좌는 <span>수시로 변경</span>될 수 있습니다.<br>
                            <span>입금 전 항상 계좌 문의</span>를 해주시고 당사의
                            층잔 계좌를 확인하신 후 <span>충전 신청</span>을 해주시길 바랍니다.
                        </p>
                        <a href="/deposit">계좌 문의하기</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <img src="/img/home/main_bg_03.jpg" alt="">
                <div class="img_box_wap">
                    <div class="img_bt_box">
                        <h3>
                            FIRST DEPOSIT
                            <strong>가입 첫충 이벤트</strong>
                        </h3>
                        <p><br>
                            <b>가입 첫충 30%</b> 지급<br>
                            또는 <b>3+1, 5+2, 7++3, 10+4, 20+7, 30+10</b>지급
                            (최대 10만 포인트까지 지급<br>
                            <span>돌발 이번트 하루 2회 상시 진행중!!!</span>
                        </p>
                        <a href="/event">이벤트 바로가기</a>
                    </div>
                 </div>
            </div>
        </slick>
<!--
        <div class="home-sub1">
            <div>
                <img src="/img/home/home04.jpg" alt="">
                <a href="/overseas/1">축구 바로가기</a>
            </div><div>
                <img src="/img/home/home05.jpg" alt="">
                <a href="/overseas/2">야구 바로가기</a>
            </div>
        </div> -->

        <div class="home-sub2">
            <ul>
                <a href="/cross">
                    <li>
                        <div>
                            <img src="/img/home/img_minigame_01.jpg" alt="">
                                <h3>
                                    <strong>국내형 스포츠</strong>
                                </h3>
                                <a href="/cross"><span class="bottom_text">CROSS <span class="text_orang">SPORTS</span></span></a>
                        </div>
                    </li>
                </a>
               <a href="/overseas">
                   <li>
                        <div>
                            <img src="/img/home/img_minigame_02.jpg" alt="">
                                <h3>
                                    <strong>해외형 스포츠</strong>
                                </h3>
                                <a href="/overseas"><span class="bottom_text">WORLD <span class="text_orang">SPORTS</span></span></a>
                        </div>
                    </li>
               </a>
                <a href="/casino/home">
                   <li>
                    <div>
                        <img src="/img/home/img_minigame_03.jpg" alt="">
                            <h3>
                                <strong>카지노</strong>
                            </h3>
                            <a href="/casino/home"><span class="bottom_text">CASINO <span class="text_orang">GAME</span></span></a>
                    </div>
                </li>
                </a>
                <a href="/minigame/eos_powerball5">
                  <li>
                        <div>
                            <img src="/img/home/img_minigame_04.jpg" alt="">
                                <h3>
                                    <strong>미니게임</strong>
                                </h3>
                                <a href="/minigame/eos_powerball5"><span class="bottom_text">MINI <span class="text_orang">GAME</span></span></a>
                        </div>
                    </li>
                </a>
            </ul>
        </div>

        <div class="popup_layer">
            <div class="home_popup" v-show="left_popup">
                <div>
                    <button @click="popup_close(1)"><i class="far fa-times-circle"></i></button>
                </div>
                <div>
                    <img src="img/home/popup_1.jpg"/>
                    <!-- <img src="/img/logo.png"/>
                    안녕하세요 .. MERITZ 운영팀입니다

                    최근 저희 사이트 명성을 이용하여 <b class="point1">사칭한 사이트</b>가 확인되고 있습니다.

                    1. MERITZ 기존 팀장 및 이벤트라는 식으로 회원님들께 연락을 합니다
                    2. Big 이벤트를 내세워 회원님들께 가입을 유도합니다.
                    3. MERITZ 대한 허위사실을 알리고 더 좋은 안전 사이트가 있다는 식으로 가입 유도를 합니다.

                    저희 MERITZ 오직 본 사이트만 운영 중에 있으며, 사칭 사이트 <b class="point1">제보 시 사례</b>하겠습니다 -->
                </div>
            </div>

            <div class="home_popup" v-show="right_popup">
                <div>
                    <button @click="popup_close(2)"><i class="far fa-times-circle"></i></button>
                </div>
                <div>
                    <img src="img/home/popup_2.jpg"/>
                    <!-- <img src="/img/logo.png"/>

                    <b class="point1 big1">사이트 사칭 주의!!!</b>

                    안녕하세요 MERITZ 운영팀입니다.

                    최근 저희 사이트 네임드를 이용한 사칭사이트가 확인되고 있습니다.

                    저희 MERITZ 오직 본 사이트만 운영중에 있으며, 별도의 계열을 운영중이지 않는점 유의 해 주시기 바랍니다.

                    <b class="point1">※ 사칭 사이트에 대한 피해는 당사는 보상을 해드리지 못하는 점 참고하시길 바랍니다</b> -->
                </div>
            </div>
        </div>
        <Foterimg></Foterimg>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import Footer from './Common/footer.vue';
    import Foterimg from './Common/footer.vue';
    export default {
        data: function () {
            return {
                slickOptions: {
                    arrows: false,
                    slidesToShow: 1,
                    autoplay: false,
                    autoplaySpeed: 3000,
                    dots : true,
                    customPaging : function(slick,index) {
                        return '<button class="slider-dot"></button>';
                    }
                }, left_popup : true, right_popup : true,
            }
        },
        computed: {

        },
        watch:{

        },
        methods: {
            _popup_repair: function(){
                Swal.fire({icon: 'warning', title: '점검중 입니다.'})
            },
            popup_close: function(_index){
                if(_index == 1){
                    this.left_popup = false;
                    this.$cookie.set('left_popup', 1, { expires: '12h' });
                }
                else if(_index == 2){
                    this.right_popup = false;
                    this.$cookie.set('right_popup', 1, { expires: '12h' });
                }
            },
            _getLink: function(_id){
                CasinoGetLink(_id)
            },
        },
        created: function () {
            this.left_popup = (this.$cookie.get('left_popup') == 1 ? false : true);
            this.right_popup = (this.$cookie.get('right_popup') == 1 ? false : true);
        },
        beforeDestroy () {

        },
        components: {
            Slick,
                Footer,Foterimg
        },
    }
</script>
