<template>
    <div class="">
        <div class="box-header"><i class="far fa-edit"></i> 고객센터 - 문의하기</div>

        <div class="inquery-write">
            <div class="title-wrap">
                <input type="text" v-tooltip="{content: form.errors.get('title'), show: form.errors.has('title'), trigger: 'manual', placement: 'bottom',}" @focus="form.errors.clear('title')"
                    v-model="form.title" placeholder="제목을 입력 하세요." :class="{'is-invalid': form.errors.has('title')}">
            </div>
            <div class="content-wrp">
                <textarea  v-tooltip="{content: form.errors.get('content'), show: form.errors.has('content'), trigger: 'manual', placement: 'bottom',}" @focus="form.errors.clear('content')"
                    v-model="form.content" placeholder="내용을 입력 하세요." :class="{'is-invalid': form.errors.has('content')}">
                </textarea>
            </div>
        </div>

        <div class="center-btn-wrap">
            <a href="javascript:history.back();">
                <button class="blue" type="button" @click="_execDelete()"><i class="fas fa-ban"></i> 취소하기</button>
            </a>
            <button class="red" type="button" @click="_create"><i class="far fa-edit"></i> 문의하기</button>
        </div>
    </div>
</template>

<script>


    export default {

        data: function () {
            return {
                form: new Form({
                    title: '',
                    content: '',
                }),
            }
        },
        computed: {
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _create: function () {
                // console.log(this.form.title.length)
                if(this.form.title.length < 2)
                    this.form.errors.set('title', '제목은 최소 2자 이상이어야 합니다.');
                else if(this.form.title.length > 255)
                    this.form.errors.set('title', '제목은 최소 255자 이하이어야 합니다.');
                else if(this.form.content.length < 4)
                    this.form.errors.set('title', '내용은 최소 4자 이상이어야 합니다.');
                else{
                    showLoading();
                    this.form.post(`${location.pathname}`)
                    .then((response)=>{
                        //console.log(response);
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.'})
                        }
                        else if(response.data.result == 1){
                            // data = response.data.data;
                            // this.myData = data;
                            Swal.fire({icon: 'success', title: '문의가 완료되었습니다.'})
                            .then((result) => {
                                //history.back();
                                window.location.href = '/inquery';
                            })
                        }
                    })
                    .finally(function () {
                        hideLoading();
                    });
                }
            },


        },
        watch:{
        },
    }
</script>
