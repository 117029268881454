<template>

    <div class="bet-list">
        <template v-if="_getBetKind == 'point' || _getBetKind == 'wdl' || _getBetKind == 'wl' || _getBetKind == 'yesno'">
            <div class="subtitle" v-if="isMainBet != true"><i v-if="icoSubTitle" :class="icoSubTitle"></i>{{_getBetName}}</div>
            <div class="subGameLists">

                <ul class="subGameList" v-for="game in _getGames" :key="game.ID">
                    <li class="time" v-if="_startTime">{{ _startTime }}</li>
                    <li class="game_name" v-if="showBetName == true"><span>{{ _getBetName }}</span></li>
                    <li class="home" :class="{'bet-active' : _betActive(game.ID, 1), bet : _classBet(game.oddsHome), isbet : isBettingList && game.betTeam == 1 }" @click="_addCart(game, 1)">
                        <div class="name-wrap">
                            <span class="name">
                                {{ _getOddsName(game, 1) }}
                            </span>
                            <span v-if="showBetName == true" class="bet-name">
                                <template v-if="_isWinner || _isHadycap || _isunderover || gameList.gameType == 12">
                                    <template v-if="_getRoundName">[{{ _getRoundName }}]</template>
                                </template>
                                <template v-else-if="_isEtc" >[{{_getRoundName}}{{ _getKindName }}{{ _getOddsName2(game, 1) }}] </template>
                            </span>
                            <span v-if="_getBetKind == 'wdl' && game.point" class="bet-point"> ({{game.point}})</span>
                            <template v-else-if="!showIconOverNameWrap">
                                <i v-if="_isHadycap" :class="icoHandy"></i>
                                <i v-else-if="_isunderover" :class="icoUnder"></i>
                            </template>
                        </div>
                        <template v-if="!(_getBetKind == 'wdl' && game.point) && showIconOverNameWrap">
                            <i v-if="_isHadycap" :class="icoHandy"></i>
                            <i v-else-if="_isunderover" :class="icoUnder"></i>
                        </template>
                        <Odds :value="game.oddsHome"></Odds>
                    </li>
                    <li v-if="_getBetKind == 'point'" class="point-wrap">
                        <div class="name-wrap">
                            <span class="name">기준점</span>
                        </div>
                        <span class="point">{{ game.point }}</span>
                    </li>
                    <li v-else-if="_getBetKind == 'wdl'" class="draw" :class="{'bet-active' : _betActive(game.ID, 2), bet : _classBet(game.oddsDraw), isbet : isBettingList && game.betTeam == 2}" @click="_addCart(game, 2)">
                        <div class="name-wrap">
                            <span class="name">
                                {{ _getOddsName(game, 2) }}
                            </span>
                            <span v-if="_getBetKind == 'wdl' && game.point" class="bet-point"> ({{game.point}})</span>
                        </div>
                        <Odds :value="game.oddsDraw"></Odds>
                    </li>
                    <li v-if="_getBetKind == 'wl' && isMainBet == true" class="vs">
                        <div class="name-wrap">
                            <span class="name">VS</span>
                        </div>
                    </li>
                    <li class="away" :class="{'bet-active' : _betActive(game.ID, 3), bet : _classBet(game.oddsAway), isbet : isBettingList && game.betTeam == 3 }" @click="_addCart(game, 3)">
                        <div class="name-wrap">
                            <span class="name">
                                {{ _getOddsName(game, 3) }}
                            </span>
                            <span v-if="showBetName == true" class="bet-name">
                                <template v-if="_isWinner || _isHadycap || _isunderover || gameList.gameType == 12">
                                    <template v-if="_getRoundName">[{{ _getRoundName }}]</template>
                                </template>
                                <template v-else-if="_isEtc" >[{{_getRoundName}}{{ _getKindName }}{{ _getOddsName2(game, 3) }}]</template>
                            </span>
                            <span v-if="_getBetKind == 'wdl' && game.point" class="bet-point">({{game.point}}) </span>
                            <template v-else-if="!showIconOverNameWrap">
                                <i v-if="_isHadycap" :class="icoHandy"></i>
                                <i v-else-if="_isunderover" :class="icoOver"></i>
                            </template>
                        </div>
                        <template v-if="!(_getBetKind == 'wdl' && game.point) && showIconOverNameWrap">
                            <i v-if="_isHadycap" :class="icoHandy"></i>
                            <i v-else-if="_isunderover" :class="icoOver"></i>
                        </template>
                        <Odds :value="game.oddsAway"></Odds>
                    </li>
                    <li v-if="showEtc" class="etc" :class="{active: showGames}" @click="$emit('get_games')">
                        <span :style="{visibility: betCount != 0 ? 'visible' : 'hidden'}" class="count">+{{ betCount }}</span>
                    </li>
                    <li v-if="isBettingList" class="result" :class="_bettingResultToClass(game.result)">
                        <span>{{ _bettingResultToString(game.result) }}</span>
                    </li>
                </ul>
            </div>
        </template>

        <template v-if="_getBetKind == 'correctGoals'">
            <div class="subtitle" v-if="isMainBet != true"><i v-if="icoSubTitle" :class="icoSubTitle"></i>{{_getBetName}}</div>
            <div class="subGameLists">
                <ul class="subGameList">
                    <li class="game_name" v-if="showBetName == true"><span>{{ _getBetName }}</span></li>
                    <template v-for="game in _getGames">
                        <template v-if="isBettingList">
                            <li class="home" :key="game.ID + 'h'">
                                <div class="name-wrap">
                                    <span class="name">
                                        {{ _getOddsName(game, 1) }}
                                    </span>
                                </div>
                            </li>
                            <li class="draw isbet" :key="game.ID + 'd'">
                                <div class="name-wrap">
                                    <span class="name">{{ game.point + ' 골' }}</span>
                                </div>
                                <Odds :value="game.oddsHome"></Odds>
                            </li>
                            <li class="away" :key="game.ID + 'a'">
                                <div class="name-wrap">
                                    <span class="name">
                                        {{ _getOddsName(game, 3) }}
                                    </span>
                                </div>
                            </li>
                            <li class="result" :class="_bettingResultToClass(game.result)" :key="game.ID + 'r'">
                                <span>{{ _bettingResultToString(game.result) }}</span>
                            </li>
                        </template>
                        <li v-else class="sub-list" :key="game.ID" :class="{'bet-active' : _betActive(game.ID, 1), bet : _classBet(game.oddsHome) }" @click="_addCart(game, 1)">
                            <div class="name-wrap">
                                <span class="name">{{ _getOddsName(game, 1) }}</span>
                            </div>
                            <Odds :value="game.oddsHome"></Odds>
                        </li>
                    </template>
                </ul>
            </div>
        </template>

        <template v-if="_getBetKind == 'correctScore'">
            <div class="subtitle" v-if="isMainBet != true"><i v-if="icoSubTitle" :class="icoSubTitle"></i>{{_getBetName}}</div>
            <div class="subGameLists">
                <ul class="subGameList">
                    <li class="game_name" v-if="showBetName == true"><span>{{ _getBetName }}</span></li>
                    <template v-for="game in _getGames">
                        <template v-if="isBettingList">
                            <li class="home" :key="game.ID + 'h'">
                                <div class="name-wrap">
                                    <span class="name">
                                        {{ _getOddsName(game, 1) }}
                                    </span>
                                </div>
                            </li>
                            <li class="draw isbet" :key="game.ID + 'd'">
                                <div class="name-wrap">
                                    <span class="name">{{ _replaceScore(game.point, game.betTeam) }}</span>
                                </div>
                                <Odds :value="_showOdds(game, game.betTeam)"></Odds>
                            </li>
                            <li class="away" :key="game.ID + 'a'">
                                <div class="name-wrap">
                                    <span class="name">
                                        {{ _getOddsName(game, 3) }}
                                    </span>
                                </div>
                            </li>
                            <li class="result" :class="_bettingResultToClass(game.result)" :key="game.ID + 'r'">
                                <span>{{ _bettingResultToString(game.result) }}</span>
                            </li>
                        </template>
                        <template v-else-if="_sameScore(game.point) == 'same'">
                            <li class="sub-list bet" v-if="game.oddsDraw != 0" :key="game.ID" :class="{'bet-active' : _betActive(game.ID, 2), bet : _classBet(game.oddsDraw) }" @click="_addCart(game, 2)">
                                <span class="name">{{ _replaceScore(game.point) }}</span>
                                <Odds :value="game.oddsDraw"></Odds>
                            </li>
                        </template>
                        <template v-else-if="_sameScore(game.point) == 'noSame'">
                            <li class="sub-list bet" v-if="game.oddsHome != 0" :key="game.ID" :class="{'bet-active' : _betActive(game.ID, 1), bet : _classBet(game.oddsHome) }" @click="_addCart(game, 1)">
                                <div class="name-wrap">
                                    <span class="name">{{ _replaceScore(game.point) }}</span>
                                </div>
                                <Odds :value="game.oddsHome"></Odds>
                            </li>
                            <li class="sub-list bet" v-if="game.oddsAway != 0" :key="game.ID+'away'" :class="{'bet-active' : _betActive(game.ID, 3), bet : _classBet(game.oddsAway) }" @click="_addCart(game, 3)">
                                <div class="name-wrap">
                                    <span class="name">{{ _changeScore(game.point) }}</span>
                                </div>
                                <Odds :value="game.oddsAway"></Odds>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
        </template>
    </div>

</template>

<script>
    import Odds from './Odds'

    export default {
        props: ['data', 'gameList', 'plusSec', 'isMainBet', 'noTeamName', 'showStartTime', 'showEtc', 'showBetName', 'isInplay', 'isBettingList',
                'iconType', 'showGames', 'showIconOverNameWrap'],
        computed: {
            _startTime: function () {
                if(this.showStartTime == true)
                    return getStartTime(this.gameList.startTime, this.gameList.sportID, this.plusSec[this.gameList.sportID]);
            },
            _getBetName: function () {
                return getBetName(this.gameList.sportID, this.data.betKind, this.gameList.sportSubID);
            },
            _getBetKind: function(){
                return getBetKind(this.gameList.sportID, this.data.betKind);
            },
            _getGames: function(){
                if(this.isMainBet == true){
                    return [_.head(this.data.games.sort((gameA, gameB) => Math.abs(gameA.oddsHome - gameA.oddsAway) - Math.abs(gameB.oddsHome - gameB.oddsAway)))];
                }
                else
                    return this.data.games;
            },
            _isHadycap: function() {
                return isHadycap(this.data.betKind);
            },
            _isunderover: function() {
                return isunderover(this.data.betKind);
            },
            _isWinner: function() {
                return isWinner(this.data.betKind);
            },
            _isEtc: function(_betkind) {
                if(this._isHadycap || this._isunderover)
                    return false;
                return true;
            },
            _getRoundName : function(){
                if(!this.isBettingList)
                    return getRoundName(this.gameList.sportID, this.data.betKind, this.gameList.sportSubID);
            },
            _getKindName :function(){
                if(!this.isBettingList)
                    return getKindName(this.gameList.sportID, this.data.betKind, this.gameList.sportSubID).replace('모두 ','');
            },
            betCount : function(){
                if(this.showEtc == true)
                    return this.gameList.game_count;
            },
            icoSubTitle : function(){
                if(this.iconType == 1)
                    return '';
                else if(this.iconType == 2 || this.iconType == 3)
                    return 'fas fa-arrow-circle-down';
                else if(this.iconType == 3)
                    return 'fas fa-angle-double-down';
            },
            icoHandy : function(){
                if(this.iconType == 1)
                    return 'fas fa-hospital-symbol';
                else if(this.iconType == 2 || this.iconType == 3)
                    return 'fas fa-h-square';
                else if(this.iconType == 3)
                    return 'fas fa-heading';
            },
            icoOver : function(){
                if(this.iconType == 1)
                    return 'fas fa-chevron-up';
                else if(this.iconType == 2 || this.iconType == 3)
                    return 'fas fa-caret-up';
                else if(this.iconType == 3)
                    return 'fas fa-sort-up';
            },
            icoUnder : function(){
                if(this.iconType == 1)
                    return 'fas fa-chevron-down';
                else if(this.iconType == 2 || this.iconType == 3)
                    return 'fas fa-caret-down';
                else if(this.iconType == 3)
                    return 'fas fa-sort-down';
            },
        },
        methods: {
            _addCart: function (game, betTeam) {
                if(!this.isBettingList)
                    addCart(game, betTeam, this.gameList);
            },
            _betActive: function(_gameID, _betTeam){
                if(!this.isBettingList)
                    return _.some(cartData.cart, { gameID : _gameID, betTeam : _betTeam });
            },
            _classBet: function(_odds){
                if(!this.isBettingList)
                    return _odds != null && _odds != 0;
            },
            _getOddsName: function (_game, _betTeam) {
                if(((this.isMainBet == true && this.isInplay != true) || this.showBetName == true) && (this.noTeamName != true && this.gameList.homeName && this.gameList.awayName)){
                    if(_betTeam == 1)
                        return this.gameList.homeName.name;
                    else if(_betTeam == 2){
                        let _name2 = this._getOddsName2(_game, _betTeam);
                        if(_name2)
                            return _name2;
                        return '무승부';
                    }
                    else if(_betTeam == 3)
                        return this.gameList.awayName.name;
                }else{
                    let _return = getOddsName(this.gameList.sportID, this.data.betKind, _betTeam, '홈', '원정', _game.point, _game.etc);
                    if(this.isInplay == true)
                        _return = _return.replace(' 승', '').replace('승부', '');
                    return _return;
                }
            },
            _getOddsName2: function (_game, _betTeam) {
                if(this.showBetName == true){
                    let __result = getOddsName(this.gameList.sportID, this.data.betKind, _betTeam, '홈', '원정', _game.point, _game.etc)
                    if(this._getKindName && __result && __result != '무승부')
                        return ' - ' + __result;
                    return __result;
                }
            },
            _sameScore: function(_score){
                var splitScore = _.split(_score, '-');
                if(splitScore.length == 2){
                    if(splitScore[0] == splitScore[1])
                        return 'same';
                    else
                        return 'noSame';
                }
                return '';
            },
            _changeScore: function(_score){
                var splitScore = _.split(_score, '-');
                if(splitScore.length == 2){
                    return splitScore[1] + ':' + splitScore[0];
                }
                return '';
            },
            _replaceScore: function(_score, _betTeam = 0){
                if(this.isBettingList && _betTeam == 3){
                    var splitScore = _.split(_score, '-');
                    if(splitScore.length == 2){
                        return splitScore[1] + ':' + splitScore[0];
                    }
                }else{
                    return _.replace(_score, '-', ':');
                }
            },
            _bettingResultToClass: function(_result){
                if(_result == null)
                    return "wait";
                else if(_result == 1){
                    return "hit";
                }else if(_result == 2){
                    return "nohit";
                }else if(_result == 4){
                    return "cancle";
                }else{
                    return "";
                }
            },
            _bettingResultToString: function(_result){
                if(_result == null)
                    return "대기중";
                else if(_result == 1){
                    return "적중";
                }else if(_result == 2){
                    return "미적중";
                }else if(_result == 4){
                    return "적특";
                }else{
                    return "대기중";
                }
            },
            _showOdds: function(_data, _betTeam){
                if(_betTeam == 1)
                    return _data.oddsHome;
                else if(_betTeam == 2)
                    return _data.oddsDraw;
                else if(_betTeam == 3)
                    return _data.oddsAway;
            },
        },
        components: {
            Odds,
        },
    }
</script>
