<template>
    <ul class="top-menu clearfix">
        <li v-for="(menu, key) in menus" :key="key" :class="{active: path1 == key}">
            <i :class="menu.icon"></i><a :href="menu.href">{{ menu.title }}</a>
        </li>

        <!-- <div class="clock"><img width="14" :src="'/img/flag/league/kr.svg'" />{{ clock }}</div> -->
    </ul>
</template>

<script>
    export default {
        data: function () {
            return {
                sTime : sTime, datefail : false, timer: null, clock : '',
                menus : MenuLists
            }
        },
        computed: {
            path1: function(){
                return location.pathname.split('/')[1];
            },
        },
        watch:{
            sTime: {
                immediate: true,
                handler(value) {
                    try {
                        interval = Date.parse(value) * 1 - Date.now() + 2000;
                        this.datefail = false;
                    } catch(e) {
                        interval = new Date(value) * 1 - new Date() * 1 + 2000;
                        this.datefail = true;
                    }
                }
            },
        },
        methods: {
            setClock: function(){
                if(this.datefail) {
                    var now = new Date(new Date() * 1 + interval);
                } else {
                    var now = new Date(Date.now() + interval);
                }
                clock = now;
                this.clock = datetimeToYMDHMS(now);
            },
            _popup_repair: function(){
                Swal.fire({icon: 'warning', title: '점검중 입니다.'})
            }
        },
        created () {
            this.timer = setInterval(this.setClock, 1000)
        },
        beforeDestroy () {
            clearInterval(this.timer)
        },
        components: {
        },
    }
</script>
