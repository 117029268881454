<template>
    <div class="spark-casino-wrap">
        <iframe v-if="_isEmbed" id="liveFrame" name="liveFrame" ref="liveFrame" :src="`https://spark-api000.com/pc/game/mini/${_path2}.aspx`" scrolling="no" frameborder="0"></iframe>
        <!-- <iframe v-if="isEmbed || _path2 == 'baccarat' || _path2 == 'baccarat2'" id="liveFrame" name="liveFrame" ref="liveFrame" :src="`https://spark-api000.com/pc/game/mini/${_path2}.aspx`" scrolling="no" frameborder="0"></iframe> -->
        <embed v-else id="liveFrame" name="liveFrame" ref="liveFrame" :src="`https://viewer.millicast.com/v2?streamId=${streamId}&amp;disableVolume=false&amp;disablePlay=false&amp;disableFull=false&amp;disableControls=false`" style="width:714px; height:714px; border:0; padding:0; transform-origin: 0 0;" />
    </div>
</template>

<style>

</style>

<script>

    export default {
        data: function () {
            return {
                isEmbed: true,
            }
        },
        components: {
        },
        computed: {
            _path2: function(){
                return location.pathname.split('/')[2];
            },
            _isEmbed: function(){
                if(this._path2 == 'baccarat' || this._path2 == 'baccarat2')
                    return true;
                else
                    return false;
            },
             _frameWidth: function(){
                if(this.isEmbed){
                    return 714;
                }
                else{
                    return 830;
                }
            },
            _frameHeight: function(){
                let path = this._path2;
                if(this.isEmbed){
                    return 714;
                }
                else{
                    // if(path == 'baccarat' || path == 'baccarat2' || path == 'baccarat_speed' || path == 'dragontiger')
                        return 714;
                    // else if(path == 'dice' || path == 'samchigi')
                    //     return 459;
                }
            },
            streamId:function(){
                let path = this._path2;
                if(path == 'baccarat')
                    return 'frKmC7/kkphk06p';
                else if(path == 'baccarat2')
                    return 'frKmC7/kkphjgh0';
                else if(path == 'baccarat_speed')
                    return 'frKmC7/kkphknrk';
                else if(path == 'dragontiger')
                    return 'frKmC7/kkphntnf';
                else if(path == 'dice')
                    return 'frKmC7/kkphixqx';
                else if(path == 'samchigi')
                    return 'frKmC7/kkphi52b';
            },
        },
        methods:{
            live_frame_wrap_resize: function(){
                // console.log($('.spark-casino-wrap').width());

                if(this._frameWidth > $('.spark-casino-wrap').width()){
                    let minus = this._frameWidth - $('.spark-casino-wrap').width();
                    let result = 1 - (minus / this._frameWidth);
                    $('.spark-casino-wrap #liveFrame').css('transform', `scale(${result})`);
                    $('.spark-casino-wrap').css('height', this._frameHeight * result);
                }
                else{
                    $('.spark-casino-wrap #liveFrame').css('transform', `scale(1.0)`);
                    $('.spark-casino-wrap').css('height', this._frameHeight);
                }
            },
            casinoRefresh: function(){
                this.$refs.liveFrame.src = this.$refs.liveFrame.src
            }
        },
        watch: {
        },
        created: function () {
            window.addEventListener('resize', this.live_frame_wrap_resize);
            this.live_frame_wrap_resize();
        },
        mounted: function () {
            this.live_frame_wrap_resize();
        },
        destroyed() {
            window.removeEventListener("resize", this.live_frame_wrap_resize);
        },
        components: {
        },
    }
</script>
