<template>
<div class="gamelists">
    <div class="box-header"><i class="fas fa-list"></i> 배팅내역</div>
    <ul class="list-menu">
        <li><a href="/bettinglist" :class="{active: !path2 }"><i class="fas fa-font"></i>전체</a></li>
        <li><a href="/bettinglist/overseas" :class="{active: path2 == 'overseas' }" ><i class="fas fa-globe-americas"></i> 해외형</a></li>
        <li><a href="/bettinglist/cross" :class="{active: path2 == 'cross' }" ><i class="fab fa-korvue"></i> 국내형</a></li>
        <li><a href="/bettinglist/inplay" :class="{active: path2 == 'inplay' }" ><i class="fas fa-dice-d6"></i> 인플레이</a></li>
        <li><a href="/bettinglist/minigame" :class="{active: path2 == 'minigame' }" ><i class="fas fa-gamepad"></i> 미니게임</a></li>
    </ul>
    <div class="box-content">
        <div class="bettinglists">
            <div v-for="betting in userdata.bettingLists.data" :key="betting.ID">
                <div class="game-header">
                    <input v-if="!_canCancel(betting) && (betting.result || _isCancel(betting))" type="checkbox" :value="betting.ID" v-model="selected">
                    <button class="cancel-btn" v-if="_canCancel(betting)" @click="_exec_cancel(betting.ID)">배팅 취소</button>
                    <span class="is-cancel" v-if="_isCancel(betting)">[취소됨]</span>
                    <span>
                        {{ _betTime(betting.createdAt) }}
                    </span>

                    <div class="type-name">
                        <i :class="_showTypeIcon(betting)"></i>
                        {{ _showTypeName(betting) }}
                    </div>
                </div>

                <BettingList v-for="game in betting.games" :key="game.ID" :data="game" :plusSec="plusSec"></BettingList>

                <div class="game-total">
                    <div class="total-result">
                        <span>
                            배당
                            <span class="result">
                                {{ _resultOdds(betting) }}
                                <span v-if="betting.bonusOdds > 1" class="result_bonus">(+{{ betting.bonusOdds }})</span>
                            </span>
                        </span>
                        <span>
                            배팅금
                            <span class="result">{{ _cash(betting.betCash) }}</span>
                        </span>
                        <span>
                            <template v-if="betting.result != 1">예상</template> 당첨금
                            <b class="result">{{ _resultCash(betting) }}</b>
                        </span>
                    </div>
                    <div class="status" :class="_bettingResultToClass(betting.result)">
                        {{ _bettingResultToString(betting.result) }}
                    </div>
                </div>
            </div>
        </div>
        <pagination :pagination="userdata.bettingLists.meta" @paginate="_exec_get()"></pagination>
    </div>

    <div class="center-btn-wrap">
        <button class="blue" type="button" @click="_execDelete()"><i class="far fa-trash-alt"></i> 선택삭제</button>
        <button class="red" type="button" @click="_execDeleteAll()"><i class="fas fa-trash-alt"></i> 전체삭제</button>
    </div>
</div>
</template>

<script>
    import BettingList from './List';

    export default {
        props: ['plusSec'],
        data: function () {
            return {
                userdata: data, selected: [], selectAll: false
            }
        },
        computed: {
            path2: function(){
                return location.pathname.split('/')[2];
            },

        },
        methods :{
            _betTime: function (_betTime) {
                return datetimeToYMDHM(_betTime);
            },
            _showTypeName: function (_gameList) {
                return getTypeName(_gameList.gameType);
            },
            _showTypeIcon: function (_gameList) {
                if(_gameList.gameType == 1)
                    return 'fas fa-globe';
                else if(_gameList.gameType == 2)
                    return 'fas fa-random';
                else if(_gameList.gameType == 3 || _gameList.gameType == 4)
                    return 'fab fa-speakap';
                else if(_gameList.gameType == 21)
                    return 'fas fa-dice-d6';
                else if(_gameList.gameType == 12)
                    return 'fas fa-gamepad';
            },
            _cash: function (_cash) {
                return numberWithComma(_cash);
            },
            _bettingResultToClass: function(_result){
                if(_result == null)
                    return "wait";
                else if(_result == 1){
                    return "hit";
                }else if(_result == 2){
                    return "nohit";
                }else if(_result == 4){
                    return "cancle";
                }else{
                    return "";
                }
            },
            _bettingResultToString: function(_result){
                if(_result == null)
                    return "대기중";
                else if(_result == 1){
                    return "적중";
                }else if(_result == 2){
                    return "미적중";
                }else if(_result == 4){
                    return "적특";
                }else{
                    return "대기중";
                }
            },
            _exec_get: function() {
                showLoading();
                    axios.get(`${location.pathname}?page=` + this.userdata.bettingLists.meta.current_page)
                .then((response)=>{
                    if(response.data.meta){
                        this.userdata.bettingLists = response.data;
                    }
                })
                .finally(function () {
                    hideLoading();
                });
                window.scrollTo(null, 0);
            },
            _canCancel: function(_betting){ //gameType, betting.games _gameType, _games){

                if(this._isCancel(_betting))
                    return false;

                if(_betting.gameType == 2){ // || _betting.gameType == 3 || _betting.gameType == 4

                    if(_.some(_betting.games, 'result'))
                        return false;

                    // var now30 = _.cloneDeep(clock);
                    // now30.setMinutes( now30.getMinutes() + 30 );
                    // var now_30 = _.cloneDeep(clock);
                    // now_30.setMinutes( now_30.getMinutes() - 30 );

                    // var minGame = _.minBy(_betting.games, function(value) { return new Date(value.startTime); });
                    // var minTime = new Date(minGame.startTime);
                    //var _createdAt = new Date(_betting.createdAt);

                    // if(now30 < minTime && now_30 < _createdAt)
                    //     return true;

                    var _now30 = moment(clock);
                    _now30.add(30, 'minutes');
                    var _now_30 = moment(clock);
                    _now_30.subtract(30, 'minutes');

                    var minGame = _.minBy(_betting.games, function(value) { return new Date(value.GameList.startTime); });
                    var _minTime = moment(minGame.GameList.startTime);
                    var _createdAt = moment(_betting.createdAt);

                    if(_now30.isBefore(_minTime) && _now_30.isBefore(_createdAt))
                        return true;

                    return false;
                }

                return false;
            },
            _isCancel: function(_betting){
                if(_betting.canceledAt)
                    return true
                return false;
            },
            _exec_cancel: function(bettingID){
                Swal.fire({icon: 'warning', title: '배팅을 취소 하시겠습니까?',
                    showCancelButton: true,
                    confirmButtonColor: '#005aa7', cancelButtonColor: '#c53434',
                    confirmButtonText: '네', cancelButtonText: '아니오'
                }).then((result) => {
                    if (result.value) {
                        showLoading();
                        axios.delete('/betting/cancel/' + bettingID)
                        .then((response)=>{
                            if(response.data.result == 0){
                                Swal.fire({icon: 'error', title: response.data.msg})
                            }
                            else if(response.data.result == 1){
                                this._exec_get();
                                Swal.fire({icon: 'success', title: '취소 완료!'});
                            }
                        })
                        .finally(function () {
                            hideLoading();
                        });
                    }
                });
            },
            _execDelete: function() {
                showLoading();
                let _type = location.pathname.split('/')[2];
                axios.delete(`/betting/delete?page=` + this.userdata.bettingLists.meta.current_page, { data: {bettingIDs: this.selected, type : _type} })
                .then((response)=>{
                    if(response.data.result == 0){
                        Swal.fire({icon: 'error', title: response.data.msg})
                    }
                    else if(response.data.meta){
                        Swal.fire({icon: 'success', title: '삭제 되었습니다.'});
                        this.userdata.bettingLists = response.data;
                    }
                })
                .finally(function () {
                    hideLoading();
                });
            },
            _execDeleteAll: function() {
                this.selected = [];
                this.selected = this.userdata.bettingLists.data.filter(function(_data) { return _data.result || _data.canceledAt; }).map(function(_data) { return _data.ID;});
                this._execDelete();
            },
            _resultOdds: function(_betting ){
                if(_betting.resultOdds)
                    return _betting.resultOdds;
                return _betting.totalOdds;
            },
            _resultCash: function(_betting){
                if(_betting.resultCash)
                    return numberWithComma(_betting.resultCash);
                return numberWithComma(parseFloat(_betting.totalOdds * _betting.betCash).toFixed(0));
            },
        },
        components: {
            BettingList,
        },
    }
</script>
