<template>
    <li>
        <div>
            <template  v-if="cdata.homeName">
                <span class="cart-team-name-left">
                    {{ cdata.homeName.name }}
                </span>
                <span class="cart-vs">
                    vs
                </span>
                <span class="cart-team-name-right">
                    {{ cdata.awayName.name }}
                </span>
            </template>
            <template v-else>
                <span>
                    {{ _miniGameName }}
                    <span v-if="cdata.sportSubID">
                        - {{ cdata.sportSubID }}회
                    </span>
                </span>
            </template>
        </div>
        <div>
            <span>
                {{ _getBetName }} {{ _getBetNamePoint }}
            </span>
            <!-- <span v-show="old_odds != cdata.odds" class="old-odds">{{ old_odds }}</span> -->
             <Odds :value="cdata.odds" />
        </div>
        <div>
            <span class="cart-odds-name">
                {{ _getOddsName }}
            </span>
            <i class="fas fa-times-circle cart-cancle" @click="cartRemove"></i>
        </div>
    </li>
</template>

<script>

    import Odds from '../GameList/Odds'

    export default {
        props: ['cdata'],
        data: function () {
            return {
                old_odds: null,
            }
        },
        computed: {
            _getBetName: function () {
                return getBetName(this.cdata.sportID, this.cdata.game.betKind, this.cdata.sportSubID);
            },
            _getBetNamePoint: function () {
                if(this.cdata.game.betKind == 1005 || this.cdata.game.betKind == 1005 || this.cdata.game.betKind == 1005 || this.cdata.game.betKind == 1010)
                    return;
                else
                    return this.cdata.game.point;
            },
            _getOddsName: function () {
                if(this._isMinigame)
                    return getOddsName(this.cdata.sportID, this.cdata.game.betKind, this.cdata.betTeam, '', '', this.cdata.game.point, this.cdata.game.etc);
                else
                    return getOddsName(this.cdata.sportID, this.cdata.game.betKind, this.cdata.betTeam, this.cdata.homeName.name, this.cdata.awayName.name, this.cdata.game.point, this.cdata.game.etc);
            },
            // _getOdds: function(){
            //     if(this.cdata.betTeam == 1)
            //         return this.cdata.game.oddsHome;
            //     else if(this.cdata.betTeam == 2)
            //         return this.cdata.game.oddsDraw;
            //     else if(this.cdata.betTeam == 3)
            //         return this.cdata.game.oddsAway;
            // },
            _isMinigame: function () {
                let path = location.pathname.split('/')[1];
                return path == 'minigame';
            },
            _miniGameName: function () {
                return getMiniGameName('', this.cdata.sportID);
            },
            _odds: function(){
                return this.cdata.odds;
            },
            _odds_BetTeam: function(){
                return this.cdata.odds + '__' + this.cdata.betTeam;
            },
        },
        methods: {
            cartRemove: function(){
                let path = location.pathname.split('/')[1];
                if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay'){
                     axios.delete(`/cart/${path}/${this.cdata.gameID}`)
                    .then((response)=>{
                        //cartData.cart = response.data;
                    })
                }

                const idx = cartData.cart.findIndex((cart) => cart.gameID === this.cdata.gameID);
                if (idx > -1)
                    cartData.cart.splice(idx, 1);
            },
            _odds_update: function(){
                if(this.cdata.betTeam == 1 && this.cdata.odds != this.cdata.game.oddsHome){
                    this.cdata.odds = this.cdata.game.oddsHome;
                }else if(this.cdata.betTeam == 2 && this.cdata.odds != this.cdata.game.oddsDraw){
                    this.cdata.odds = this.cdata.game.oddsDraw;
                }else if(this.cdata.betTeam == 3 && this.cdata.odds != this.cdata.game.oddsAway){
                    this.cdata.odds = this.cdata.game.oddsAway;
                }
            }
        },
        watch: {
            cdata: {
                handler: function(newValue, oldValue) {
                    this._odds_update();
                },
                deep: true, immediate: false
            },
            _odds_BetTeam :{
                handler: function(newValue, oldValue) {
                    // console.log(oldValue)
                    let newSplit = newValue.split('__');
                    let oldSplit = oldValue.split('__');
                    if(newSplit && oldSplit && newSplit.length >= 2 && oldSplit.length >= 2){
                        if(newSplit[0] != oldSplit[0] && newSplit[1] == oldSplit[1])
                            this.$emit('changeOdds');
                        else if(newSplit[0] != oldSplit[0] && newSplit[1] != oldSplit[1])
                            this.old_odds  = this.cdata.odds;
                    }

                }, immediate: false
            }
        },
        created: function () {
          this.old_odds = this.cdata.odds;
        },
        mounted: function () {
            this._odds_update();
        },
        components: {
            Odds,
        },
    }
</script>
