<template>
    <div class="">
        <div class="box-header"><i class="far fa-calendar-check"></i> 출석체크</div>
        <div class="box-content box-main-content">

            <div class="attendance-summry">
                <span>하루 최소 <b>{{ _showCash(attendanceCash) }}</b>이상 충전시 출석체크 가능합니다.</span>
                <span>출석체크 시 <b>{{ _showCash(attendancePoint) }}</b> 포인트가 지급 됩니다.</span>
            </div>

            <FullCalendar
                class='attendance-calendar'
                ref="fullCalendar"
                defaultView="dayGridMonth"
                :header="{
                    left: '',
                    center: 'title',
                    right: ''
                }"
                :plugins="calendarPlugins"
                :events="calendarEvents"
                eventOrder="index"
                contentHeight="auto"
                eventBackgroundColor="transparent"
                eventBorderColor="transparent"
                @eventRender="eventRender"
                @eventClick="handleEventClick"
                locale="ko"
                />

        </div>
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'

    export default {

        data: function () {
            return {
                userdata: data.subData,
                attendanceCash: data.attendanceCash,
                attendancePoint: data.attendancePoint,
                calendarPlugins: [ // plugins must be defined in the JS
                    dayGridPlugin,
                ],
            }
        },
        computed: {
             calendarEvents: function () {
                let events = _.forEach(this.userdata, function(objs, index) {
                    objs.start = objs.date;
                    objs.attendance = 1;
                });

                var d = new Date();
                var _date=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2);

                let today = [];
                if(!_.some(events, {start : _date}))
                    today = { start : _date, attendance : 2};

                var _getDate = d.getDate();
                var _dates = [];
                for(var i=1; i<_getDate; i++) {
                    _dates.push(d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + i).slice(-2));
                }

                let attendance0 = [];
                _.forEach(_dates, function(objs, index) {
                    if(!_.some(events, {start : objs})){
                        attendance0.push({ start : objs, attendance : 0});
                    }
                });

                return _.concat(events, today, attendance0);
            },
        },
        methods: {
            eventRender:function(info) {
                if(info.event.extendedProps.attendance == 1) {
                    $(info.el).find("span.fc-time").remove();
                    $(info.el).find("span.fc-title").remove();
                    $(info.el).find("div.fc-content").append("<span class='attandance-complete'>출석완료</span>");
                }else if(info.event.extendedProps.attendance == 2) {
                    $(info.el).find("span.fc-time").remove();
                    $(info.el).find("span.fc-title").remove();
                    $(info.el).find("div.fc-content").append("<span class='attandance-attempt'><i class='far fa-calendar-check'></i> 출석</span>");
                }else if(info.event.extendedProps.attendance == 0) {
                    $(info.el).find("span.fc-time").remove();
                    $(info.el).find("span.fc-title").remove();
                    $(info.el).find("div.fc-content").append("<i class='attandance-no far fa-check-circle'></i>");
                }
            },
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            handleEventClick(arg) {
                if($(arg.el).find('span.attandance-attempt').length !== 0){
                    showLoading();
                    axios.post(location.pathname)
                    .then((response)=>{
                        if(response.data.result == 1){
                            var d = new Date();
                            var _date=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2);
                            this.userdata.push({ date : _date});
                            Swal.fire({icon: 'success', title: '출석 체크 완료'});
                        }
                        else
                        {
                            Swal.fire({icon: 'error', title: response.data.msg});
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[001]'})
                    })
                    .finally(function () {
                        hideLoading();
                    });
                }
            },
        },
        watch:{

        },
        components: {
            FullCalendar
        },
    }
</script>

<style lang='scss'>
// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.demo-app-calendar {
  margin: 0 auto;
}

.fc-unthemed td.fc-today {
    background: transparent;
    color: orange;
}
.fc-content{
    text-align: center;
}
span.fc-title>span {
    padding-left: 5px;
}
</style>
