<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 240" class="sr-lmt-pitch-soccer__svg"  style="width:100%">
        <defs>
            <mask id="mask">
                <image xlink:href="/img/inplay/soccer-noise.png" />
            </mask>
            <pattern id="pattern2">
                <line x1="0" y1="0" x2="30" y2="30" stroke="#3D8246" stroke-width="2" class="ptrn1" />
                <line x1="0" y1="30" x2="30" y2="0" class="ptrn2" />
            </pattern>

            <linearGradient id="Gradient1">
            <stop offset="5%" stop-color="white"/>
            <stop offset="95%" stop-color="blue"/>
            </linearGradient>
            <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
            <stop offset="5%" stop-color="red"/>
            <stop offset="95%" stop-color="orange"/>
            </linearGradient>


            <mask id="mask"  x="0" y="0" width=".25" height=".25">
                <image xlink:href="/img/inplay/soccer-noise.png" />
            </mask>
            <pattern id="Pattern"  x="0" y="0" width="60" height="60" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="60" height="60" fill="#628d00"/>
                <image xlink:href="/img/inplay/soccer-noise.png" />
            </pattern>
            <pattern id="Pattern2"  x="0" y="0" width=".13" height="100%" >
                <rect x="0" y="0" width="22" height="100%" fill="#ffffff18"/>
            </pattern>

            <radialGradient id="black-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #000;stop-opacity: 0.2;" />
                <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
            </radialGradient>
            <radialGradient id="white-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #fff;stop-opacity: 0.15;" />
                <stop offset="100%" style="stop-color: #fff;stop-opacity: 0;" />
            </radialGradient>
        </defs>

        <g id="pitch" stroke="none" fill="none" fill-rule="evenodd">
            <rect id="pitch-BG" x="0" y="0" width="400" height="240" fill="url(#Pattern)"></rect>
            <rect id="pitch-SP" x="25" y="7" width="360" height="226" fill="url(#Pattern2)"></rect>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M6,6h388v228H6V6z"></path>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M58,93c12 4,15 20,15 26s-3 22,-15 26 M6.5,58h51v120h-51 M393,58h-51v120h51 M342,93c-12 4,-15 20,-15 26s3 22,15 26"></path>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M6.5,94h20v50h-20 M393,94h-20v50h20 M200,6v228"></path>
            <circle stroke="#fff4" stroke-width="3" fill="none" cx="200" cy="120" r="30"></circle>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M6,106H2v28h4"></path>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M394,106h4v28h-4"></path>
            <line stroke="#fff4" stroke-width="6" fill="none" stroke-linecap="round" x1="200" y1="120" x2="200" y2="120"></line>
            <line stroke="#fff4" stroke-width="4" fill="none" stroke-linecap="round" x1="41" y1="120" x2="41" y2="120"></line>
            <line stroke="#fff4" stroke-width="4" fill="none" stroke-linecap="round" x1="358" y1="120" x2="358" y2="120"></line>
            <path stroke="#fff4" stroke-width="3" fill="none" d="M7,13.5c4,0,6-4,6-6 M13.5,232.5c0-4-4-6-6-6 M387,232.5c0-4,4-6,6-6 M393,13.5c-4,0-6-4-6-6"></path>
            <circle cx="6" cy="6" r="50" fill="url(#black-rg)"></circle>
            <circle cx="394" cy="6" r="50" fill="url(#black-rg)"></circle>
            <circle cx="6" cy="234" r="50" fill="url(#black-rg)"></circle>
            <circle cx="394" cy="234" r="50" fill="url(#black-rg)"></circle>
            <circle cx="41" cy="120" r="50" fill="url(#black-rg)"></circle>
            <circle cx="358" cy="120" r="50" fill="url(#black-rg)"></circle>
            <circle cx="200" cy="120" r="80" fill="url(#white-rg)"></circle>
        </g>
    </svg>

        <!-- <svg class="lmt-soccer-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 200" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" >
        <defs>
            <linearGradient id="ml1-Gradient1">
                <stop class="gradStart" offset="35%" style="stop-opacity: 0.4; stop-color: rgb(24, 84, 53);"></stop>
                <stop class="gradEnd" offset="100%" style="stop-opacity: 0.4; stop-color: rgb(24, 84, 53);"></stop>
            </linearGradient>
            <linearGradient id="ml1-Gradient2">
                <stop class="gradStart" offset="0%" style="stop-opacity:0.4"></stop>
                <stop class="gradEnd" offset="100%" style="stop-opacity:0.4"></stop>
            </linearGradient>
            <linearGradient id="ml1-SolidTeamColour">
                <stop id="ml1-SolidTeam1" offset="0%" stop-color="#FFDF1B"></stop>
                <stop id="ml1-SolidTeam2" offset="100%" stop-color="#FFDF1B"></stop>
            </linearGradient>
        </defs>
        <g id="pitch2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path fill="#222" d="M0 0h400v180h-400z"></path>
            <rect id="pitch-BG" fill="#35713D" x="0" y="0" width="400" height="180"></rect>
            <rect id="18-yard-away" stroke="#3D8246" stroke-width="2" x="348" y="44" width="51" height="93"></rect>
            <rect id="18-yard-home" stroke="#3D8246" stroke-width="2" x="1" y="44" width="51" height="93"></rect>
            <path d="M199,3 L199,178 L199,179 L201,179 L201,178 L201,3 L201,2 L199,2 L199,3 L199,3 Z" id="Shape" fill="#3D8246"></path>
            <path d="M200,118 L200,118 C215.463973,118 228,105.463973 228,90 C228,74.536027 215.463973,62 200,62 C184.536027,62 172,74.536027 172,90 C172,105.463973 184.536027,118 200,118 L200,118 Z M200,120 L200,120 C183.431458,120 170,106.568542 170,90 C170,73.4314575 183.431458,60 200,60 C216.568542,60 230,73.4314575 230,90 C230,106.568542 216.568542,120 200,120 L200,120 Z" id="Shape-Copy" fill="#3D8246"></path>
            <circle id="Oval-344" fill="#3D8246" cx="200" cy="90" r="6"></circle>
            <path d="M74,63 C65.5522257,68.9666814 60,79.0570309 60,90.5 C60,101.942969 65.5522257,112.033319 74,118" id="d-left" stroke="#3D8246" stroke-width="2" transform="translate(67.000000, 90.500000) scale(-1, 1) translate(-59.000000, -90.500000) "></path>
            <circle id="Oval-70" fill="#3D8246" cx="34" cy="90" r="3"></circle>
            <path d="M1,11 C6.5228475,11 11,6.5228475 11,1 L9.046875,1 C9.046875,5.44416635 5.44416635,9.046875 1,9.046875 L1,11 L1,11 Z" id="Shape" fill="#3D8246"></path>
            <path d="M1,171 C5.418278,171 9,174.581722 9,179 L11,179 C11,173.477153 6.5228475,169 1,169 L1,171 L1,171 Z" id="Shape" fill="#3D8246"></path>
            <path d="M337.884236,62 C328.64831,68.2472191 323,78.6467666 323,90 C323,101.353233 328.64831,111.752781 337.884236,118 L339,116.354082 C330.305144,110.472845 324.989964,100.686649 324.989964,90 C324.989964,79.3133507 330.305144,69.5271551 339,63.645918 L337.884236,62 L337.884236,62 Z" transform="translate(9,0)" id="Shape-Copy-2" fill="#3D8246"></path>
            <rect id="6-yard-home" stroke="#3D8246" stroke-width="2" x="1" y="69" width="16" height="41"></rect>
            <path d="M399,69 L383,69 L383,110 L399,110 L399,69 L399,69 Z" id="6-yard-away" stroke="#3D8246" stroke-width="2" sketch:type="MSShapeGroup"></path>
            <circle id="Oval-70" fill="#3D8246" cx="365" cy="90" r="3"></circle>
            <path d="M399,9.046875 C394.555834,9.046875 390.953125,5.44416635 390.953125,1 L389,1 C389,6.5228475 393.477153,11 399,11 L399,9.046875 L399,9.046875 Z" id="Shape" fill="#3D8246"></path>
            <path d="M399,169 C393.477153,169 389,173.477153 389,179 L391,179 C391,174.581722 394.581722,171 399,171 L399,169 L399,169 Z" id="Shape" fill="#3D8246"></path><rect stroke="#3D8246" stroke-width="2" x="1" y="1" width="398" height="178"></rect>
        </g>
        <path class="ml1-SoccerPitch_AnimationElement" id="ml1-Possession1" opacity="1" d="M2,2 215.52892561983472,2 215.70247933884298,2 C215.70247933884298,1.4 219,28 219,28 C219.17355371900825,29 219.17355371900825,30 219,31 L215.70247933884298,61 L219,88 C219.17355371900825,89 219.17355371900825,90 219,91 L215.70247933884298,121 L219,146 C219.17355371900825,147 219.17355371900825,150 219,151 L215.70247933884298,178 C215.70247933884298,178 2.0,178 2.0,178 L2,2 Z" fill="url(#ml1-Gradient1)" style="opacity: 0;"></path>
        <path class="ml1-SoccerPitch_AnimationElement" id="ml1-Possession2" d="" fill="url(#ml1-Gradient2)"></path>
        <g class="ml1-SoccerPitch_AnimationElement" id="ml1-WideKickRadial" opacity="1" transform="translate(16, 72) rotate(2.8310016778652676)">
            <path id="ml1-WideKickRadialOuter" fill="#165031" d="M94.703,73.698C110.554,53.359,120,27.784,120,0c0-27.764-9.433-53.321-25.262-73.653L0,0.036L94.703,73.698z" class="" style="opacity: 0; transform: scaleX(0) scaleY(0);"></path>
            <path id="ml1-WideKickRadialCentre" fill="#165031" d="M62.325,48.514C72.765,35.123,79,18.294,79,0c0-18.274-6.222-35.085-16.639-48.469L0,0.036L62.325,48.514z" class="" style="opacity: 0; transform: scaleX(0) scaleY(0);"></path>
            <path id="ml1-WideKickRadialInner" fill="#165031" d="M30.759,23.961C35.918,17.349,39,9.037,39,0c0-9.017-3.068-17.311-8.205-23.917L0,0.036L30.759,23.961z" class="" style="opacity: 0; transform: scaleX(0) scaleY(0);"></path>
        </g>
        <g class="ml1-SoccerPitch_AnimationElement" id="ml1-NarrowKickRadial" opacity="1" transform="translate(200, 127.8) rotate(-10.702657494201645)">
            <path id="ml1-NarrowKickRadialOuter" fill="#165031" d="M0.031,0.013l117.647,45.045C123.041,31.064,126,15.881,126,0s-2.959-31.065-8.322-45.059L0.093-0.036L0.031,0.013z" class="" style="opacity: 0.22; transform: scaleX(1) scaleY(1);"></path>
            <path id="ml1-NarrowKickRadialCentre" fill="#165031" d="M0.031,0.013l77.43,29.647c3.536-9.21,5.488-19.204,5.488-29.66s-1.952-20.45-5.487-29.66L0.093-0.036L0.031,0.013z" class="" style="opacity: 0.44; transform: scaleX(1) scaleY(1);"></path>
            <path id="ml1-NarrowKickRadialInner" fill="#165031" d="M0.031,0.013l38.208,14.629c1.746-4.546,2.71-9.48,2.71-14.642s-0.964-10.096-2.709-14.642L0.093-0.036L0.031,0.013z" class="" style="opacity: 0.66; transform: scaleX(1) scaleY(1);"></path>
        </g>
        <g class="ml1-SoccerPitch_AnimationElement" id="ml1-ThrowRadial" opacity="0">
            <path id="ml1-ThrowRadialOuter" fill="#165031" d="M53.033,53.068c29.289-29.289,29.289-76.777,0-106.066L0,0.035L53.033,53.068z"></path>
            <path id="ml1-ThrowRadialInner" fill="#165031" d="M26.517,26.552c14.646-14.645,14.644-38.39,0-53.033L0,0.035L26.517,26.552z"></path>
        </g>
    </svg> -->


</template>

<script>
    export default {
        props: ['stats'],
        data: function () {
            return {
            }
        },
        computed: {


        },
        methods: {

        },
        watch: {

        },
    }
</script>
