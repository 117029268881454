<template>
    <div class="">
        <div class="box-header"><i class="fas fa-info-circle"></i> {{ _showTitle }}</div>

        <div class="userinfo-sub board-lists">
            <div class="board-list title">
                <ul>
                    <li>번호</li>
                    <li>제목</li>
                </ul>
            </div>
            <BoardList v-for="(data, index) in userdata.subData.data" :data="data"  :key="index"></BoardList>
            <div class="empty" v-show="userdata.subData.data.length == 0">
                내용이 없습니다.
            </div>
        </div>

        <pagination :pagination="this.userdata.subData.meta" @paginate="_exec_get()"></pagination>
    </div>
</template>

<script>

    import BoardList from './BoardList';

    export default {

        data: function () {
            return {
                userdata: data
            }
        },
        computed: {
            _showTitle: function () {
                let path = location.pathname.split('/')[1];
                if(path == 'notice')
                    return '공지사항';
                else if(path == 'event')
                    return '이벤트';
            },
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _getContent: function(_data){
                _data.showContent = false;
                _data.showContent = true;
                // axios.get(`/inquery/content/${_data.ID}`)
                // .then((response)=>{

                // })
            },
             _exec_get: function() {
                showLoading();
                    axios.get(`${location.pathname}?page=` + this.userdata.subData.meta.current_page)
                .then((response)=>{
                    //console.log(response);
                    data.subData = response.data;
                    this.userdata = data;
                    hideLoading();
                })
            },

        },
        watch:{
        },
        components: {
            BoardList,
        },
    }
</script>
