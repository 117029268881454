<template>
    <svg class="lm_volleyball_svg" viewBox="0 0 400 200" style="width:100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <radialGradient id="black-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #000;stop-opacity: 0.1;" />
                <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
            </radialGradient>
            <radialGradient id="white-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #fff;stop-opacity: 0.11;" />
                <stop offset="100%" style="stop-color: #fff;stop-opacity: 0;" />
            </radialGradient>
            <filter x="-150%" y="0" width="400%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="2" dy="0"></feOffset>
                <feGaussianBlur stdDeviation="1" result='offset-blur'></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0.1" in="offset-blur" type="matrix" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect width="400" height="196" style="stroke-width: 3px; fill: rgb(54 102 159); stroke: rgb(68 117 175);" y="2"></rect>
            <path style="stroke-width: 3px; stroke: rgb(68 117 175);" d="M 123 100 L 277 100"></path>
            <rect width="122" height="196" style="stroke-width: 3px; fill: rgb(230, 158, 87); stroke: rgb(184, 152, 96);" y="2"></rect>
            <rect x="278" width="122" height="196" style="stroke-width: 3px; fill: rgb(230, 158, 87); stroke: rgb(184, 152, 96);" y="2"></rect>
            <path d="M 198 2.5 L 202 2.5 L 202 197.5 L 198 197.5 L 198 2.5 Z" id="Rectangle-171" filter="url(#filter-1)" style="fill: rgb(255, 255, 255);"></path>
            <circle cx="200" cy="92" r="80" fill="url(#white-rg)"></circle>
            <circle cx="60" cy="50" r="60" fill="url(#white-rg)"></circle>
            <circle cx="60" cy="150" r="60" fill="url(#white-rg)"></circle>
            <circle cx="340" cy="50" r="60" fill="url(#white-rg)"></circle>
            <circle cx="340" cy="150" r="60" fill="url(#white-rg)"></circle>
        </g>
    </svg>
</template>

<script>
    export default {
        props: ['stats'],
        data: function () {
            return {
            }
        },
        computed: {

        },
        methods: {
        },
        watch: {
        },
    }
</script>
