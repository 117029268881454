<template>
<div class="">
    <div class="box-header"><i class="fas fa-chess-rook"></i> 스카이파크 - {{ _title }}</div>
    <div class="minigame-menu">

        <div class="minigame-sub-menu">
            <ul class="casino">
                <li :class="{active: _path2 == 'baccarat'}"><a href="/skypark-casino/baccarat">바카라1</a></li><!--
                --><li :class="{active: _path2 == 'baccarat2'}"><a href="/skypark-casino/baccarat2">바카라2</a></li><!--
                --><li :class="{active: _path2 == 'baccarat_speed'}"><a href="/skypark-casino/baccarat_speed">스피드 바카라</a></li><!--
                --><li :class="{active: _path2 == 'dragontiger'}"><a href="/skypark-casino/dragontiger">드래곤 타이거</a></li><!--
                --><li :class="{active: _path2 == 'dice'}"><a href="/skypark-casino/dice">주사위</a></li><!--
                --><li :class="{active: _path2 == 'samchigi'}"><a href="/skypark-casino/samchigi">삼치기</a></li>
                <!-- <li :class="{active: _path2 == 'powerkeno_ladder'}"><a href="/minigame/powerkeno_ladder">파워키노사다리</a></li> -->
            </ul>
        </div>
    </div>
    <skypark-casino-view ref="casinoView"></skypark-casino-view>
    <div class='spark-casino-pan' :class="{samchigi: _path2 == 'samchigi'}">
        <div class="bet-info show-cash">
            <div class="casino-money">
                <span class="title">보유머니</span>
            </div>
            <div class="casino-total-money">
                 <span class="now-cash">{{ _showCash(myData.cash) }}</span>
            </div>
            <div class="video-refresh" @click="$refs.liveFrame.src = $refs.liveFrame.src">
                <button @click="$refs.casinoView.casinoRefresh()">
                    <i class="fas fa-sync-alt"></i>영상 새로고침
                </button>
            </div>
        </div>
        <div class="bet-casino-box">
            <div class="bet-kind baccarat" v-if="_path2 == 'baccarat' || _path2 == 'baccarat2' || _path2 == 'baccarat_speed'">
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 1</div>
                    <div class="player bet" :class="set_bet== 1 ? 'active' : ''" @click="_set_bet(1, 1.97)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">1.97</span>
                                <span class="casino-batingname">플레이어</span>
                            </p>
                        </div>
                    </div><!--
                    --><div class="tie bet" :class="set_bet== 3 ? 'active' : ''" @click="_set_bet(3, 8.00)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">8.00</span>
                                <span class="casino-batingname">타이</span>
                            </p>
                        </div>
                    </div><!--
                    --><div class="banker bet" :class="set_bet== 2 ? 'active' : ''" @click="_set_bet(2, 1.93)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">1.93</span>
                                <span class="casino-batingname">뱅커</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 2</div>
                    <div class="banker-pair bet" :class="set_bet== 5 ? 'active' : ''" @click="_set_bet(5, 11.00)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">11.00</span>
                                <span  class="casino-batingname">B 페어</span>
                            </p>
                        </div>
                    </div>
                    <div class="player-pair bet" :class="set_bet== 4 ? 'active' : ''" @click="_set_bet(4, 11.00)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">11.00</span>
                                <span class="casino-batingname">P 페어</span>
                            </p>
                        </div>
                    </div>
                </div>
        </div>
            <div class="bet-kind dragontiger" v-if="_path2 == 'dragontiger'">
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 1</div>
                    <div class="dragon bet" :class="set_bet== 1 ? 'active' : ''" @click="_set_bet(1, 1.95)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">1.95</span>
                                <span class="casino-batingname">드래곤</span>
                            </p>
                        </div>
                    </div>
                    <div class="tie bet" :class="set_bet== 3 ? 'active' : ''" @click="_set_bet(3, 30.00)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">30.00</span>
                                <span class="casino-batingname">타이</span>
                            </p>
                        </div>
                    </div>
                    <div class="tiger bet" :class="set_bet== 2 ? 'active' : ''" @click="_set_bet(2, 1.95)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">1.95</span>
                                <span class="casino-batingname">타이거</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bet-kind dice" v-if="_path2 == 'dice'">
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 1</div>
                    <div class="player bet" :class="set_bet== 1 ? 'active' : ''" @click="_set_bet(1, 1.95)">
                        <div class="bet-wrap">
                            <p>
                                <span class="casino-odds">1.95</span>
                                <span class="casino-batingname">홀</span>
                            </p>
                        </div>
                    </div>
                    <div class="banker bet" :class="set_bet== 2 ? 'active' : ''" @click="_set_bet(2, 1.95)">
                            <div class="bet-wrap">
                                <p>
                                    <span class="casino-odds">1.95</span>
                                    <span class="casino-batingname">짝</span>
                                </p>
                            </div>
                    </div>
                </div>
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 2</div>
                    <div class="player bet dice3" :class="set_bet== 8 ? 'active' : ''" @click="_set_bet(8, 2.00)">
                        <div class="bet-wrap">
                            <p>
                            <span class="casino-odds">2.00</span>
                            <span class="casino-batingname">블루</span>
                            </p>
                        </div>
                    </div>
                    <div class="tie bet dice3" :class="set_bet== 7 ? 'active' : ''" @click="_set_bet(7, 4.00)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">4.00</span>
                            <span class="casino-batingname">타이</span>
                        </p></div>
                    </div>
                    <div class="banker bet dice3" :class="set_bet== 6 ? 'active' : ''" @click="_set_bet(6, 2.00)">
                            <div class="bet-wrap"><p>
                                <span class="casino-odds">2.00</span>
                                 <span class="casino-batingname">레드</span>
                            </p></div>
                    </div>
                </div>
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 3</div>
                    <div class="player bet dice3" :class="set_bet== 5 ? 'active' : ''" @click="_set_bet(5, 2.00)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">2.00</span>
                             <span class="casino-batingname">언더</span>
                            </p>
                        </div>
                    </div>
                     <div class="tie bet dice3" :class="set_bet== 4 ? 'active' : ''" @click="_set_bet(4, 4.00)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">4.00</span>
                            <span class="casino-batingname">세븐</span>
                            </p>
                        </div>
                    </div>
                   <div class="banker bet dice3" :class="set_bet== 3 ? 'active' : ''" @click="_set_bet(3, 2.00)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">2.00</span>
                            <span class="casino-batingname">오버</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bet-kind samchigi" v-if="_path2 == 'samchigi'">
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 1</div>
                     <div class="player bet samchigi3 " :class="set_bet== 1 ? 'active' : ''" @click="_set_bet(1, 2.80)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">2.80</span>
                            <span class="casino-batingname">1승</span>
                            </p>
                        </div>
                    </div>
                     <div class="tie bet samchigi3" :class="set_bet== 2 ? 'active' : ''" @click="_set_bet(2, 2.80)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">2.80</span>
                            <span class="casino-batingname">2승</span>
                            </p>
                        </div>
                    </div>
                      <div class="banker bet samchigi3" :class="set_bet== 3 ? 'active' : ''" @click="_set_bet(3, 2.80)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">2.80</span>
                            <span class="casino-batingname">3승</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 2</div>
                     <div class="player bet  samchigi3" :class="set_bet== 4 ? 'active' : ''" @click="_set_bet(4, 1.90)">
                            <div class="bet-wrap"><p>
                                <span class="casino-odds">1.90</span>
                                <span class="casino-batingname">1승2무</span>
                                </p>
                            </div>
                        </div>
                       <div class="player bet  samchigi3" :class="set_bet== 5 ? 'active' : ''" @click="_set_bet(5, 1.90)">
                            <div class="bet-wrap"><p>
                                <span class="casino-odds">1.90</span>
                                 <span class="casino-batingname">1승3무</span>
                                 </p>
                            </div>
                        </div>
                        <div class="player bet  samchigi3" :class="set_bet== 10 ? 'active' : ''" @click="_set_bet(10, 1.30)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.30</span>
                            <span class="casino-batingname">1승2승</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 3</div>
                    <div class="tie bet samchigi3" :class="set_bet== 6 ? 'active' : ''" @click="_set_bet(6, 1.90)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.90</span>
                            <span class="casino-batingname">2승1무</span>
                             </p>
                        </div>
                    </div>
                     <div class="tie bet samchigi3" :class="set_bet== 7 ? 'active' : ''" @click="_set_bet(7, 1.90)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.90</span>
                             <span class="casino-batingname">2승3무</span>
                            </p>
                        </div>
                    </div>
                    <div class="tie bet samchigi3" :class="set_bet== 11 ? 'active' : ''" @click="_set_bet(11, 1.30)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.30</span>
                            <span class="casino-batingname">2승3승</span>
                            </p>
                        </div>
                    </div>
                </div>



                <div class="casino-betting-wrap">
                    <div class="casino-bating">선택 4</div>
                  <div class="banker bet samchigi3" :class="set_bet== 8 ? 'active' : ''" @click="_set_bet(8, 1.90)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.90</span>
                            <span class="casino-batingname">3승1무</span>
                            </p>
                        </div>
                    </div>
                   <div class="banker bet samchigi3" :class="set_bet== 9 ? 'active' : ''" @click="_set_bet(9, 1.90)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.90</span>
                            <span class="casino-batingname">3승2무</span>
                            </p>
                        </div>
                    </div>
                    <div class="banker bet samchigi3" :class="set_bet== 12 ? 'active' : ''" @click="_set_bet(12, 1.30)">
                        <div class="bet-wrap"><p>
                            <span class="casino-odds">1.30</span>
                            <span class="casino-batingname">3승1승</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="casino-beting-cart">
                <div class="casino-cart-title">
                    카지노 배팅카트
                </div>
                <div class='casino-info1'>
                    <div class="casino-title">
                        <span>{{_get_casino_name}}</span>
                        <span class="betting-info">
                            게임선택
                            <b :class="_set_bet_class">{{ _set_bet_name }}</b>
                        </span>
                    </div>
                    <div class="bet_info">
                        <div class="dividend">배당
                            <span>
                                <b class="casino-odds">{{ set_odds.toFixed(2) }}</b>
                            </span>
                        </div>
                        <div class="div_pay">
                            적중금액
                            <span >{{ _showCash(_result_cash.toFixed(0)) }}원</span>
                        </div>
                    </div>

                    <div class="bet_cash_box">
                        배팅금액
                        <div class="input_area">
                            <input type="text" class="casino-bet-cash" v-model="bet_cash" placeholder="0">
                        </div>
                    </div>
                </div>
                <div class="casino-info2">
                    <div class="betting-info">
                        <span>
                            게임선택
                        </span>
                        <b :class="_set_bet_class">{{ _set_bet_name }}</b>
                    </div>

                    <div class="plus-btns">
                        <div><button  class="bet-plus-btn" type="button" @click="_plus_bet_cash(5000)">+5,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="_plus_bet_cash(10000)" >+10,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="_plus_bet_cash(100000)" >+100,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="_plus_bet_cash(300000)" >+300,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="_plus_bet_cash(500000)" >+500,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="_plus_bet_cash(1000000)">+1,000,000</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="bet_cash = ''">초기화</button></div>
                        <div><button class="bet-plus-btn" type="button" @click="bet_cash = myData.cash">올인</button></div>
                    </div>


                    <button class="casino-bet-btn" type="button" @click="_bet_exec" >배팅하기</button>

                </div>
            </div>
        </div>
    </div>

    <CasinoBetLists ref="betList" />

</div>
</template>

<style>

</style>

<script>
    import CasinoBetLists from './BetLists';

    export default {
        data: function () {
            return {
                set_bet : '', set_odds : 0, bet_cash: '', myData : userData,
                // data,
            }
        },
        components: {
        },
        computed: {
            _get_casino_name: function(){
                if(this._path2 == 'baccarat'){
                    return '바카라';
                }
                else if(this._path2 == 'baccarat2'){
                    return '바카라2';
                }
                  else if(this._path2 == 'baccarat_speed'){
                    return '스피드 바카라';
                }
                   else if(this._path2 == 'dragontiger'){
                    return '드래곤 타이거';
                }
                    else if(this._path2 == 'dice'){
                    return '주사위';
                }
                 else if(this._path2 == 'samchigi'){
                    return '삼치기';
                }
            },
            _result_cash: function(){
                if(this.bet_cash == '')
                    return 0;
                else if(Number.isInteger(this.bet_cash))
                    return this.set_odds * this.bet_cash;
                else
                    return this.set_odds * parseFloat(this.bet_cash.replaceAll(',', ''));
            },
            _set_bet_name: function(){
                if(this._path2 == 'baccarat' || this._path2 == 'baccarat2' || this._path2 == 'baccarat_speed'){
                    if(this.set_bet == 1)
                        return '플레이어';
                    else if(this.set_bet == 2)
                        return '뱅커';
                    else if(this.set_bet == 3)
                        return '타이';
                    else if(this.set_bet == 4)
                        return '플레이어 페어';
                    else if(this.set_bet == 5)
                        return '뱅커 페어';
                }else if(this._path2 == 'dragontiger'){
                    if(this.set_bet == 1)
                        return '드래곤';
                    else if(this.set_bet == 2)
                        return '타이거';
                    else if(this.set_bet == 3)
                        return '타이';
                }else if(this._path2 == 'dice'){
                    if(this.set_bet == 1)
                        return '홀';
                    else if(this.set_bet == 2)
                        return '짝';
                    else if(this.set_bet == 3)
                        return '오버';
                    else if(this.set_bet == 4)
                        return '세븐';
                    else if(this.set_bet == 5)
                        return '언더';
                    else if(this.set_bet == 6)
                        return '레드';
                    else if(this.set_bet == 7)
                        return '타이';
                    else if(this.set_bet == 8)
                        return '블루';
                }else if(this._path2 == 'samchigi'){
                    if(this.set_bet == 1)
                        return '1승';
                    else if(this.set_bet == 2)
                        return '2승';
                    else if(this.set_bet == 3)
                        return '3승';
                    else if(this.set_bet == 4)
                        return '1승2무';
                    else if(this.set_bet == 5)
                        return '1승3무';
                    else if(this.set_bet == 6)
                        return '2승1무';
                    else if(this.set_bet == 7)
                        return '2승3무';
                    else if(this.set_bet == 8)
                        return '3승1무';
                    else if(this.set_bet == 9)
                        return '3승2무';
                    else if(this.set_bet == 10)
                        return '1승2승';
                    else if(this.set_bet == 11)
                        return '2승3승';
                    else if(this.set_bet == 12)
                        return '3승1승';
                }
            },
            _set_bet_class: function(){
                if(this._path2 == 'baccarat' || this._path2 == 'baccarat2' || this._path2 == 'baccarat_speed' || this._path2 == 'dragontiger'){
                    if(this.set_bet == 1 || this.set_bet == 4)
                        return 'bet-blue';
                    else if(this.set_bet == 2 || this.set_bet == 5)
                        return 'bet-red';
                    else if(this.set_bet == 3)
                        return 'bet-green';
                }else if(this._path2 == 'dice'){
                    if(this.set_bet == 1 || this.set_bet == 5 || this.set_bet == 8)
                        return 'bet-blue';
                    else if(this.set_bet == 2 || this.set_bet == 3 || this.set_bet == 6)
                        return 'bet-red';
                    else if(this.set_bet == 4 || this.set_bet == 7)
                        return 'bet-green';
                }else if(this._path2 == 'samchigi'){
                    if(this.set_bet == 1 || this.set_bet == 4 || this.set_bet == 5 || this.set_bet == 10)
                        return 'bet-blue';
                    else if(this.set_bet == 3 || this.set_bet == 8 || this.set_bet == 9 || this.set_bet == 12)
                        return 'bet-red';
                    else if(this.set_bet == 2 || this.set_bet == 6 || this.set_bet == 7 || this.set_bet == 11)
                        return 'bet-green';
                }
            },
            _title: function(){
                return getSparkCasinoName(this._path2, '');
            },
            _path2: function(){
                return location.pathname.split('/')[2];
            },
        },
        methods:{
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _set_bet: function(_betID, _betOdds){
                this.set_bet = _betID;
                this.set_odds = _betOdds;
            },
            _plus_bet_cash: function (_plusCash) {
                if(this.bet_cash == '')
                    this.bet_cash = _plusCash;
                else
                    this.bet_cash = parseFloat(this.bet_cash.replaceAll(',', '')) + _plusCash;
            },
            _classBet: function(_odds){
                return _odds != null && _odds != 0;
            },
            _bet_exec: function(){
                let path = location.pathname.split('/')[2];
                if(path == 'baccarat' || path == 'baccarat2' || path == 'baccarat_speed' || path == 'dragontiger' || path == 'dice' || path == 'samchigi'){
                    if(!this.set_bet || !this.set_odds){
                        Swal.fire({icon: 'error', title: '배팅 픽을 선택 후 이용하세요'});
                        return;
                    }
                    let _bet_cash = parseFloat(this.bet_cash.replaceAll(/,/gi, ''));
                    console.log(_bet_cash);
                    if(!_bet_cash || _bet_cash < 5000){
                        Swal.fire({icon: 'error', title: '배팅 금액은 최소 5,000원 입니다.'});
                        return;
                    }

                    Swal.fire({icon: 'warning', title: '배팅 하시겠습니까?',
                        showCancelButton: true,
                        confirmButtonColor: '#005aa7', cancelButtonColor: '#c53434',
                        confirmButtonText: '네', cancelButtonText: '아니오'
                    }).then((result) => {
                        if (result.value) {
                            showLoading();
                            // console.log({ data: this.cart })
                            axios.post(`${location.pathname}`, { set_bet: this.set_bet, set_odds: this.set_odds, bet_cash: _bet_cash })
                            .then((response)=>{
                                //this.console.log(response);
                                if(response.data.result == 0){
                                    Swal.fire({icon: 'error', title: response.data.msg});
                                }
                                else if(response.data.result == 1){
                                    Swal.fire({icon: 'success', title: '배팅 완료!'});
                                    userData.cash = parseInt(userData.cash) - _bet_cash;
                                    this.$refs.betList._exec_get();
                                    // this.cart_clear();
                                }
                            })
                            .finally(function () {
                                hideLoading();
                            });
                        }
                    });
                }
            },
        },
        watch: {
            bet_cash: function(newValue) {
                console.log(newValue)
                const result = newValue.toString().replaceAll(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.$nextTick(() => this.bet_cash = result);
            }
        },
        components: {
            CasinoBetLists,
        },
    }
</script>
