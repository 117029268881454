<template>


    <svg viewBox="0 0 400 214" class="lm_basketball_svg" style="width:100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id="Pattern"  x="0" y="0" width="200" height="108" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="200" height="108" fill="#d39a55"/>
                <image xlink:href="/img/inplay/basketball-noise.png" />
            </pattern>

            <radialGradient id="black-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #000;stop-opacity: 0.15;" />
                <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
            </radialGradient>
            <radialGradient id="white-rg" cx="0.5" cy="0.5" r="0.5" fx="0.5" fy="0.5">
                <stop offset="0%" style="stop-color: #fff;stop-opacity: 0.15;" />
                <stop offset="100%" style="stop-color: #fff;stop-opacity: 0;" />
            </radialGradient>
        </defs>
        <g>
            <rect id="pitch-BG" x="0" y="0" width="400" height="240" fill="url(#Pattern)"></rect>
            <path fill="none" stroke-width="2" opacity="undefined" d="M 6 6 L 394 6 L 394 208 L 6 208 L 6 6 Z" id="Rectangle-245" style="stroke: #ddbc95;"></path>
            <path fill="#D9B470" opacity="undefined" d="M 200 7 L 200 7 L 200 207 L 200 207 L 200 7 Z" id="Rectangle-109" stroke-width="2" style="stroke: #ddbc95;"></path>
            <ellipse fill="none" stroke-width="2" style="stroke: #ddbc95;" cx="200" cy="107" rx="24" ry="24"></ellipse>
        <g>
            <g style="">
                <path stroke-width="2" id="Path-32" d="M 6.9 37.763 L 66.9 37.763 C 66.9 37.763 119.9 45.621 119.9 106.763 C 119.9 166.905 66.9 174.763 66.9 174.763 L 6.9 174.763" fill="none" style="stroke: #ddbc95;"></path>
                <path id="svg_24" d="M 6.941 71.999 L 83.9 71.972 L 83.9 141.941 L 6.9 141.972" opacity="NaN" stroke-width="2" fill="none" style="stroke: #ddbc95;"></path>
                <path stroke-width="2" id="svg_25" d="M 6.88 130.956 L 83.782 130.956 C 83.883 130.985 83.883 83.071 83.782 83.044 C 83.883 83.071 6.98 83.071 6.98 83.071" opacity="NaN" fill="none" style="stroke: #ddbc95;"></path>
                <line id="svg_27" y2="82.2" x2="36.5" y1="78.7" x1="36.5" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="svg_28" y2="82.151" x2="51.2" y1="78.651" x1="51.2" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="svg_30" y2="135.2" x2="36.462" y1="131.7" x1="36.5" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="svg_31" y2="135.222" x2="51.204" y1="131.722" x1="51.204" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="svg_29" y2="82.151" x2="65.2" y1="78.651" x1="65.2" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="svg_32" y2="135.222" x2="65.209" y1="131.722" x1="65.209" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <path stroke-width="2" id="svg_34" d="M 84.9 83.05 C 114.9 85.85 114.9 128.35 84.9 130.95" opacity="NaN" fill="none" style="stroke: #ddbc95;"></path>
                <path stroke-width="2" id="path-1" d="M 59.6 130.95 C 89.6 128.15 89.6 85.65 59.6 83.05" opacity="NaN" fill="none" style="stroke-dasharray: 9, 2; stroke: #ddbc95;" transform="matrix(-1, 0, 0, -1, 141.699997, 214)"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 1, 0)">
                <line stroke="#f2620e" id="svg_17" y2="113.915" x2="5" y1="100.115" x1="5" fill="none" style="stroke-width: 2px;"></line>
                <rect stroke="#f2620e" id="svg_19" height="3.318" width="2.853" y="105.31" x="5.616" fill="#f2620e"></rect>
                <ellipse stroke="#f2620e" stroke-width="1.5" ry="6.636" rx="6.636" id="svg_20" cy="106.976" cx="14.922" fill="none"></ellipse>
                <line stroke="#f2620e" id="svg_21" y2="105.549" x2="5.072" y1="100.794" x1="12.001" fill="none"></line>
                <line stroke="#f2620e" id="svg_22" y2="108.608" x2="5.276" y1="113.391" x1="12.185" fill="none"></line>
            </g>
        </g>
        <g transform="matrix(-1, 0, 0, 1, 400, 0.737)">
            <g style="">
                <path stroke-width="2" id="path-2" d="M 6.9 37.763 L 66.9 37.763 C 66.9 37.763 119.9 45.621 119.9 106.763 C 119.9 166.905 66.9 174.763 66.9 174.763 L 6.9 174.763" fill="none" style="stroke: #ddbc95;"></path>
                <path id="path-3" d="M 6.941 71.999 L 83.9 71.972 L 83.9 141.941 L 6.9 141.972" opacity="NaN" stroke-width="2" fill="none" style="stroke: #ddbc95;"></path>
                <path stroke-width="2" id="path-4" d="M 6.88 130.956 L 83.782 130.956 C 83.883 130.985 83.883 83.071 83.782 83.044 C 83.883 83.071 6.98 83.071 6.98 83.071" opacity="NaN" fill="none" style="stroke: #ddbc95;"></path>
                <line id="line-1" y2="82.2" x2="36.5" y1="78.7" x1="36.5" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="line-2" y2="82.151" x2="51.2" y1="78.651" x1="51.2" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="line-3" y2="135.2" x2="36.462" y1="131.7" x1="36.5" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="line-4" y2="135.222" x2="51.204" y1="131.722" x1="51.204" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="line-5" y2="82.151" x2="65.2" y1="78.651" x1="65.2" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <line id="line-6" y2="135.222" x2="65.209" y1="131.722" x1="65.209" stroke-width="2" fill="none" style="stroke: #ddbc95;"></line>
                <path stroke-width="2" id="path-5" d="M 84.9 83.05 C 114.9 85.85 114.9 128.35 84.9 130.95" opacity="NaN" fill="none" style="stroke: #ddbc95;"></path>
                <path stroke-width="2" id="path-6" d="M 59.6 130.95 C 89.6 128.15 89.6 85.65 59.6 83.05" opacity="NaN" fill="none" style="stroke-dasharray: 9, 2; stroke: #ddbc95;" transform="matrix(-1, 0, 0, -1, 141.699997, 214)"></path>
            </g>
            <g transform="matrix(1, 0, 0, 1, 1, 0)">
                <line stroke="#f2620e" id="line-7" y2="113.915" x2="5" y1="100.115" x1="5" fill="none" style="stroke-width: 2px;"></line>
                <rect stroke="#f2620e" id="rect-1" height="3.318" width="2.853" y="105.31" x="5.616" fill="#f2620e"></rect>
                <ellipse stroke="#f2620e" stroke-width="1.5" ry="6.636" rx="6.636" id="ellipse-1" cy="106.976" cx="14.922" fill="none"></ellipse>
                <line stroke="#f2620e" id="line-8" y2="105.549" x2="5.072" y1="100.794" x1="12.001" fill="none"></line>
                <line stroke="#f2620e" id="line-9" y2="108.608" x2="5.276" y1="113.391" x1="12.185" fill="none"></line>
            </g>
        </g>
        <circle cx="6" cy="6" r="50" fill="url(#black-rg)"></circle>
        <circle cx="394" cy="6" r="50" fill="url(#black-rg)"></circle>
        <circle cx="6" cy="208" r="50" fill="url(#black-rg)"></circle>
        <circle cx="394" cy="208" r="50" fill="url(#black-rg)"></circle>
        <circle cx="30" cy="107" r="50" fill="url(#white-rg)"></circle>
        <circle cx="370" cy="107" r="50" fill="url(#white-rg)"></circle>
        <circle cx="200" cy="107" r="80" fill="url(#white-rg)"></circle>
    </g>
</svg>


</template>

<script>
    export default {
        props: ['stats'],
        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {
        },
        watch: {
        },
    }
</script>
