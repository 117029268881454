<template>
    <div class="">
        <div class="box-header"><i class="fas fa-minus-square"></i> 환전신청</div>

        <div class="message_box">
            <withdraw-msg></withdraw-msg>
        </div>
        <div class="box-content box-main-content">
            <ul>
                <li>현재보유머니</li>
                <li><p>{{ _cash(userInfoData.cash) }} 원</p></li>
                <li>최근 충전금액</li>
                <li><p>{{ _cash(userdata.preDeposit) }} 원 <span class="p1">(+{{ userdata.bonusRate }}%)</span></p></li>
                <li>충전후 배팅금액</li>
                <li>
                    <p>
                        <span v-for="bk in userdata.useCash" :key="bk.betType">{{ _getBetName(bk.betType) }} : <span class="p2">{{ _cash((bk.betCash - bk.cancelCash) * -1) }} </span> </span>
                    </p>
                </li>
                <li>환전</li>
                <li>
                    <p>본인 명의로 신청 가능합니다. </p>
                </li>
                <li>환전비밀번호</li>
                <li><input type="password" class="input-pw" v-model="withdrawPwd"  placeholder="환전 비밀번호를 입력해 주세요."></li>
                <li>환전금액</li>
                <li>
                    <input type="text" class="input-cash" v-model="plusCash"  placeholder="환전 금액 입력">
                    <button class="red cash-clear" type="button" @click="_plusCashClear"><i class="far fa-trash-alt"></i> 초기화</button>
                </li>
                <li class="fast-input">빠른입력</li>
                <li class="fast-input">
                    <button class="blue cash-plus" type="button" @click="_plusCash(10000)">+10,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(30000)" >+30,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(50000)" >+50,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(100000)" >+100,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(500000)" >+500,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(1000000)" >+1,000,000</button>
                </li>
            </ul>
        </div>

        <div class="center-btn-wrap">
            <button class="blue" type="button" @click="_execWithdraw"><i class="far fa-check-circle"></i> 환전신청</button>
        </div>

        <div class="userinfo-sub withdraw-history">
            <ul class="title">
                <li><input type="checkbox" v-model="selectAll" @click="_selectAll"></li>
                <li>신청일자</li>
                <li>환전금액</li>
                <li>처리현황</li>
                <li>처리일시</li>
            </ul>
            <ul v-for="(data, index) in userdata.subData.data" :key="index">
                <li><input type="checkbox" :value="data.ID" v-model="selected"></li>
                <li>{{ _showTime(data.createdAt) }}</li>
                <li>{{ _showCash(data.cash) }}</li>
                <li :class="{'blue-color' : data.status == 3, 'red-color' : data.status == 2}">{{ _showStatus(data.status) }}</li>
                <li>{{ _showUpdatedTime(data) }}</li>
            </ul>
            <div class="empty" v-show="userdata.subData.data.length == 0">
                환전신청 내역이 없습니다.
            </div>

            <pagination :pagination="userdata.subData.meta" @paginate="_exec_get()"></pagination>
        </div>

        <div class="center-btn-wrap">
            <button class="blue" type="button" @click="_execDelete()"><i class="far fa-trash-alt"></i> 선택삭제</button>
            <button class="red" type="button" @click="_execDeleteAll()"><i class="fas fa-trash-alt"></i> 전체삭제</button>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                userdata: data, plusCash: '', withdrawPwd: '', selected: [], selectAll: false,
                userInfoData: userData,
            }
        },
        computed: {
        },
        methods: {
            _cash: function (_cash) {
                if(_cash)
                    return numberWithComma(_cash);

                return '-';
            },
            _getBetName: function (_type) {
                if(_type)
                    return WGameBetKind[_type];
            },
            _plusCash: function(_cash){
                if(this.plusCash == '')
                    this.plusCash = 0;
                this.plusCash = (parseInt(this.plusCash.toString().replace(/[^0-9]/g, '')) + _cash);
            },
            _plusCashClear: function(){
                this.plusCash = '';
            },
            _exec_get: function() {
                showLoading();
                    axios.get(`${location.pathname}?page=` + this.userdata.subData.meta.current_page)
                .then((response)=>{

                    if(response.data.meta){
                        this.userdata.subData = response.data;
                    }

                    hideLoading();
                })
            },
            _execWithdraw: function(){

                var _plusCash = parseInt(this.plusCash.toString().replace(/[^0-9]/g, ''));

                if(!this.withdrawPwd || this.withdrawPwd.length < 4)
                    Swal.fire({icon: 'info', title: '환전 비밀번호를 재대로 입력해 주세요.'});
                else if(!_plusCash || _plusCash < 5000)
                    Swal.fire({icon: 'info', title: '환전금액은 5천원 이상이어야 합니다.'});
                else if(_plusCash > this.userInfoData.cash)
                    Swal.fire({icon: 'info', title: '환전금액은 보유금액보다 같거나 작아야 합니다.'});
                else if(_plusCash % 10000 != 0)
                    Swal.fire({icon: 'info', title: '환전금액은 10,000원단위로 입력해주세요.'});
                else {
                    axios.post('/withdraw', { plusCash: _plusCash, withdrawPwd: this.withdrawPwd })
                    .then((response)=>{
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.meta){
                            Swal.fire({icon: 'success', title: '환전신청이 완료 되었습니다.'});
                            this.userdata.subData = response.data;
                            userData.cash -= _plusCash;
                        }
                    })
                }
            },
            _showTime: function (_time) {
                return datetimeToMDHM(_time);
            },
            _showUpdatedTime: function (_data) {
                if(_data && _data.status != 0)
                    return datetimeToMDHM(_data.updatedAt);
                else
                    return '';
            },
            _showStatus: function (_status) {
                if(_status == 3)
                    return '환전완료';
                else if(_status == 2)
                    return '환전거절';
                else
                    return '대기중';
            },
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _selectAll: function() {
                this.selected = [];
                if (!this.selectAll) {
                    this.selected = _.map(this.userdata.subData, 'ID');
                }
            },
            _execDeleteAll: function() {
                this.selected = [];
                this.selected = _.map(this.userdata.subData, 'ID');
                this._execDelete();
            },
            _execDelete: function() {
                showLoading();
                axios.delete('/withdraw', { data: {depositIDs: this.selected} })
                .then((response)=>{
                    if(response.data.result == 0){
                        Swal.fire({icon: 'error', title: response.data.msg})
                    }
                    else if(response.data.meta){
                        Swal.fire({icon: 'success', title: '삭제 되었습니다.'});
                        this.userdata.subData = response.data;
                    }
                })
                hideLoading();
            },
        },
        mounted: function(){
            if(this.userdata.withdraw_disable_msg)
                Swal.fire({icon: 'info', title: this.userdata.withdraw_disable_msg});
        },
        watch:{
            plusCash(){
                return this.plusCash = this.plusCash.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
    }
</script>
