<template>

    <div class="board-list" @click="_getContent">
        <ul>
            <li>{{ data.ID }}</li>
            <li>{{ data.title }}</li>
        </ul>
        <transition name="fade">
            <div class="content-wrp" v-show="showContent">
                <p v-html="content"></p>
            </div>
        </transition>
    </div>

</template>

<script>


    export default {
        props: ['data', 'selected'],
        data: function () {
            return {
                showContent: false, content: ''
            }
        },
        computed: {
            _showTime: function () {
                return datetimeToYMDHM(this.data.createdAt);
            },
        },
        methods: {
            _getContent: function(){
                if(!this.showContent){
                    showLoading();
                    axios.get(`${location.pathname}/${this.data.ID}`)
                    .then((response)=>{
                        if(response.data.result == 1){
                            this.content = response.data.data;
                        }
                        else{
                            Swal.fire({icon: 'error', title: response.data.msg});
                        }
                        this.showContent = !this.showContent;
                    })
                    .finally(function () {
                        hideLoading();
                    });
                }else{
                    this.showContent = !this.showContent;
                }
            },
            closeForDelete: function(){
                // if(_.includes(this.selected, this.data.ID))
                    this.showContent = false;
            },
        },
        watch:{
        },
    }
</script>
