<template>
    <transition name="slide-fade" mode="out-in" :enter-class="transition_enter_class">
        <!-- v-on:enter="enter" v-on:after-enter="afterEnter" v-on:enter-cancelled="enterCancelled"  -->
        <span class="odds" :key="value">{{ value }}</span>
    </transition>
</template>

<style>
.slide-fade-enter-active {
    /* transform .5s ease,  */
  transition: color 4.5s cubic-bezier(0.95, 0.05, 0.95, 0.05);
  animation: blink 1.2s linear 3;
}
@keyframes blink{
    0%{opacity: 1;}
    50%{opacity: 0.3;}
    100%{opacity: 1;}
}
.slide-fade-leave-active {
  /* transition: transform .3s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
}
.slide-fade-enter
/* .slide-fade-leave-active for <2.1.8 */ {
  /* transform: translateY(-5px); */
  /* opacity: 0; */
}
.slide-fade-enter-red{
  color: #ff6868 !important;
}
.slide-fade-enter-blue{
  color: #7982ff !important;
}
.slide-fade-leave-to {
    /* transform: translateY(5px); */
    /* opacity: 0; */
}
</style>

<script>

    export default {
        props: ['value'],
        data: function () {
            return {
                transition_enter_class : 'slide-fade-enter-red',
            }
        },
        methods: {
            enter: function (el) {

                el.style.color = '#ff3e3e';
            },
            afterEnter: function (el) {
                console.log(el);
                el.style.color = '';
            },
            enterCancelled: function (el) {
                console.log(el);
                el.style.color = '';
            },
        },
        computed: {
        },
        components: {
        },
        watch: {
            value: function (newValue, oldValue) {
                if(newValue < oldValue)
                    this.transition_enter_class = 'slide-fade-enter-red'
                else
                    this.transition_enter_class = 'slide-fade-enter-blue'
                // this.tween(oldValue, newValue)
            }
        },
    }
</script>
