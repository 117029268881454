<template>
</template>

<script>
    import CustomNotification from './CustomNotification';

    export default {

        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {
            _show_name: function(_type){
                if(_type == 1)
                    return '새로운 공지사항이 도착했습니다.';
                else if(_type == 2)
                    return '답변이 도착했습니다.';
                else if(_type == 3)
                    return '충전이 완료 되었습니다.';
                else if(_type == 4)
                    return '환전이 완료 되었습니다.';
            },
            _get_url: function(_type){
                if(_type == 1)
                    return '/inquery';
                else if(_type == 2)
                    return '/inquery';
                else if(_type == 3)
                    return '/deposit';
                else if(_type == 4)
                    return '/withdraw';
            },
        },
        watch:{

        },
        components: {

        },
        created: function () {
            if(noticeID){
                Echo.private(`user.${noticeID}`)
                .listen('.notice', (e) => {
                    console.log(e);
                    this.$toast({component: CustomNotification,  props: {title: this._show_name(e.type), url: this._get_url(e.type)}}, {
                        timeout: 30000, closeOnClick: false, position: "bottom-right"
                    });
                });
            }
        },
        // mounted: function(){
        //     this.$toast({component: CustomNotification,  props: {title: this._show_name(1), url: this._get_url(1)}}, {
        //                 timeout: 30000, closeOnClick: false, position: "bottom-right"
        //             });
        // }
    }
</script>
