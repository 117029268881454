window.getInplayMsg = function (sportID, stats){
    if(stats && stats.ST){
        let ani_code = stats.ST.slice(-4);
        if(sportID == 1){ //축구
            if(ani_code == "0008") //ShootoutScore
                return "승부차기";
            else if(ani_code == "0009") //ShootoutMiss - {0} Penalty Missed
                return "승부차기 실패";
            else if(ani_code == "0021") //  {0} Penalty
                return "승부차기";
            else if(ani_code == "1000") //J
                return "공격-위험지역";
            else if(ani_code == "1001") //tt
                return "공격";
            else if(ani_code == "1002") //et
                return "볼점유";
            else if(ani_code == "1003") //it
                return "골인";
            else if(ani_code == "1004") //yt
                return "코너킥";
            else if(ani_code == "1005") //nt
                return "옐로우카드";
            else if(ani_code == "1006") //at
                return "레드카드";
            else if(ani_code == "1007") //ot
                return "골킥";
            else if(ani_code == "1008") //st
                return "페널티킥";
            else if(ani_code == "1009") //ct
                return "프리킥-위험지역"; //프리킥존1
            else if(ani_code == "1010") //ct
                return "프리킥";
            else if(ani_code == "1011") //Ct
                return "유효슈팅";
            else if(ani_code == "1012") //Et
                return "슈팅";
            else if(ani_code == "1013") //mt
                return "선수교체";
            else if(ani_code == "1014") //rt - kickoff
                return "경기시작"; //
            else if(ani_code == "1015") //rt - halftime
                return "전반전 종료"; //
            else if(ani_code == "1016") //rt - secondhalf
                return "후반전"; //
            else if(ani_code == "1017") //rt - fulltime
                return "후반전 종료"; //
            else if(ani_code == "1018") //rt - startExtraTime1
                return "연장전"; //
            else if(ani_code == "1019") //rt - extraTimeHT - Extra Time Half Time
                return "전반전 추가시간"; //
            else if(ani_code == "1020") //rt - startExtraTime2 - Extra Time Second Half
                return "후반전 추가시간"; //
            else if(ani_code == "1021") //rt - endOfExtraTime - Extra Time Full Time
                return "풀타임 추가시간"; //
            else if(ani_code == "1022") //rt - penaltyshootout - Penalty Shootout
                return "승부차기"; //
            else if(ani_code == "1023") //Tt - PenaltyMissed
                return "패널티 실패";
            else if(ani_code == "1024") //ht
                return "스로인";
            else if(ani_code == "1237") //ht - ThrowZone
                return "스로인";
            else if(ani_code == "1025") //bt
                return "부상";
            else if(ani_code == "1026") //lt
                return "추가시간";
            else if(ani_code == "1233") //dt - MatchInfo
                return "매치정보";
            else if(ani_code == "1234") //pt
                return "오프사이드";
            else if(ani_code == "1236") //ct - FreeKickZone3
                return "프리킥";
            else if(ani_code == "1238") //ut - SubstitutionEnhanced
                return "선수교체";
            else if(ani_code == "1239") //yt - CornerZone2
                return "코너킥";
            else if(ani_code == "1302") //Lt - TakeOnSafePossession
                return "볼점유";
            else if(ani_code == "1301") //It - TakeOnAttack
                return "공격";
            else if(ani_code == "1300") //wt - TakeOnDangerousAttack
                return "공격-위험지역";
            else if(ani_code == "1310") //xt
                return "ReplayPossession";
            else if(ani_code == "1311") //xt
                return "ReplayPossession";
            else if(ani_code == "1312") //xt
                return "ReplayPossession";
            else if(ani_code == "1313") //xt
                return "ReplayPossession";
            else if(ani_code == "1314") //xt
                return "ReplayPossession";
            else if(ani_code == "1315") //xt
                return "ReplayPossession";
            else if(ani_code == "1316") //Ot
                return "ReplayPenalty";
            else if(ani_code == "1317") //xt
                return "ReplayPossession";
            else if(ani_code == "1318") //ht - ThrowZone3
                return "스로인";
            else if(ani_code == "1319") //ot - GoalKick13
                return "골킥";
            else if(ani_code == "1320") //ct - FreeKickZone4
                return "프리킥";
            else if(ani_code == "1321") //ct - FreeKickZone5
                return "프리킥";
            else if(ani_code == "1322") //yt - CornerZone3
                return "코너킥";
            else if(ani_code == "1323") //At
                return "ReplayGoal";
            else if(ani_code == "1325") //E - ReplayTrigger
                return "ReplayTrigger";
            else if(ani_code == "1332")
                return "VR확인중 - 골인"; //videoRefereeReviewingGoal
            else if(ani_code == "1331")
                return "VR확인중 - 레드카드"; //videoRefereeReviewingRedCard
            else if(ani_code == "1333")
                return "VR확인중 - 페널티"; //videoRefereeReviewingPenalty
            else if(ani_code == "1330")
                return "VR 확인중"; //videoRefereeInProgress
            else
                return ani_code;
        }else if(sportID == 3){ //농구
            if(ani_code == "1038") //XYMiss
                return "슈팅실패"; //자유투 실패?
            else if(ani_code == "1062") //PossessionXY
                return "볼점유";
            else if(ani_code == "1077") //Possession
                return "볼점유";
            else if(ani_code == "1074") //TwoPoints
                return "2점슛 득점";
            else if(ani_code == "1075") //ThreePoints
                return "3점슛 득점";
            else if(ani_code == "1078") //FreeThrow
                return "자유투";
            else if(ani_code == "1079") //FreeThrow - FreethrowScored
                return "자유투 득점";
            else if(ani_code == "1080") //FreeThrow - FreethrowMissed
                return "자유투 실패";
            else if(ani_code == "1081") //TimeOut
                return "타임아웃";
            else if(ani_code == "1086") //Foul
                return "파울";
            else if(ani_code == "1082") //GeneralAlert - EndOfQuarter
                return stats.S2 + "쿼터 종료";
            else if(ani_code == "1083")//GeneralAlert - EndOfHalf
                return "2쿼터 종료";
            else if(ani_code == "1084")//GeneralAlert - EndOfMatch
                return "경기 종료";
            else if(ani_code == "1085")//GeneralAlert -OverTime
                return "추가시간";
            else if(ani_code == "1087")//GeneralAlert - Quarter1
                return "1쿼터";
            else if(ani_code == "1088")//GeneralAlert - Quarter2
                return "2쿼터";
            else if(ani_code == "1089")//GeneralAlert - Quarter3
                return "3쿼터";
            else if(ani_code == "1090")//GeneralAlert - Quarter4
                return "4쿼터";
            else if(ani_code == "1091")//GeneralAlert - Half1
                return "2쿼터";
            else if(ani_code == "1092")//GeneralAlert - Half2
                return "4쿼터";
            else if(ani_code == "1095") //TwoPoints
                return "2점슛 실패";
            else if(ani_code == "1094") //ThreePoints
                return "3점슛 실패";
            else
                return ani_code;
        }else if(sportID == 4){ //배구
            if(ani_code == "1248") //Service
                return "서비스"; //
            else if(ani_code == "1249") //Rally
                return "랠리";
            else if(ani_code == "1250") //PointScored
                return "PointScored";
            else if(ani_code == "1251") //LastSixPoints
                return "LastSixPoints";
            else if(ani_code == "1252") //Fault
                return "Fault";
            else if(ani_code == "1253") //Ace
                return "Ace";
            else if(ani_code == "1254") //EndOfSet
                return "EndOfSet";
            else if(ani_code == "1255") //TimeOut
                return "타임아웃";
            else if(ani_code == "1256") //TechnicalTimeOut
                return "테크니컬 타임아웃";
            else if(ani_code == "1257") //LastSixPoints
                return "LastSixPoints";
            else if(ani_code == "1258") //Breakdown
                return "Breakdown";
            else if(ani_code == "1259")//Streak
                return "Streak";
            else if(ani_code == "1260")//PointScored
                return "PointScored";
            else if(ani_code == "1261")//GeneralAlert
                return stats.S2;
            else
                return ani_code;
        }else if(sportID == 21){ //아이스하키
            if(ani_code == "1200") //X - MatchInfo
                return "매치정보"; //
            else if(ani_code == "1270") //b - Possession
                return "볼점유"; //
            else if(ani_code == "1271") //G - TimePenalty
                return stats.S2 + "분 패널티"; //
            else if(ani_code == "1272") //U - TeamAlert
                return "파워플레이 종료"; //
            else if(ani_code == "1273") //E - Shot
                return "슈팅"; //
            else if(ani_code == "1274") //L - Goal
                return "골인"; //
            else if(ani_code == "1275") //F - PenaltyShot
                return "패널티"; //
            else if(ani_code == "1276") //R - PenaltyMissed
                return "패널티 실패"; //
            else if(ani_code == "1277") //W - PulledKeeper
                return "Pulled 키퍼"; //
            else if(ani_code == "1278") //H - KeeperBack
                return "슈팅실패"; //
            else if(ani_code == "1279") //V - GeneralAlert
                return "알림"; //
            else if(ani_code == "1280") //V - GeneralAlert
                return stats.S2; //
            else if(ani_code == "1281") //V - GeneralAlert
                return "알림"; //
            else if(ani_code == "1282") //V - GeneralAlert
                return "알림"; //
            else if(ani_code == "1283") //K - Shootout
                return "슛아웃"; //
            else if(ani_code == "1284") //B - FaceOff
                return "페이스오프"; //
            else if(ani_code == "1285") //B - FaceOff
                return "Puck Dropped"; //
            else if(ani_code == "1286") //B - FaceOff
                return "Face Off Winner"; //
            else if(ani_code == "1287") //N - TimeOut
                return "타임아웃"; //
            else if(ani_code == "1289") //U - TeamAlert
                return "파워 플레이"; //
            else if(ani_code == "1290") //U - TeamAlert
                return "아이싱"; //
            else if(ani_code == "1291") //U - TeamAlert
                return "Penalty Over"; //
            else if(ani_code == "1292") //k - ShotOnTarget
                return "유효슈팅"; //
            else if(ani_code == "1293") //D - ShotOffTarget
                return "슈팅"; //
            else if(ani_code == "1294") //M - KeeperSaved
                return "키퍼 선방"; //
            else if(ani_code == "1295") //O PossessionXY
                return "볼점유"; //
            else
                return ani_code;
        }
        //추가정보는 PD 또는 PG
    }
}

window.getInplayMsg2 = function (sportID, stats){
    if(stats && stats.ST && stats.S2){
        let ani_code = stats.ST.slice(-4);

        if(sportID == 1){
            if(ani_code == "1000" || ani_code == "1300" || ani_code == "1001" || ani_code == "1301" || ani_code == "1002" || ani_code == "1302")
                return stats.S2;
        }
        else if(sportID == 3){
            if(ani_code == "1078")
                return stats.S2.replace(':', ' / ');
        }
        else if(sportID == 21){
            if(ani_code == "1270" || ani_code == "1295")
                return stats.S2.replace('ON', ' 대 ');
        }
    }
}


window.getLmt = function (sportID, stats){
    let returnClass = 'center';
    let ani_code = stats.ST.slice(-4);
    if(sportID == 1){ //축구
        if(ani_code == "1000" || ani_code == "1300"){
            returnClass = "d-attack";
        }
        else if(ani_code == "1001" || ani_code == "1301"){
            returnClass = "attack";
        }
        else if(ani_code == "1002" || ani_code == "1302"){
            returnClass = "poss";
        }
    }else if(sportID == 3){ //농구
        if(ani_code == "1062" || ani_code == "1077"){
            if(stats.PT && stats.PT.includes(',')){
                let team_code = stats.ST.slice(0, 1);
                let _x = parseFloat(stats.PT.split(',')[0]);
                if(team_code == 1){
                    if(_x > 0.8)
                        returnClass = "d-attack";
                    else if(_x > 0.65)
                        returnClass = "attack";
                }
                else if(team_code == 2){
                    if(_x < 0.2)
                        returnClass = "d-attack";
                    else if(_x < 0.35)
                        returnClass = "attack";
                }
            }
            if(returnClass == 'center')
                returnClass = "poss";
        }
    }else if(sportID == 21){ //아이스하키
        if(ani_code == "1270" || ani_code == "1295"){

            if(stats.PT && stats.PT.includes(',')){
                let team_code = stats.ST.slice(0, 1);
                let _x = parseFloat(stats.PT.split(',')[0]);
                if(team_code == 1){
                    if(_x > 0.8)
                        returnClass = "d-attack";
                    else if(_x > 0.65)
                        returnClass = "attack";
                }
                else if(team_code == 2){
                    if(_x < 0.2)
                        returnClass = "d-attack";
                    else if(_x < 0.35)
                        returnClass = "attack";
                }
            }
            if(returnClass == 'center')
                returnClass = "poss";
        }
    }

    return returnClass;
}

window.CheckAddHistory = function (sportID, stats){
    let ani_code = stats.ST.slice(-4);
    if(sportID == 1){ //축구
        if(ani_code == "1000" || ani_code == "1300"){
            return true;
        }else if(ani_code == "1001" || ani_code == "1301"){
            return true;
        }else if(ani_code == "1002" || ani_code == "1302"){
            return true;
        }
    }else if(sportID == 3){ //농구
        if(ani_code == "1062" || ani_code == "1077"){
            return true;
        }
    }else if(sportID == 21){ //아이스하키
        if(ani_code == "1270" || ani_code == "1295"){
            return true;
        }
    }
    return false;
}


window.GetStatWait = function (sportID, stats){
    let ani_code = stats.ST.slice(-4);
    if(sportID == 1){ //축구
        if(ani_code == "1003" || ani_code == "1011" || ani_code == "1012" || ani_code == "1023"){ //슈팅
            return 15;
        }
    }else if(sportID == 3){ //농구
        if(ani_code == "1038" || ani_code == "1095" || ani_code == "1074" || ani_code == "1086"){
            return 15; //슈팅 || 2점 실패 || 2점슛 성공 || 파울
        }
        else if(ani_code == "1094" || ani_code == "1075"){
            return 15; //3점슛
        }
        else if(ani_code == "1079" || ani_code == "1080"){
            return 15; //자유투 성공 실패
        }
        else if(ani_code == "1078"){
            return 5; //자유투
        }
    }else if(sportID == 21){ //아이스하키
        if(ani_code == "1273" || ani_code == "1274" || ani_code == "1292" || ani_code == "1294" || ani_code == "1293"){ //슈팅
            return 15;
        }
    }
    return 0;
}

window.IsShotting = function (sportID, stats){

    let ani_code = stats.ST.slice(-4);
    if(sportID == 1){
        if(ani_code == "1012" || ani_code == "1003" || ani_code == "1011" || ani_code == "1023"){
            return 'st'; //슈팅
        }
    }else if(sportID == 3){
        if(ani_code == "1038" || ani_code == "1094" || ani_code == "1075"){
            return 'st3'; //슈팅실패 || 3점 슈팅실패 || 3점 슈팅성공
        }
        else if(ani_code == "1095" || ani_code == "1074"){
            return 'st2'; //2점 슈팅실패 || 2점 슈팅성공
        }
        else if(ani_code == "1078" || ani_code == "1079" || ani_code == "1080"){
            return 'fs'; //자유투
        }
    }else if(sportID == 21){
        if(ani_code == "1273" || ani_code == "1274" || ani_code == "1292" || ani_code == "1294" || ani_code == "1293"){
            return 'st'; //슈팅
        }
    }
},

window.GetShotResult = function (sportID, stats){
    let ani_code = stats.ST.slice(-4);
    if(sportID == 1){ //축구
        if(ani_code == "1003"){
            return 'p'; //득점 -
        }
        else if(ani_code == "1011"){
            return 's'; //방어 - 유효슈팅
        }
        else if(ani_code == "1012" || ani_code == "1023"){
            return 'f'; // 실패 - 슈팅 || 패널티실패
        }
    }else if(sportID == 3){ //농구
        if(ani_code == "1074" || ani_code == "1075" || ani_code == "1079"){
            return 'p'; //득점 -
        }
        else if(ani_code == "1038" || ani_code == "1080" || ani_code == "1094" || ani_code == "1095"){
            return 'f'; // 실패 -
        }
    }else if(sportID == 21){ //아이스하키
        if(ani_code == "1274"){
            return 'p'; //득점 -
        }
        else if(ani_code == "1292" || ani_code == "1294"){
            return 's'; //방어 -
        }
        else if(ani_code == "1293" || ani_code == "1273"){
            return 'f'; // 실패 -
        }
    }
}
