<template>

    <div  class="gamelist" :class="data.isWaiting == true ? 'is-waiting' : ''">

        <div class="league">
            <img width="12" :src="`/img/icon/sport/${data.sportID}.png`" />{{ _showSportName(data.sportID) }}
            <i class="fas fa-angle-double-right"></i>
            <div class="flag">
                <img class="leg_img" width="18" :src="imageLeagueUrl" />
                <div  class="flag-wave"></div>
            </div>
            <span class="name">{{ data.leagueName.name }}</span>
            <span class="time">{{ _startTime }}</span>
            <span @click="_exec_get_games" class="count">
                {{ betCount }}<i class="fas fa-chevron-right"></i>
            </span>
        </div>

        <ul class="im">
            <li class="status" v-if="extData && extData.SC">
                <div class="stime">
                    <span>{{ extData.PE }}</span>
                    <span v-if="data.sportID != 4">{{ timerStr }}</span>
                </div>
                <div class="score">
                    <span>{{ getScore1 }}</span>
                    <span>{{ getScore2 }}</span>
                </div>
            </li>
            <li class="t-wrap">
                <div class="t-name">
                    <span class="name">{{ data.homeName.name}}</span>
                    <span class="vs">VS</span>
                    <span class="name">{{data.awayName.name}}</span>
                </div>
                <GameBetList v-if="mainBet" :data="mainBet" :gameList="data" :isMainBet="true" :isInplay="true" :iconType="2"></GameBetList>
                <div v-else class="game-lock">
                    <i class="fas fa-unlock-alt"></i>
                </div>
            </li>
        </ul>
    </div>

</template>

<script>

    export default {
        props: ['data', 'extData', 'plusSec', 'maintype'],
        data: function () {
            return {
                showBetList: false,
                timer : null, timerStr : '',
            }
        },
        methods: {
            _showSportName: function (_sport_id) {
                return getSportName(_sport_id);
            },
            _exec_get_games: function() {
                if(this.showBetList)
                    this.showBetList = false;
                else{
                    this.$emit('selectGameList', this.data);
                }
            },
            getTimer: function(){
                if(this.extData && this.extData.M && this.extData.S){
                    if(this.extData.P && this.extData.T && this.extData.P == '1'){
                        let durate = null;
                        if(this.extData.D && this.extData.D == '1')
                            durate = moment.duration(((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000) - (moment().valueOf() - moment.tz(this.extData.T, 'YYYYMMDDHHmmss', 'Europe/London').valueOf()));
                        else
                            durate = moment.duration(moment().valueOf() - moment.tz(this.extData.T, 'YYYYMMDDHHmmss', 'Europe/London').valueOf() + ((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000));
                        // console.log('aa', ((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000));
                        // console.log('bb', (moment().valueOf() - moment(this.extData.T, 'YYYYMMDDHHmmss').add(9, 'h').valueOf()));
                        // console.log('cc', ((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000) - (moment().valueOf() - moment(this.extData.T, 'YYYYMMDDHHmmss').add(9, 'h').valueOf()) + interval);

                        if(durate)
                            this.timerStr = `${this.parserTimeStr(durate.hours() * 60 + durate.minutes())}:${this.parserTimeStr(durate.seconds())}`;
                    }else{
                        this.timerStr = `${this.parserTimeStr(this.extData.M)}:${this.parserTimeStr(this.extData.S)}`;
                    }
                }
            },
            parserTimeStr: function(_number){
                if(_number < 10)
                    return `0${_number}`;
                return _number;
            },
        },
        computed: {
            mainBet: function () {
                let _betKind1 = '';
                let _betKind2 = '';
                if(this.maintype == 1){
                    _betKind1 = '01';
                    _betKind2 = '04';
                }else if(this.maintype == 2){
                    _betKind1 = '06';
                    _betKind2 = '26';
                }else if(this.maintype == 3){
                    _betKind1 = '08';
                    _betKind2 = '28';
                }

                return _.head(_.chain(_.filter(this.data.games, function(_game) { return _game.betKind.toString().substr(1, 1) == '0' && (_game.betKind.toString().substr(2, 2) == _betKind1 || _game.betKind.toString().substr(2, 2) == _betKind2); }))
                .groupBy("betKind")
                .map((value, key) => ({ betKind: key, games: value }))
                .value());
            },
            _startTime: function () {
                return getStartTime(this.data.startTime, this.data.sportID, this.plusSec[this.data.sportID]);
            },
            imageLeagueUrl: function(){
                return '/img/flag/league/' + ((this.data.leagueName.flag == null) ? 'world.svg' : this.data.leagueName.flag);
            },
            // betGroup: function(){
            //     return _.chain(_.reject(this.data.games, {'betKind' : this.mainBet.betKind}))
            //     // Group the elements of Array based on `color` property
            //     .groupBy("betKind")
            //     // `key` is group's name (color), `value` is the array of objects
            //     .map((value, key) => ({ betKind: key, games: value }))
            //     .value();
            // },
            betCount : function(){
                return _.size(_.chain(this.data.games).filter('vi').groupBy('betKind').value());
            },
            getScore1: function(){
                if(this.extData && this.extData.SC)
                    return this.extData.SC.split('-')[0];
                return '';
            },
            getScore2: function(){
                if(this.extData && this.extData.SC)
                    return this.extData.SC.split('-')[1];
                return '';
            },
        },
        mounted: function(){
            this.timer = setInterval(() => this.getTimer(), 1000);
        },
        components: {
        },
    }
</script>
