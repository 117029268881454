<template>
<div class="pagination-wrap">
    <ul class="pagination">
        <li v-if="pagination.current_page > 1">
            <a href="javascript:void(0)" aria-label="Previous" v-on:click.prevent="changePage(pagination.current_page - 10)">
                <i class="fas fa-angle-double-left"></i>
            </a>
        </li>
        <li v-for="(page, index) in pagesNumber" :key="index" :class="{'active': page == pagination.current_page}">
            <a href="javascript:void(0)" v-if="page != pagination.current_page" v-on:click.prevent="changePage(page)">{{ page }}</a>
            <span href="javascript:void(0)" v-else v-on:click.prevent="changePage(page)">{{ page }}</span>
        </li>
        <li v-if="pagination.current_page < pagination.last_page">
            <a href="javascript:void(0)" aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 10)">
                <i class="fas fa-angle-double-right"></i>
            </a>
        </li>
    </ul>
    </div>
</template>
<script>
  export default{
    props: {
        pagination: {
            type: Object,
            required: true
        },
        offset: {
            type: Number,
            default: 5
        }
    },
    computed: {
        pagesNumber() {
            if (!this.pagination.to) {
                return [];
            }
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }

            let to = from + (this.offset * 2);
            if (to > this.pagination.last_page) {
                to = this.pagination.last_page;

                from = to - (this.offset * 2);
                if (from < 1) {
                    from = 1;
                }   
            }

            let pagesArray = [];
            for (let page = from; page <= to; page++) {                                
                pagesArray.push(page);
            }
            return pagesArray;
        }
    },
    methods : {
        changePage(page) {
            if(this.pagination.current_page != page){

                if(page > this.pagination.last_page)
                    this.pagination.current_page = this.pagination.last_page;
                else if(page < 0)
                    this.pagination.current_page = 0;
                else
                    this.pagination.current_page = page;

                
                this.$emit('paginate');             
            }
        }
    }
  }
</script>