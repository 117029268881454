// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!../../../../../../../../node_modules/@fullcalendar/core/main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!../../../../../../../../node_modules/@fullcalendar/daygrid/main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".demo-app-calendar{margin:0 auto}.fc-unthemed td.fc-today{background:transparent;color:orange}.fc-content{text-align:center}span.fc-title>span{padding-left:5px}", "",{"version":3,"sources":["webpack://./vendor/tt-pack/users/resources/T21/js/components/User/Attendance.vue"],"names":[],"mappings":"AAMA,mBACE,aAHF,CAMA,yBACI,sBAAA,CACA,YAHJ,CAKA,YACI,iBAFJ,CAIA,mBACI,gBADJ","sourcesContent":["\n// you must include each plugins' css\n// paths prefixed with ~ signify node_modules\n@import '~@fullcalendar/core/main.css';\n@import '~@fullcalendar/daygrid/main.css';\n\n.demo-app-calendar {\n  margin: 0 auto;\n}\n\n.fc-unthemed td.fc-today {\n    background: transparent;\n    color: orange;\n}\n.fc-content{\n    text-align: center;\n}\nspan.fc-title>span {\n    padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
