<template>
    <div class="inplay-wrap">
        <div class="ts">
            <div class="home">
                <span class="color" :style="`background-color:${getColorHome}`"></span><span class="name">{{ data.homeName.name }}</span><span class="score">{{ getScoreHome }}</span>
            </div>
            <div class="away">
                <span class="color" :style="`background-color:${getColorAway}`"></span><span class="name">{{ data.awayName.name }}</span><span class="score">{{ getScoreAway }}</span>
            </div>
        </div>

        <div class="inplay-rader" :class="{'ice-hockey' : data.sportID == 21, 'soccer' : data.sportID == 1}" ref="rader">
            <div class="time">
                <div class="tl" :style="`width:${_getTimeLine}%`"></div>
                <span v-if="extData && extData.PE" class="str" style="margin-right:-2px">{{ extData.PE }}</span>
                <span v-if="data.sportID != 4" class="str">{{ _getTimeStr }}</span>
                <span v-if="extData && extData.A" class="str" style="margin-left:-2px">+{{ extData.A }}분</span>
            </div>
            <div class="lmt home" :class="_now_team == 1 ? _class_lmt : ''">
                <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                    <defs>
                        <radialGradient id="home-rg" cx="1.1" cy="0.5" r="1.2" fx="1.5" fy="0.5">
                        <stop offset="0%" style="stop-color: #000;stop-opacity: 0.9;" />
                        <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
                        </radialGradient>
                    </defs>
                    <path  d='M50 50 L40 0 H0 V100 H40 Z' fill="url(#home-rg)"  />
                </svg>
            </div>
            <div class="lmt away" :class="_now_team == 2 ? _class_lmt : ''">
                <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                    <defs>
                        <radialGradient id="away-rg" cx="1.1" cy="0.5" r="1.2" fx="1.5" fy="0.5">
                        <stop offset="0%" style="stop-color: #000;stop-opacity: 0.9;" />
                        <stop offset="100%" style="stop-color: #000;stop-opacity: 0;" />
                        </radialGradient>
                    </defs>
                    <path  d='M50 50 L40 0 H0 V100 H40 Z' fill="url(#away-rg)"  />
                </svg>
            </div>
            <div class="kick" v-show="_is_kick">
                <svg preserveAspectRatio="none">
                    <defs>
                        <radialGradient id="kick-rg" cx="0" cy="0.5" r="1" fx="0" fy="0.5">
                        <stop offset="0%" style="stop-color: #111;stop-opacity: 0.9;" />
                        <stop offset="100%" style="stop-color: #111;stop-opacity: 0;" />
                        </radialGradient>
                    </defs>
                    <g class="kick-g" id="ml1-NarrowKickRadial" opacity="1" :style="_kick_g_style">
                        <path id="ml1-NarrowKickRadialInner" fill="url(#kick-rg)" d="M0,0l90,30c5-7,12-12,12-30s-7-23-12-30z" :style="_get_kick_style"></path>
                    </g>
                </svg>
            </div>

            <div class="ball-histories">
                <svg preserveAspectRatio="none">
                    <template v-for="_ball, idx in ball_his.balls.slice(-6).reverse()">
                        <!-- <line class="path" :x1="_ball.b1.X" :y1="_ball.b1.Y" :x2="_ball.b2.X" :y2="_ball.b2.Y" stroke-width="2" stroke="#b48e02" :opacity="(8 - idx) * 0.1" :key="'l' + idx" /> -->
                        <template v-if="idx == 0">
                            <path :class="`path-ani${ani_num ? 1 : 2}`" :style="`stroke-dasharray: ${line_distance(_ball.b1, _ball.b2)}px;stroke-dashoffset: ${line_distance(_ball.b1, _ball.b2)}px;`" :d="`M${_ball.b1.X} ${_ball.b1.Y}, L${_ball.b2.X} ${_ball.b2.Y}`" stroke-width="2" :opacity="(7 - idx) * 0.1" :key="'l' + idx" />
                        </template>
                        <template v-else>
                            <path :d="`M${_ball.b1.X} ${_ball.b1.Y}, L${_ball.b2.X} ${_ball.b2.Y}`" stroke-width="2" :opacity="(7 - idx) * 0.1" :key="'l' + idx" />
                        </template>
                        <circle :cx="_ball.b1.X" :cy="_ball.b1.Y" r="3" :opacity="(7 - idx) * 0.1" :key="'c' + idx" />
                    </template>
                </svg>
            </div>
            <div class="shot" v-if="_is_shot">
                <svg preserveAspectRatio="none">
                    <defs>
                        <path id="path1" :d="`M${_get_shot_pos.X} ${_get_shot_pos.Y} Q${_get_shot_pos.X + ((_goal_pos.X - _get_shot_pos.X) / 2)} ${_get_shot_pos.Y - ((_get_shot_pos.Y - _goal_pos.Y) / 2) - get_shot_minusY} ${_goal_pos.X} ${_goal_pos.Y}`" />
                        <mask id="mask1" maskUnits="userSpaceOnUse">
                            <use class="mask" xlink:href="#path1" :style="`stroke-dasharray: ${line_distance(_get_shot_pos, _goal_pos) + 20}px;stroke-dashoffset: ${line_distance(_get_shot_pos, _goal_pos) + 20}px;`"/>
                        </mask>
                    </defs>
                    <use class="paths" xlink:href="#path1" mask="url(#mask1)" />
                    <path v-if="get_shot_result == 'f'" class="shot-result fail" d="M 12 10.095 L 7.842 5.991 L 11.944 1.849 L 10.095 0 L 5.988 4.158 L 1.831 0.056 L 0 1.89 L 4.158 6.009 L 0.056 10.169 L 1.89 12 L 6.007 7.842 L 10.151 11.944 Z"
                        :style="`transform: translate(${_goal_pos.X - 6}px, ${_goal_pos.Y - 14}px);`"/>
                    <path v-else-if="get_shot_result == 's'" class="shot-result shield" d="M 6 3.295 C 4.782 4.031 3.684 4.361 2.667 4.517 L 2.667 9.69 C 2.667 10.761 2.943 10.98 6 12.863 L 6 3.295 Z M 0 2 L 0 9.69 C 0 12.759 2.135 13.559 6 16 C 9.865 13.559 12 12.759 12 9.69 L 12 2 C 9.656 2 8.247 1.911 6 0 C 3.753 1.911 2.344 2 0 2 Z M 1.333 3.307 C 2.964 3.224 4.333 2.899 6 1.696 C 7.667 2.899 9.036 3.224 10.667 3.307 L 10.667 9.69 C 10.667 11.718 9.54 12.243 6 14.428 C 2.458 12.241 1.333 11.718 1.333 9.69 L 1.333 3.307 Z"
                        :style="`transform: translate(${_goal_pos.X - 6}px, ${_goal_pos.Y - 17}px);`"/>
                    <path v-else-if="get_shot_result == 'p'" class="shot-result goal" d="M 12.749 6.426 C 12.799 6.749 12.833 7.078 12.833 7.417 C 12.833 10.959 9.959 13.833 6.417 13.833 C 2.872 13.833 0 10.959 0 7.417 C 0 3.872 2.872 1 6.417 1 C 7.785 1 9.052 1.433 10.094 2.162 L 9.265 3.012 C 8.443 2.479 7.466 2.167 6.417 2.167 C 3.522 2.167 1.167 4.522 1.167 7.417 C 1.167 10.311 3.522 12.666 6.417 12.666 C 9.271 12.666 11.596 10.375 11.661 7.536 L 12.749 6.426 Z M 12.194 1.68 L 6.708 7.304 L 4.466 5.196 L 2.661 7.002 L 6.708 10.916 L 14 3.486 L 12.194 1.68 Z"
                        :style="`transform: translate(${_goal_pos.X - 6}px, ${_goal_pos.Y - 14}px);`"/>
                </svg>
            </div>
            <div class="ball-position" >
                <div v-show="_xy" class="point" :style="_xy">
                    <div class="ping"></div>
                    <div class="ping ping2"></div>
                </div>
                <div v-show="!_xy && _kick_xy" class="point" :style="_kick_xy">
                </div>
                <div class="ref-point" ref="point">

                </div>
            </div>

            <div v-show="_get_msg" class="team-msg" :class="_class_team + _class_lmt" :style="_msg_style">
                <div>
                    <div>
                        <p class="tn" v-show="_show_team">{{_show_team}}</p>
                        <p class="msg">{{_get_msg}}</p>
                        <p class="msg2" v-show="_get_msg2">{{_get_msg2}}</p>
                    </div>
                    <div v-show="_show_team_color" class="team-color" :style="`background-color:${_show_team_color}`"></div>
                </div>
            </div>

            <div class="ml-soccer-svg inplay-bg">
                <Soccer v-if="data.sportID == 1"></Soccer>
                <Basketball v-else-if="data.sportID == 3"></Basketball>
                <IceHockey v-else-if="data.sportID == 21"></IceHockey>
                <Volleyball v-else-if="data.sportID == 4"></Volleyball>
            </div>
        </div>
    </div>
</template>

<script>
    import Basketball from './Sport/Basketball.vue';
    import IceHockey from './Sport/IceHockey.vue';
    import Soccer from './Sport/Soccer.vue';
    import Volleyball from './Sport/Volleyball.vue';

    export default {
        props: ['data', 'extData', 'plusSec'],
        data: function () {
            return {
                ball_his: { team: '0', ball: null, balls: [] },
                ani_num: true,
                timer : null, timeCounter : '', timer_stat: null, stat_wait: 0,
                stat: null, stats : [],
            }
        },
        components: {
            Soccer, Basketball, IceHockey, Volleyball
        },
        computed: {
            _xy: function(){
                if(this.data.sportID == 3 && this._is_shot == "fs"){
                    return `transform: translate(${this._get_shot_pos.X}px, ${this._get_shot_pos.Y}px)`;
                }
                else if(this.stat && this.stat.PT){
                    let ball_pos = this._get_ball_pos();

                    if(ball_pos){
                        return `transform: translate(${ball_pos.X}px, ${ball_pos.Y}px)`;
                    }
                }
            },
            _class_lmt: function() {
                if(this._is_shot){
                    return ' shot';
                }else if(this.stat){
                    return ' ' + getLmt(this.data.sportID, this.stat);
                }
            },
            _msg_style: function() {
                if(this._is_shot && this._now_team){
                    if(this._now_team == 1)
                        return `right: ${this.$refs.point.clientWidth - this._get_shot_pos.X + 10}px`;
                    else if(this._now_team == 2)
                        return `left: ${this._get_shot_pos.X + 10}px`;
                }
            },
            _now_team: function(){
                if(this.stat && this.stat.ST){
                    if(this.stat.ST.length >= 5){
                        let team_code = null;
                        if(this.stat.ST.includes('^')){
                            let team_code2 = this.stat.ST.split('^');
                            if(team_code2.length >= 2)
                                team_code = team_code2[1].substring(0, 1);
                        }else if(this.stat.ST.includes('_')){
                            let team_code2 = this.stat.ST.split('_');
                            if(team_code2.length >= 2)
                                team_code = team_code2[1].substring(0, 1);
                        }else{
                            team_code = this.stat.ST.substring(0, 1);
                        }
                        return team_code;
                    }
                }
            },
            _class_team: function() {
                if(this._now_team){
                    if(this._now_team == 1)
                        return 'home';
                    else if(this._now_team == 2)
                        return 'away';
                }
            },
            _show_team_color: function() {
                if(this._now_team){
                    if(this._now_team == 1){
                        return this.getColorHome;
                    }else if(this._now_team == 2){
                        return this.getColorAway;
                    }
                }
            },
            _show_team: function() {
                if(this._now_team){
                    if(this._now_team == 1){
                        if(this.data.homeName)
                            return this.data.homeName.name;
                        else
                            return '홈팀';
                    }else if(this._now_team == 2){
                        if(this.data.awayName)
                            return this.data.awayName.name;
                        else
                            return '원정';
                    }
                }
            },
            getScoreHome: function(){
                if(this.extData && this.extData.SC)
                    return this.extData.SC.split('-')[0];
                return '';
            },
            getScoreAway: function(){
                if(this.extData && this.extData.SC)
                    return this.extData.SC.split('-')[1];
                return '';
            },
            getColorHome: function(){
                if(this.extData && this.extData.CO)
                    return this.extData.CO.split(',')[0];
                return '';
            },
            getColorAway: function(){
                if(this.extData && this.extData.CO)
                    return this.extData.CO.split(',')[1];
                return '';
            },
            _get_kick_style: function() {
                if(this._is_kick){
                    let ball_pos = this._get_kick_pos();

                    let next_pt = { X: 1, Y :1 };
                    if(this._now_team == 1){
                        if(this._is_kick == "f"){//프리킥
                            next_pt = { X : this.$refs.point.clientWidth * 0.995, Y: this.$refs.point.clientHeight * 0.5 };
                        }else{
                            next_pt = { X : this.$refs.point.clientWidth * 0.9, Y: this.$refs.point.clientHeight * 0.5 };
                        }
                    }
                    else{
                        if(this._is_kick == "f"){//프리킥
                            next_pt = { X : this.$refs.point.clientWidth * 0.005, Y: this.$refs.point.clientHeight * 0.5 };
                        }else{
                            next_pt = { X : this.$refs.point.clientWidth * 0.1, Y: this.$refs.point.clientHeight * 0.5 };
                        }
                    }

                    let scaleY = 1.2;
                    if(this._is_kick == 'g')//골킥
                        scaleY = 2.3;
                    else if(this._is_kick == 't')//스로인
                        scaleY = 1.7;
                    else if(this._is_kick == 'f' || this._is_kick == 'fd')//프리킥
                        scaleY = 1.6;

                    let scaleX = 1.1;
                    if(this._is_kick == 't')//스로인
                        scaleX = 1.0;
                    else if(this._is_kick == 'g')//골킥
                        scaleX = 1.5;
                    else if(this._is_kick == 'c')//코너킥
                        scaleX = 1.2;
                    else if(this._is_kick == 'fd')//프리킥 - 위험지역
                        scaleX = 1.1;
                    else if(this.$refs.point.clientWidth / this.line_distance(ball_pos, next_pt) > 1.6)
                        scaleX = 1.5;

                    let getAngle = this.line_angle(ball_pos, next_pt);
                    return `transform: translate(${ball_pos.X}px, ${ball_pos.Y}px) rotate(${getAngle}deg) scaleX(${scaleX}) scaleY(${scaleY});`;
                }
                return '';
            },
            _kick_g_style: function(){
                if(this._is_kick){
                    let ball_pos = this._get_kick_pos();
                    if(ball_pos)
                        return `transform-origin: ${ball_pos.X}px ${ball_pos.Y}px;`;
                }
            },
            _kick_xy: function(){
                if(this._is_kick){
                    let ball_pos = this._get_kick_pos();
                    if(ball_pos){
                        return `transform: translate(${ball_pos.X}px, ${ball_pos.Y}px)`;
                    }
                }
            },
            _is_kick: function() {
                if(this._now_team){
                    let ani_code = this.stat.ST.slice(-4);
                    if(this.data.sportID == 1){
                        if(ani_code == "1004"){
                            return 'c'; //코너킥
                        }else if(ani_code == "1007"){
                            return 'g'; //골킥
                        }else if(ani_code == "1010"){
                            return 'f'; //프리킥
                        }else if(ani_code == "1009"){
                            return 'fd'; //프리킥 - 위험지역
                        }else if(ani_code == "1024"){
                            return 't'; //스로인
                        }
                    }
                }
                return null;
            },
            _get_msg: function() {
                return getInplayMsg(this.data.sportID, this.stat);
            },
            _get_msg2: function() {
                return getInplayMsg2(this.data.sportID, this.stat);
            },
            _getTimeLine: function() {
                if(this.timeCounter){
                    let fTime = 0;
                    if(this.data.sportID == 1)
                        fTime = 90 * 60;
                    else if(this.data.sportID == 3){
                        if(this.data.leagueName.name.includes('NBA'))
                            fTime = 12 * 60;
                        else
                            fTime = 10 * 60;
                    }
                    else if(this.data.sportID == 21)
                        fTime = 20 * 60;

                    if(this.extData.D && this.extData.D == '1')
                        return (1 - (((((this.timeCounter.hours() * 60) + this.timeCounter.minutes()) * 60) + this.timeCounter.seconds()) / (fTime))) * 100;
                    else
                        return ((((this.timeCounter.hours() * 60) + this.timeCounter.minutes()) * 60) + this.timeCounter.seconds()) / (fTime) * 100;
                }

            },
            _getTimeStr: function() {
                if(this.timeCounter)
                    return `${this.parserTimeStr(this.timeCounter.hours() * 60 + this.timeCounter.minutes())}:${this.parserTimeStr(this.timeCounter.seconds())}`
            },
            _is_shot: function() {
                if(this._now_team)
                    return IsShotting(this.data.sportID, this.stat);
            },
            _goal_pos: function() {
                if(this.data.sportID == 1){
                    if(this._now_team == 1)
                        return {X: this.$refs.point.clientWidth * 0.99, Y: this.$refs.point.clientHeight * 0.5};
                    else if(this._now_team == 2)
                        return {X: this.$refs.point.clientWidth * 0.01, Y: this.$refs.point.clientHeight * 0.5};
                }else if(this.data.sportID == 3){
                    if(this._now_team == 1)
                        return {X: this.$refs.point.clientWidth * 0.972, Y: this.$refs.point.clientHeight * 0.5};
                    else if(this._now_team == 2)
                        return {X: this.$refs.point.clientWidth * 0.028, Y: this.$refs.point.clientHeight * 0.5};

                }else if(this.data.sportID == 21){
                    if(this._now_team == 1)
                        return {X: this.$refs.point.clientWidth * 0.95, Y: this.$refs.point.clientHeight * 0.5};
                    else if(this._now_team == 2)
                        return {X: this.$refs.point.clientWidth * 0.05, Y: this.$refs.point.clientHeight * 0.5};

                }
            },
            _get_shot_pos: function() {
                let ball_pos = this._get_ball_pos();
                if(this._is_shot == "fs"){
                    if(this._now_team == 1)
                        ball_pos = { X: this.$refs.point.clientWidth * 0.797, Y: this.$refs.point.clientHeight * 0.5};
                    else if(this._now_team == 2)
                        ball_pos = { X: this.$refs.point.clientWidth * 0.203, Y: this.$refs.point.clientHeight * 0.5};
                }
                else if(!ball_pos){
                    if(this.data.sportID == 1){
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.7, Y: this.$refs.point.clientHeight * 0.55};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.3, Y: this.$refs.point.clientHeight * 0.55};
                    }else if(this.data.sportID == 3){
                        if(this._is_shot == "st2"){
                            if(this._now_team == 1)
                                ball_pos = { X: this.$refs.point.clientWidth * 0.8, Y: this.$refs.point.clientHeight * 0.55};
                            else if(this._now_team == 2)
                                ball_pos = { X: this.$refs.point.clientWidth * 0.2, Y: this.$refs.point.clientHeight * 0.55};
                        }else{
                            if(this._now_team == 1)
                                ball_pos = { X: this.$refs.point.clientWidth * 0.7, Y: this.$refs.point.clientHeight * 0.55};
                            else if(this._now_team == 2)
                                ball_pos = { X: this.$refs.point.clientWidth * 0.3, Y: this.$refs.point.clientHeight * 0.55};
                        }
                    }else if(this.data.sportID == 21){
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.7, Y: this.$refs.point.clientHeight * 0.55};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.3, Y: this.$refs.point.clientHeight * 0.55};
                    }
                }
                return ball_pos;
            },
            get_shot_result: function() {
                if(this._now_team)
                    return GetShotResult(this.data.sportID, this.stat);
            },
            get_shot_minusY: function() {
                if(this.data.sportID == 1){
                    return 10;
                }else if(this.data.sportID == 3){
                    return 20;
                }else if(this.data.sportID == 4){
                    return 20;
                }else if(this.data.sportID == 21){
                    return 5;
                }
            },
        },
        methods: {
            getTimer: function(){
                if(this.extData && this.extData.M && this.extData.S){
                    if(this.extData.P && this.extData.T && this.extData.P == '1'){
                        let durate = null;
                        if(this.extData.D && this.extData.D == '1')
                            this.timeCounter = moment.duration(((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000) - (moment().valueOf() - moment.tz(this.extData.T, 'YYYYMMDDHHmmss', 'Europe/London').valueOf()));
                        else
                            this.timeCounter = moment.duration(moment().valueOf() - moment.tz(this.extData.T, 'YYYYMMDDHHmmss', 'Europe/London').valueOf() + ((parseInt(this.extData.M) * 60 + parseInt(this.extData.S)) * 1000));
                    }else{
                        this.timeCounter = moment.duration(((parseInt(this.extData.M) * 60) + parseInt(this.extData.S)) * 1000);
                    }
                }
            },
            parserTimeStr: function(_number){
                if(_number < 10)
                    return `0${_number}`;
                return _number;
            },
            _get_team_code: function() {
                if(this.stat && this.stat.ST && this.stat.ST.length >= 5){
                    let team_code = this.stat.ST.substring(0, 1);
                    return team_code;
                }
                return '0';
            },
            _get_ball_pos: function() {
                if(this.stat && this.stat.PT){
                    const codi = this.stat.PT.split(',');
                    if(codi.length == 2){
                        return { X : this.$refs.point.clientWidth * codi[0], Y: this.$refs.point.clientHeight * codi[1] };
                    }
                }
                return null;
            },
            _get_kick_pos: function() {
                let ball_pos = this._get_ball_pos();
                if(!ball_pos){
                    if(this._is_kick == "c"){//코너킥
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.99, Y: this.$refs.point.clientHeight * 0.99};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.01, Y: this.$refs.point.clientHeight * 0.01};
                    }else if(this._is_kick  == "g"){//골킥
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.06, Y: this.$refs.point.clientHeight * 0.6};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.94, Y: this.$refs.point.clientHeight * 0.39};
                    }else if(this._is_kick  == "f"){//프리킥
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.4, Y: this.$refs.point.clientHeight * 0.45};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.6, Y: this.$refs.point.clientHeight * 0.55};
                    }else if(this._is_kick  == "t"){//스로인
                        if(this._now_team == 1)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.62, Y: this.$refs.point.clientHeight * 0.01};
                        else if(this._now_team == 2)
                            ball_pos = { X: this.$refs.point.clientWidth * 0.38, Y: this.$refs.point.clientHeight * 0.99};
                    }
                }
                return ball_pos;
            },
            line_distance: function(point1, point2){
                var xs = 0;
                var ys = 0;

                xs = point2.X - point1.X;
                xs = xs * xs;

                ys = point2.Y - point1.Y;
                ys = ys * ys;

                return Math.sqrt( xs + ys );
            },
            line_angle: function(point1, point2){
                var dx = point2.X - point1.X;
                var dy = point2.Y - point1.Y;
                return Math.atan2(dy, dx) * 180 / Math.PI;
            },
            inplayLeave: function(_game){
                this.stats = [];
                this.stat = null;
                this.ball_his = { team: '0', ball: null, balls: [] };
            },
            PushStat: function(_stats){
                if(_stats.ST.includes('^')){
                    let _pts = _stats.PT.split('^');
                    let _sts = _stats.ST.split('^');
                    let _s2s = _stats.S2.split('^');
                    for(let i = 0; i < _sts.length; i++){
                        this.stats.push({PT : _pts[i] ? _pts[i] : '', ST : _sts[i] ? _sts[i] : '', S2 : _s2s[i] ? _s2s[i] : '', });
                    }
                }else{
                    this.stats.push(_stats);
                }
            },
            timerStat: function(_stats){
                if(this.stat_wait <= 0){
                    let getStat = this.stats.shift();
                    if(getStat){
                        this.stat_wait = GetStatWait(this.data.sportID, getStat);
                        this.stat = getStat;
                        this.updateBallHis();
                    }
                }
                this.stat_wait--;
            },
            updateBallHis: function(){
                let _team_code = this._get_team_code();
                if(_team_code != '0' && CheckAddHistory(this.data.sportID, this.stat)){
                    let cur_ball_pos = this._get_ball_pos();
                    if(cur_ball_pos){
                        if(this.ball_his.team == _team_code){
                            this.ani_num = !this.ani_num;
                            this.ball_his.balls.push({ b1: this.ball_his.ball, b2: cur_ball_pos});
                            this.ball_his.ball = cur_ball_pos;
                        }else{
                            this.ball_his.team = _team_code;
                            this.ball_his.ball = cur_ball_pos;
                            this.ball_his.balls = [];
                        }
                    }
                }
                else
                    this.ball_his = { team: '0', ball: null, balls: [] };
            },
        },
        watch: {
        },
        created: function () {
        },
        mounted: function(){
            this.timer = setInterval(() => this.getTimer(), 1000);
            this.timer_stat = setInterval(() => this.timerStat(), 100);
        },
    }
</script>
