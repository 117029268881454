<template>
    <div>
        <MobileBottomMenu></MobileBottomMenu>
        <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
            <notification></notification>
            <sidebar-mobile-menu></sidebar-mobile-menu>
            <div class="navbar-brand-wrap">
                <a class="navbar-brand" href="/">
                    <img src="/img/logo.png" :alt="appName" >
                </a>
            </div>
            <top-menu></top-menu>
            <sidebar-mobile-cart></sidebar-mobile-cart>
        </nav>
    </div>
</template>

<script>
    import MobileBottomMenu from './MobileBottomMenu.vue'

    export default {
        data: function () {
            return {
                loginClickCount : 0, appName,
            }
        },
        computed: {
        },
        watch:{
        },
        mounted: function(){

            $(document).on("click", '.bet, a', this.loginControler);
        },
        methods: {
            loginControler: function(){

                // this.loginClickCount++;

                // if(this.loginClickCount >= 5){
                //     this.loginClickCount = 0;
                //     Swal.fire({icon: 'info', title: `로그인 후 이용가능합니다.`})
                // }


                // event.preventDefault();
            }
        },
        created () {
        },
        beforeDestroy () {
        },
        components: {
            MobileBottomMenu
        },
    }
</script>
