<template>
    <div class="">
        <div class="box-header"><i class="fas fa-user"></i> 추천인 내역</div>
        <div class="box-content box-main-content">
            <ul>
                <li>총 추천인 수</li>
                <li><p>{{ userdata.subData.meta.total }}명</p></li>
                <li>정상회원</li>
                <li><p>{{ userdata.rCount }}명</p></li>
                <li>추천 낙첨 포인트</li>
                <li>
                    <p>{{ _rLoseRate }}%</p>
                </li>
            </ul>
        </div>

        <div class="userinfo-sub recommend-history">
            <ul class="title">
                <li>가입일시</li>
                <li>닉네임</li>
                <li>상태</li>
            </ul>
            <ul v-for="(data, index) in userdata.subData.data" :key="index">
                <li>{{ _showTime(data.createdAt) }}</li>
                <li>{{ data.nickName }}</li>
                <li>{{ _showStatus(data.state) }}</li>
            </ul>
            <div class="empty" v-show="userdata.subData.length == 0">
                포인트 내역이 없습니다.
            </div>
            <pagination :pagination="userdata.subData.meta" @paginate="_exec_get()"></pagination>
        </div>
    </div>
</template>

<script>


    export default {

        data: function () {
            return {
                userdata: data,
            }
        },
        computed: {
            _rLoseRate: function(){
                if(Number(this.userdata.rCount) > 10)
                    this.userdata.rCount = 10;
                return Number(this.userdata.recommendLoseRate) * Number(this.userdata.rCount);
            },
        },
        methods: {
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _showStatus: function (_state) {
                if(_state == 0)
                    return '대기회원';
                else if(_state == 1)
                    return '정상회원';
                else if(_state == 2)
                    return '정지회원';
                else if(_state == 3)
                    return '삭제회원';

                return '-';
            },
            _exec_get: function() {
                showLoading();
                    axios.get(`${location.pathname}?page=` + this.userdata.subData.meta.current_page)
                .then((response)=>{

                    if(response.data.meta){
                        this.userdata.subData = response.data;
                    }

                    hideLoading();
                })
            },
        },
        watch:{
        },
    }
</script>
