<template>
<div class="">
    <!-- <div class="box-header">
        <span class="header_title">
            <i class="fas fa-chess-queen"></i> 카지노
        </span>
    </div> -->

    <div class="minigame-menu">
        <div class="minigame-main-menu">
            <ul>
                <li :class="{active: _mainMenu == 'casino'}">
                    <a href="/casino/home"><img src="/img/icon/sport/casino.png" width="19" /> 카지노</a>
                </li>
                <li :class="{active: _mainMenu == 'slot'}">
                    <a href="/slot/home"><img src="/img/icon/sport/slot.png" width="17" /> 슬롯</a>
                </li>
            </ul>
        </div>
    </div>


    <div class="casino-lists">
        <div v-for="(_list, key) in casinoLists" :key="key" @click="_getLink(key)">
            <span v-if="_list.isExchange" class="extra-info">머니 전환형</span>
            <img :src="_list.img">
            <div class="entering">입장하기</div>
        </div>
    </div>
</div>
</template>

<style>

</style>

<script>

    export default {
        data: function () {
            return {
                userData : userData,
                casinoLists : CasinoMenus,
            }
        },
        components: {
        },
        computed: {
            _mainMenu: function(){
                return location.pathname.split('/')[1];
            },
        },
        methods:{
            _getLink: function(_id){
                CasinoGetLink(_id)
            },
            // _getLink: async function(_id){
            //     if(_id == 'SABONG' || _id == 'VOTA'){
            //         let _get_balance = await this._getCasinoCash(_id);
            //         if(_get_balance != undefined){
            //             let _title = "";
            //             if(_id == 'SABONG')
            //                 _title = "사봉";
            //             else if(_id == 'VOTA')
            //                 _title = "보타";
            //             Swal.fire({
            //                 title: '<i class="fas fa-exchange-alt"></i> ' +  _title + ' 머니전환',
            //                 confirmButtonText: '<i class="fas fa-exchange-alt"></i> 전환',
            //                 cancelButtonText: '<i class="fas fa-times"></i> 취소',
            //                 denyButtonText: '<i class="fas fa-play"></i> 게임시작',
            //                 showCancelButton: true,
            //                 showDenyButton: true,
            //                 html:
            //                     '<div class="radios">' +
            //                     '현재 ' + _title + '머니 : ' + numberWithComma(_get_balance) + '원' +
            //                     '</div>' +
            //                     '<div class="radios">' +
            //                     '   <label><input type="radio" class="swal2-radio1" id="swal-radio1" name="swal-radio" checked> 사이트 <i class="fas fa-arrow-right"></i> ' + _title + '머니</label>' +
            //                     '   <label><input type="radio" class="swal2-radio2" id="swal-radio2" name="swal-radio"> ' + _title + '머니 <i class="fas fa-arrow-right"></i> 사이트</label>' +
            //                     '</div>' +
            //                     '<div class="rangs">' +
            //                     '   <output id="range_val">전환머니 ' + numberWithComma(parseInt(userData.cash / 100) * 100) + '원</output>' +
            //                     '   <input id="swal-input2" type="range" class="swal2-input" name="swal-range" max="' + userData.cash + '" value="' + userData.cash + '" oninput="range_val.value = `전환머니 ` + numberWithComma(this.value)+ `원`">' +
            //                     '</div>',
            //                 focusConfirm: false,
            //                 customClass: {
            //                     container: 'casino-transition',
            //                     icon: 'no-border'
            //                 },
            //                 willOpen: () => {
            //                     const inputRadio1 = Swal.getHtmlContainer().querySelector('#swal-radio1')
            //                     const inputRadio2 = Swal.getHtmlContainer().querySelector('#swal-radio2')
            //                     const inputRange = Swal.getHtmlContainer().querySelector('#swal-input2')
            //                     const range_val = Swal.getHtmlContainer().querySelector('#range_val')

            //                     inputRadio1.addEventListener('change', () => {
            //                         if(inputRadio1.checked){
            //                             inputRange.max = userData.cash
            //                             inputRange.value = userData.cash
            //                             range_val.value = '전환머니 ' + numberWithComma(parseInt(userData.cash / 100) * 100) + '원'
            //                         }
            //                     })
            //                     inputRadio2.addEventListener('change', () => {
            //                         if(inputRadio2.checked){
            //                             inputRange.max = _get_balance
            //                             inputRange.value = _get_balance
            //                             range_val.value =  '전환머니 ' + numberWithComma(parseInt(_get_balance / 100) * 100) + '원'
            //                         }
            //                     })
            //                 },
            //                 preConfirm: () => {
            //                     return [
            //                         document.getElementById('swal-radio1').checked ? 1 : 2,
            //                         document.getElementById('swal-input2').value
            //                     ]
            //                 }
            //             }).then((result) => {
            //                 if (result.isConfirmed) {
            //                     this._exchangeCash(_id, { kind : result.value[0], cash: result.value[1] });
            //                 } else if (result.isDenied) {
            //                     this._getJoinLink(_id, { kind : result.value[0], cash: result.value[1] });
            //                 }
            //             })
            //         }
            //     }
            //     else{
            //         this._getJoinLink(_id);
            //     }
            // },
            // _getJoinLink: function(_id, _extra = null){
            //     showLoading();
            //     var winRef = window.open("","_blank");
            //     axios.post(`/casino/join/${_id}`, _extra)
            //     .then((response)=>{
            //         if(response.data.result == 0){
            //             winRef.close()
            //             Swal.fire({icon: 'error', title: response.data.msg})
            //         }
            //         else if(response.data.result == 1){
            //             if(response.data.link)
            //                 winRef.location = response.data.link;
            //         }
            //     })
            //     .finally(() => {
            //         hideLoading();
            //     });
            // },
            // _exchangeCash: function(_id, _extra = null){
            //     showLoading();
            //     axios.post(`/casino/exchange/${_id}`, _extra)
            //     .then((response)=>{
            //         if(response.data.result == 0){
            //             Swal.fire({icon: 'error', title: response.data.msg})
            //         }
            //         else if(response.data.result == 1){
            //             Swal.fire({icon: 'success', title: response.data.msg, timer: 1500})
            //             .then(() => {
            //                 if(_extra.kind == 1)
            //                     this._getLink(_id);
            //             })
            //         }
            //     })
            //     .finally(() => {
            //         hideLoading();
            //     });
            // },
            // _getCasinoCash: async function(_id){
            //     showLoading();
            //     let _ret = null
            //     await axios.get(`/casino/balance/${_id}`)
            //     .then((response)=>{
            //         if(response.data.result == 0){
            //             Swal.fire({icon: 'error', title: response.data.msg})
            //         }else if(response.data.result == 1){
            //             _ret = response;
            //         }
            //     })
            //     .finally(() => {
            //         hideLoading();
            //     });
            //     if(_ret.status == 200 && _ret.data.result == 1)
            //         return _ret.data.balance;
            // },
        },
        watch: {
        },
        components: {
        },
    }
</script>
