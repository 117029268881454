<template>
    <div class="">
        <div class="box-header"><i class="fas fa-user"></i> 마이페이지</div>
        <div class="box-content box-main-content">
            <ul>
                <li>아이디</li>
                <li><p>{{ userdata.userID }}</p></li>
                <li>닉네임</li>
                <li><p>{{ userdata.nickName }}</p></li>
                <li>계좌정보</li>
                <li>
                    <p>고객센터로 계좌정보 문의 바랍니다.</p>
                </li>
                <li>휴대폰 번호</li>
                <li>
                    <p>***-****-**** </p>
                </li>
                <li>해외형 최대 베팅</li>
                <li>
                    <p>{{ _showCash(userdata.overseasMax) }} </p>
                </li>
                <li>해외형 최대당첨</li>
                <li>
                    <p>{{ _showCash(userdata.overseasWin) }} </p>
                </li>
                <li>국내형 최대 베팅</li>
                <li>
                    <p>{{ _showCash(userdata.crossMax) }} </p>
                </li>
                <li>국내형 최대 당첨</li>
                <li>
                    <p>{{ _showCash(userdata.crossWin) }} </p>
                </li>
                <li>낙첨 포인트</li>
                <li>
                    <p>{{ userdata.loseRate }}%</p>
                </li>
            </ul>
        </div>

        <div class="center-btn-wrap">
            <button class="blue" type="button" @click="_subViewClick(1)"><i class="far fa-file-powerpoint"></i> 포인트 내역</button>
            <button class="blue" type="button" @click="_subViewClick(2)"><i class="fas fa-sign-in-alt"></i> 로그인 기록</button>
            <button class="red" type="button" @click="_subViewClick(3)"><i class="fas fa-unlock-alt"></i> 비밀번호 변경</button>
        </div>

        <div v-if="subView == 1" class="userinfo-sub point-history">
            <ul class="title">
                <li>일시</li>
                <li>내용</li>
                <li>지급포인트</li>
            </ul>
            <ul v-for="(data, index) in subData.data" :key="index">
                <li>{{ _showTime(data.createdAt) }}</li>
                <li>{{ _showPointKind(data.reasonKind) }} {{ data.reasonText  }}</li>
                <li :class="{'red-color' : data.point < 0}">{{ data.point }}</li>
            </ul>
            <div class="empty" v-show="subData.length == 0">
                포인트 내역이 없습니다.
            </div>
            <pagination :pagination="subData.meta" @paginate="_subViewClick(1)"></pagination>
        </div>
        <div v-if="subView == 2" class="userinfo-sub login-history">
            <ul class="title">
                <li>날짜</li>
                <li>상태</li>
                <li>아이피</li>
                <li>운영체제</li>
                <li>브라우저</li>
            </ul>
            <ul v-for="(data, index) in subData.data" :key="index">
                <li>{{ _showTime(data.createdAt) }}</li>
                <li :class="{'red-color' : data.success == 0}">{{ _showSuccess(data.success) }}</li>
                <li>{{ data.ip }}</li>
                <li>{{ data.os }}</li>
                <li>{{ data.browser }}</li>
            </ul>
            <div class="empty" v-show="subData.length == 0">
                로그인 내역이 없습니다.
            </div>
            <pagination :pagination="subData.meta" @paginate="_subViewClick(2)"></pagination>
        </div>
        <div v-if="subView == 3">
            <div class="box-header"><i class="fas fa-unlock-alt"></i> 비밀번호 재설정</div>
            <div class="box-content box-main-content">
                <ul>
                    <li>현재 비밀번호</li>
                    <li><input type="password" v-model="form_pwd.pwd" placeholder="현재 비밀번호를 입력하세요"
                        v-tooltip="{content: form_pwd.errors.get('pwd'), show: form_pwd.errors.has('pwd'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_pwd.errors.clear('pwd')" :class="{'is-invalid': form_pwd.errors.has('pwd')}">
                    </li>
                    <li>변경할 비밀번호</li>
                    <li><input type="password" v-model="form_pwd.pwdNew" placeholder="변경할 비밀번호를 입력하세요"
                        v-tooltip="{content: form_pwd.errors.get('pwdNew'), show: form_pwd.errors.has('pwdNew'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_pwd.errors.clear('pwdNew')" :class="{'is-invalid': form_pwd.errors.has('pwdNew')}">
                    </li>
                    <li>비밀번호 재입력</li>
                    <li><input type="password" v-model="form_pwd.pwdNew_confirmation" placeholder="변경할 비밀번호를 재입력하세요"
                        v-tooltip="{content: form_pwd.errors.get('pwdNew_confirmation'), show: form_pwd.errors.has('pwdNew_confirmation'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_pwd.errors.clear('pwdNew_confirmation')" :class="{'is-invalid': form_pwd.errors.has('pwdNew_confirmation')}">
                    </li>
                </ul>
            </div>
            <div class="center-btn-wrap">
                <button class="blue pwd-change-btn" type="button" @click="_execPwd"><i class="far fa-check-circle"></i> 비밀번호 변경</button>
            </div>
            <div class="box-header"><i class="fas fa-unlock-alt"></i> 환전 비밀번호 재설정</div>
            <div class="box-content box-main-content">
                <ul>
                    <li>현재 비밀번호</li>
                    <li><input type="password" v-model="form_account_pwd.pwd" placeholder="현재 환전 비밀번호를 입력하세요"
                        v-tooltip="{content: form_account_pwd.errors.get('pwd'), show: form_account_pwd.errors.has('pwd'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_account_pwd.errors.clear('pwd')" :class="{'is-invalid': form_account_pwd.errors.has('pwd')}">
                    </li>
                    <li>변경할 비밀번호</li>
                    <li><input type="password" v-model="form_account_pwd.pwdNew" placeholder="변경할 환전 비밀번호를 입력하세요"
                        v-tooltip="{content: form_account_pwd.errors.get('pwdNew'), show: form_account_pwd.errors.has('pwdNew'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_account_pwd.errors.clear('pwdNew')" :class="{'is-invalid': form_account_pwd.errors.has('pwdNew')}">
                    </li>
                    <li>비밀번호 재입력</li>
                    <li><input type="password" v-model="form_account_pwd.pwdNew_confirmation" placeholder="변경할 환전 비밀번호를 재입력하세요"
                        v-tooltip="{content: form_account_pwd.errors.get('pwdNew_confirmation'), show: form_account_pwd.errors.has('pwdNew_confirmation'), trigger: 'manual', placement: 'top', offset: -3}"
                        @focus="form_account_pwd.errors.clear('pwdNew_confirmation')" :class="{'is-invalid': form_account_pwd.errors.has('pwdNew_confirmation')}">
                    </li>
                </ul>
            </div>
            <div class="center-btn-wrap">
                <button class="blue pwd-change-btn" type="button" @click="_execAccountPwd"><i class="far fa-check-circle"></i> 출금 비밀번호 변경</button>
            </div>
        </div>
    </div>
</template>

<script>


    export default {

        data: function () {
            return {
                userdata: data, subView: 0, subData: [],
                form_pwd: new Form({
                    pwd: '',
                    pwdNew: '',
                    pwdNew_confirmation: '',
                }),
                form_account_pwd: new Form({
                    pwd: '',
                    pwdNew: '',
                    pwdNew_confirmation: '',
                }),
            }
        },
        computed: {
        },
        methods: {
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _subViewClick: function(_viewNum){

                if(_viewNum == 1){

                    axios.get('/userinfo/pointHistory?page=' + (this.subData.meta == null ? 0 : this.subData.meta.current_page))
                    .then((response)=>{
                        this.subData = response.data;
                        this.subView = _viewNum;
                    })
                }
                else if(_viewNum == 2){
                    axios.get('/userinfo/loginHistory?page=' + (this.subData.meta == null ? 0 : this.subData.meta.current_page))
                    .then((response)=>{
                        this.subData = response.data;
                        this.subView = _viewNum;
                    })
                }
                else if(_viewNum == 3){
                    this.subData = [];
                    this.subView = _viewNum;
                }
            },
            _showTime: function (_time) {
                return datetimeToYMDHM(_time);
            },
            _showSuccess: function (_success) {
                if(_success == 0)
                    return '로그인 실패';
                else if(_success == 1)
                    return '로그인 성공';
            },
            _execPwd: function(){
                if(this.form_pwd.pwd == null || this.form_pwd.pwd.length < 4)
                    this.form_pwd.errors.set('pwd', "비밀번호를 4자리 이상 입력해 주세요.");
                else if(this.form_pwd.pwdNew == null || this.form_pwd.pwdNew.length < 4)
                    this.form_pwd.errors.set('pwdNew', "변경할 비밀번호를 4자리 이상 입력해 주세요.");
                else if(this.form_pwd.pwdNew != this.form_pwd.pwdNew_confirmation)
                    this.form_pwd.errors.set('pwdNew', "변경할 비밀번호와 재입력한 비밀번호가 일치하지 않습니다.");
                else if(this.form_pwd.pwd == this.form_pwd.pwdNew)
                    this.form_pwd.errors.set('pwd', "현재 비밀번호와 변경할 비밀번호를 같은 값으로 입력했습니다.");
                else{
                    showLoading();
                    this.form_pwd.post('/userinfo/password')
                    .then((response)=>{
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.result == 1){
                            Swal.fire({icon: 'success', title: '비밀번호가 변경되었습니다.'});
                        }
                    })
                    .finally(function () {
                        hideLoading();
                    });
                }
            },
            _execAccountPwd: function(){
                if(this.form_account_pwd.pwd == null || this.form_account_pwd.pwd.length < 4)
                    this.form_account_pwd.errors.set('pwd', "비밀번호를 4자리 이상 입력해 주세요.");
                else if(this.form_account_pwd.pwdNew == null || this.form_account_pwd.pwdNew.length < 4)
                    this.form_account_pwd.errors.set('pwdNew', "변경할 비밀번호를 4자리 이상 입력해 주세요.");
                else if(this.form_account_pwd.pwdNew != this.form_account_pwd.pwdNew_confirmation)
                    this.form_account_pwd.errors.set('pwdNew', "변경할 비밀번호와 재입력한 비밀번호가 일치하지 않습니다.");
                else if(this.form_account_pwd.pwd == this.form_account_pwd.pwdNew)
                    this.form_account_pwd.errors.set('pwd', "현재 비밀번호와 변경할 비밀번호를 같은 값으로 입력했습니다.");
                else{
                    showLoading();
                    this.form_account_pwd.post('/userinfo/accountPassword')
                    .then((response)=>{
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.result == 1){
                            Swal.fire({icon: 'success', title: '출금 비밀번호가 변경되었습니다.'});
                        }
                    })
                    .finally(function () {
                        hideLoading();
                    });
                }
            },
            _showPointKind: function(_kind){
                if(_kind == 11)
                    return '낙첨 포인트';
                else if(_kind == 12)
                    return '추천인 낙첨 포인트';
                else if(_kind == 13)
                    return '낙첨 포인트 회수';
                else if(_kind == 14)
                    return '추천인 낙첨 포인트 회수';
                else if(_kind == 21)
                    return '출석 포인트';
                else if(_kind == 31)
                    return '캐시 전환';
                else if(_kind == 51)
                    return '관리자 지급';
                else if(_kind == 52)
                    return '관리자 회수';
            }
        },
        watch:{
        },
    }
</script>
