<template>
    <div class="casino-bet-lists">
        <div class="box-header">
            <i class="fas fa-list"></i> {{ _title }} 배팅내역
            <span @click="_exec_get" class="refresh">
                <i class="fas fa-sync-alt"></i>
                새로고침
            </span>
        </div>

        <div>
            <div class="casino-bet-list title">
                <ul>
                    <li>배팅일자</li>
                    <li>게임번호</li>
                    <li>배팅</li>
                    <li>배팅금액</li>
                    <li>결과</li>
                </ul>
            </div>
            <div v-for="(data, index) in betLists.data" :key="index" class="casino-bet-list">
                <ul>
                    <li>{{ _showTime(data.createdAt) }}</li>
                    <li>{{ data.displayID }}</li>
                    <li>{{ _showBet(data.bet) }} <span class="odds">({{ data.odds }})</span></li>
                    <li>{{ _showCash(data.betCash) }}</li>
                    <li :class="_resultToClass(data.result)">{{ _showResult(data.result) }}</li>
                </ul>
            </div>
            <div class="empty" v-if="!isListLoaded">
                로딩중...
            </div>
            <div class="empty" v-else-if="betLists.data.length == 0">
                배팅내역이 없습니다.
            </div>
        </div>

        <pagination :pagination="this.betLists.meta" @paginate="_exec_get()"></pagination>
    </div>
</template>

<script>
    export default {

        data: function () {
            return {
                betLists: { data: [], meta: {current_page: 1}},
                isListLoaded : false,
            }
        },
        computed: {
            _title: function(){
                return getSparkCasinoName(this._path2, '');
            },
            _path2: function(){
                return location.pathname.split('/')[2];
            },
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToMDHM(_time);
            },
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _showBet: function(_bet){
                if(this._path2 == 'baccarat' || this._path2 == 'baccarat2' || this._path2 == 'baccarat_speed'){
                    if(_bet == 1)
                        return '플레이어';
                    else if(_bet == 2)
                        return '뱅커';
                    else if(_bet == 3)
                        return '타이';
                    else if(_bet == 4)
                        return 'P. 페어';
                    else if(_bet == 5)
                        return 'B. 페어';
                }else if(this._path2 == 'dragontiger'){
                    if(_bet == 1)
                        return '드래곤';
                    else if(_bet == 2)
                        return '타이거';
                    else if(_bet == 3)
                        return '타이';
                }else if(this._path2 == 'dice'){
                    if(_bet == 1)
                        return '홀';
                    else if(_bet == 2)
                        return '짝';
                    else if(_bet == 3)
                        return '오버';
                    else if(_bet == 4)
                        return '세븐';
                    else if(_bet == 5)
                        return '언더';
                    else if(_bet == 6)
                        return '레드';
                    else if(_bet == 7)
                        return '타이';
                    else if(_bet == 8)
                        return '블루';
                }else if(this._path2 == 'samchigi'){
                    if(_bet == 1)
                        return '1승';
                    else if(_bet == 2)
                        return '2승';
                    else if(_bet == 3)
                        return '3승';
                    else if(_bet == 4)
                        return '1승2무';
                    else if(_bet == 5)
                        return '1승3무';
                    else if(_bet == 6)
                        return '2승1무';
                    else if(_bet == 7)
                        return '2승3무';
                    else if(_bet == 8)
                        return '3승1무';
                    else if(_bet == 9)
                        return '3승2무';
                    else if(_bet == 10)
                        return '1승2승';
                    else if(_bet == 11)
                        return '2승3승';
                    else if(_bet == 12)
                        return '3승1승';
                }
            },
            _set_bet_class: function(_bet){
                if(this._path2 == 'baccarat' || this._path2 == 'baccarat2' || this._path2 == 'baccarat_speed' || this._path2 == 'dragontiger'){
                    if(_bet == 1 || _bet == 4)
                        return 'bet-blue';
                    else if(_bet == 2 || _bet == 5)
                        return 'bet-red';
                    else if(_bet == 3)
                        return 'bet-green';
                }else if(this._path2 == 'dice'){
                    if(_bet == 1 || _bet == 5 || _bet == 8)
                        return 'bet-blue';
                    else if(_bet == 2 || _bet == 3 || _bet == 6)
                        return 'bet-red';
                    else if(_bet == 4 || _bet == 7)
                        return 'bet-green';
                }else if(this._path2 == 'samchigi'){
                    if(_bet == 1 || _bet == 4 || _bet == 5 || _bet == 10)
                        return 'bet-blue';
                    else if(_bet == 3 || _bet == 8 || _bet == 9 || _bet == 12)
                        return 'bet-red';
                    else if(_bet == 2 || _bet == 6 || _bet == 7 || _bet == 11)
                        return 'bet-green';
                }
            },
            _showResult: function(_result){
                if(_result == 1)
                    return '당첨';
                else if(_result == 2)
                    return '미당첨';
                else if(_result == 4)
                    return '무효';
                else if(_result == null)
                    return '대기중';
            },
            _resultToClass: function(_result){
                if(_result == null)
                    return "wait";
                else if(_result == 1){
                    return "hit";
                }else if(_result == 2){
                    return "nohit";
                }else if(_result == 4){
                    return "cancle";
                }else{
                    return "";
                }
            },
            _exec_get: function(isLoading = true) {
                if(isLoading)
                    showLoading();
                axios.get(`${location.pathname}/betlist?page=` + this.betLists.meta.current_page)
                .then((response)=>{
                    //console.log(response);
                    this.betLists = response.data;
                    userData.cash = parseInt(response.data.cash);
                })
                .finally(() => {
                    hideLoading();
                    this.isListLoaded = true;
                });
            },
        },
        created: function(){
            this._exec_get(false);
        }
    }
</script>
