<template>
    <div class="foot_img">
        <img src="/img/home/foot_banner_1.png">
        <!-- <ul class="foot_site clearfix">
            <li v-for="i in 28" :key="i"></li>
        </ul> -->
    </div>

</template>
<script>
export default {

}
</script>
