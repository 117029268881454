/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('../../Common/js/common');
require('../../Common/js/naming');
require('../../Common/js/inplay');
require('../../Common/js/casinoHelper');
require('./etc');

import Vue from 'vue'

window.moment = require('moment-timezone');

import { Form, HasError, AlertError } from 'vform'
window.Form = Form;
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import Toast from "vue-toastification";
Vue.use(Toast);

import Swal from 'sweetalert2/src/sweetalert2.js'
window.Swal = Swal

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

import VueCurrencyInput from 'vue-currency-input'
const pluginOptions = {
    /* see config reference */
    globalOptions: { currency: {suffix: ' 원'}, distractionFree: false, precision : 0, autoDecimalMode: true, allowNegative: false, locale: 'ko-KR' },
    componentName: 'MoneyInput'
}
Vue.use(VueCurrencyInput, pluginOptions)
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.component('home', require('./components/Home.vue').default);
Vue.component('footer-img', require('./components/Common/footer.vue').default);
Vue.component('login', require('./components/Login.vue').default);
Vue.component('top-menu', require('./components/Common/TopMenu.vue').default);
Vue.component('app-wrap', require('./components/Common/AppWrap.vue').default);
Vue.component('mobile-top-menu', require('./components/Common/MobileTopMenu.vue').default);

Vue.component('sidebar-menu', require('./components/Sidebar/LeftMenu.vue').default);
Vue.component('sidebar-userinfo', require('./components/Sidebar/UserInfo.vue').default);
Vue.component('sidebar-mobile-menu', require('./components/Sidebar/MobileMenu.vue').default);

Vue.component('cart', require('./components/Cart/Lists.vue').default);
Vue.component('sidebar-mobile-cart', require('./components/Cart/Mobile.vue').default);

Vue.component('gamelists', require('./components/GameList/Overseas/Lists.vue').default);
Vue.component('gamelists-cross', require('./components/GameList/Cross/Lists.vue').default);
Vue.component('gamelists-inplay', require('./components/GameList/Inplay/Lists.vue').default);
Vue.component('bettinglists', require('./components/Betting/Lists.vue').default);
Vue.component('deposit', require('./components/User/Deposit.vue').default);
Vue.component('withdraw', require('./components/User/Withdraw.vue').default);
Vue.component('withdraw-msg', require('./components/User/WithdrawMsg.vue').default);
Vue.component('userinfo', require('./components/User/Userinfo.vue').default);
Vue.component('inquery', require('./components/User/Inquery.vue').default);
Vue.component('inquery-write', require('./components/User/InqueryWrite.vue').default);
Vue.component('attendance', require('./components/User/Attendance.vue').default);
Vue.component('recommend', require('./components/User/Recommend.vue').default);

Vue.component('minigame', require('./components/MiniGame/MiniGame.vue').default);
Vue.component('casino', require('./components/Casino/Casino.vue').default);
Vue.component('slot-casino', require('./components/Casino/Slot.vue').default);
Vue.component('skypark-casino', require('./components/SkyparkCasino/Casino.vue').default);
Vue.component('skypark-casino-view', require('./components/SkyparkCasino/CasinoView.vue').default);

Vue.component('board-lists', require('./components/BoardLists.vue').default);

Vue.component('pagination', require('../../Common/js/Pagination.vue').default);

Vue.component('notification', require('./components/Notification/Notice.vue').default);

Vue.component('Inplay', require('../../Common/js/GameList/Inplay/Inplay.vue').default);
Vue.component('GameBetList', require('../../Common/js/GameList/BetList.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.prototype.$telegram = 'https://t.me/Gm_MR';

const app = new Vue({
    el: '#app',
});
