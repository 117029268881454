<template>
    <div>
        <div class="logo">
            <img src="/img/main-logo.png">
        </div>
        <transition name="login-fade">
            <div v-show="visible" class="login-form">
                <h3>Member Login</h3>
                <div class="form-control">
                    <span><i class="fas fa-user-circle"></i></span>
                    <input v-model="form.userid" type="text" placeholder="ID" :class="{'is-invalid': form.errors.has('userid')}" @focus="form.errors.clear('userid')">
                    <has-error :form="form" field="userid"></has-error>
                </div>
                <div class="form-control">
                    <span><i class="fas fa-lock"></i></span>
                    <input v-model="form.password"  v-on:keyup.enter="_exec_login" type="password" placeholder="Password" :class="{'is-invalid': form.errors.has('password')}" @focus="form.errors.clear('password')">
                    <has-error :form="form" field="password"></has-error>
                </div>
                <div class="inquery">
                    <a :href="$telegram" target="_blank"><i class="fab fa-telegram-plane"></i>텔레그램 문의하기</a>
                </div>
                <div class="btns">
                    <button class="login" @click="_exec_login">로그인</button>
                </div>
                <div class="register_wrap">
                    <button class="register" @click="_register">회원가입</button>
                </div>
                <div class="capy">Copyright @Meritz.All Right Reserved.</div>
            </div>
        </transition>
        <transition name="login-fade">
            <Register v-if="register" v-on:registerBack="_registerBack">

            </Register>
        </transition>
    </div>
</template>

<style>
.login-fade-enter-active {
    transition: all .5s ease-out;
}
.login-fade-enter {
    transform: translateY(-50px);
    opacity: 0;
}
</style>

<script>
    import Register from './Register';

    export default {
        data: function () {
            return {
                visible : false,
                register : false,
                form: new Form({
                    userid: '',
                    password : '',
                }),
            }
        },
        computed: {

        },
        watch:{

        },
        mounted: function(){
            this.visible = true;
        },
        methods: {
            _exec_login: function(){
                if(this.form.userid.length < 4)
                    this.form.errors.set('userid', '아이디는 최소 4자 이상이어야 합니다.');
                else if(this.form.userid.length > 30)
                    this.form.errors.set('userid', '아이디는 최소 30자 이하이어야 합니다.');
                else if(this.form.password.length < 4)
                    this.form.errors.set('password', '패스워드는 최소 4자 이상이어야 합니다.');
                else if(this.form.password.length > 30)
                    this.form.errors.set('password', '패스워드는 최소 30자 이하이어야 합니다.');
                else{
                    showLoading();

                    this.form.post(`login`)
                    .then((response)=>{
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.result == 1){
                            if(response.data.path)
                                window.location.href = response.data.path;
                            else
                                window.location.href = '/home';
                        }
                        else if(response.request.responseURL.includes('/home')){
                            window.location.href = response.request.responseURL;
                        }
                    })
                    .catch(function (error) {
                        if(error.response){
                            if(error.response.status == 419){
                                Swal.fire({icon: 'error', title: '잘못된 토큰입니다. 다시 시도해 주세요.'})
                                .then(()=>{
                                    location.reload();
                                });
                            }
                        }
                    }).finally(() => {
                        hideLoading();
                    });
                }
            },
            _register: function(){
                this.visible = false;
                this.register = true;
            },
            _registerBack: function(){
                this.visible = true;
                this.register = false;
            },
        },
        created () {

        },
        beforeDestroy () {

        },
        components: {
            Register
        },
    }
</script>
