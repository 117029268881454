<template>
    <div class="">
        <div class="box-header"><i class="fas fa-plus-square"></i> 충전신청</div>
        <div class="message_box">
            <p>
               <strong>※</strong>
                 당사의 충전 계좌는 회원님들의 보안을 위해 상시 변경될 수있습니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;송금 전 필히 충전 계좌 정보를 확인 후 송금해주시길 바라며,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;당사의 충전 계좌가 변경된 후 이전 계좌로 입금 시 충전 승인 처리가 불가합니다.<br>
                <strong>※</strong> 충전 신청이 가능한 <span class="message-strog">최소 금액은 10,000원</span>이며, 그 이상 1,000원 단위까지 충전 신청이 가능합니다.<br>
                <strong>※</strong> 송금하신 금액을 체크 박스로 선택하거나 직접 입력하여 신청하시면 됩니다.<br>
                <strong>※</strong> 수표 입금은 받지 않으며. 수표 입금 시 충전 승인 처리가 불가합니다.<br>
                <strong>※</strong>
                간편 송금 (토스, 카카오페이, 핀크 등) 을 통한 입금이나,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;당사에 등록된 계좌 이외에 다른 계좌로 입금 시 충전 승인 처리가불가하오니 이 점 유념하시길 당부드립니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;(부득이하게 본인의 다른 계좌로 입금을 할 수 밖에 없는 경우 고객센터에 문의 후 이용해 주시길 바랍니다.<br>

            </p>
        </div>
        <div class="box-content box-main-content">
            <ul>
                <li>현재보유머니</li>
                <li><p>{{ userCash.toLocaleString() }}</p></li>
                <li>입금계좌번호</li>
                <li>
                    <div>
                        <button type="button" class="blue account-check" @click="request_account"><i class="far fa-check-circle"></i>입금계좌확인</button>
                    </div>
                </li>
                <li>입금</li>
                <li><p>입금/충전은 본인명의로 가능합니다.</p></li>
                <li>충전금액</li>
                <li>
                    <input type="text" class="input-cash" id="deposit-cash" v-model="plusCash" name="deposit-cash" placeholder="충전 금액 입력" >
                    <button class="red cash-clear" type="button" @click="_plusCashClear"><i class="far fa-trash-alt"></i> 초기화</button>
                </li>
                <li class="fast-input">빠른입력</li>
                <li class="fast-input">
                    <button class="blue cash-plus" type="button" @click="_plusCash(10000)">+10,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(30000)">+30,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(50000)">+50,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(100000)">+100,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(500000)">+500,000</button>
                    <button class="blue cash-plus" type="button" @click="_plusCash(1000000)">+1,000,000</button>
                </li>
                <li v-if="!userdata.nbonus">충전보너스</li>
                <li v-if="!userdata.nbonus"><p><b>{{ _showBonus }}%</b> <span class="max-bonus" v-show="isBonus == 1">최대 {{ _cash(userdata.maxBonus) }}</span></p></li>
                <li class="rolling">롤링퍼센트</li>
                <li class="rolling">
                    <p>
                        해외형 <b :class="_showRollingOverseas == '100' ? 'yellow' : 'green'">{{ _showRollingOverseas }}%</b>
                        해외형단폴더 <b :class="_showRollingOverseas1 == '100' ? 'yellow' : 'green'">{{ _showRollingOverseas1 }}%</b>
                        미니게임 <b :class="_showRollingMinigame == '100' ? 'yellow' : 'green'">{{ _showRollingMinigame }}%</b>
                    </p>
                    <p>
                        국내형 <b :class="_showRollingCross == '100' ? 'yellow' : 'green'">{{ _showRollingCross }}%</b>
                        국내형단폴더 <b :class="_showRollingCross1 == '100' ? 'yellow' : 'green'">{{ _showRollingCross1 }}%</b>
                    </p>
                    <p>
                        스페셜1,2 <b :class="_showRollingSpecial == '100' ? 'yellow' : 'green'">{{ _showRollingSpecial }}%</b>
                        <template v-if="_showRollingCasino">
                            카지노 <b :class="_showRollingCasino == '100' ? 'yellow' : 'green'">{{ _showRollingCasino }}%</b>
                        </template>

                    </p>
                </li>
                <li v-if="!userdata.nbonus">충전보너스</li>
                <li v-if="!userdata.nbonus">
                    <div>
                        <label class="radio-wrap">적용
                            <input type="radio" v-model="isBonus" value="1">
                            <span class="checkmark blue"></span>
                        </label>
                        <label class="radio-wrap">미적용
                            <input type="radio" v-model="isBonus" value="0">
                            <span class="checkmark red"></span>
                        </label>
                    </div>
                </li>
            </ul>
        </div>

        <div class="center-btn-wrap">
            <button class="blue" type="button" @click="_execDeposit"><i class="far fa-check-circle"></i> 충전신청</button>
        </div>

        <div class="userinfo-sub deposit-history">
            <ul class="title">
                <li><input type="checkbox" v-model="selectAll" @click="_selectAll"></li>
                <li>신청일자</li>
                <li>충전보너스</li>
                <li>충전금액</li>
                <li>처리현황</li>
                <li>처리일시</li>
            </ul>
            <ul v-for="(data, index) in userdata.subData.data" :key="index">
                <li><input type="checkbox" :value="data.ID" v-model="selected"></li>
                <li>{{ _showTime(data.createdAt) }}</li>
                <li>{{ _showIsBonus(data.isBonus) }}</li>
                <li>{{ _showCash(data.cash) }}</li>
                <li :class="{'blue-color' : data.status == 3, 'red-color' : data.status == 2}">{{ _showStatus(data.status) }}</li>
                <li>{{ _showUpdatedTime(data) }}</li>
            </ul>
            <div class="empty" v-show="userdata.subData.data.length == 0">
                충전신청 내역이 없습니다.
            </div>

            <pagination :pagination="userdata.subData.meta" @paginate="_exec_get()"></pagination>
        </div>

        <div class="center-btn-wrap">
            <button class="blue" type="button" @click="_execDelete()"><i class="far fa-trash-alt"></i> 선택삭제</button>
            <button class="red" type="button" @click="_execDeleteAll()"><i class="fas fa-trash-alt"></i> 전체삭제</button>
        </div>
    </div>
</template>

<script>


    export default {

        data: function () {
            return {
                userdata: data, userCash: userData.cash, plusCash: '', isBonus: 1, selected: [], selectAll: false
            }
        },
        computed: {
            _showBonus: function () {
                if(this.isBonus == 1)
                    return this.userdata.bonusRate;
                else
                    return 0;
            },
            _showRollingOverseas: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.overseas.bonus;
                else
                    return DepositRolling.overseas.noBonus;
            },
            _showRollingOverseas1: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.overseas1.bonus;
                else
                    return DepositRolling.overseas1.noBonus;
            },
            _showRollingCross: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.cross.bonus;
                else
                    return DepositRolling.cross.noBonus;
            },
            _showRollingCross1: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.cross1.bonus;
                else
                    return DepositRolling.cross1.noBonus;
            },
            _showRollingSpecial: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.special.bonus;
                else
                    return DepositRolling.special.noBonus;
            },
            _showRollingSpecial1: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.special1.bonus;
                else
                    return DepositRolling.special1.noBonus;
            },
            _showRollingMinigame: function () {
                if(this.userdata.nbonus == 0 && this.isBonus == 1)
                    return DepositRolling.minigame.bonus;
                else
                    return DepositRolling.minigame.noBonus;
            },
            _showRollingCasino: function () {
                if(DepositRolling.casino){
                    if(this.userdata.nbonus == 0 && this.isBonus == 1)
                        return DepositRolling.casino.bonus;
                    else
                        return DepositRolling.casino.noBonus;
                }
            },
        },
        methods: {
            _cash: function (_cash) {
                return numberWithComma(_cash);
            },
            _plusCash: function(_cash){
                if(this.plusCash == '')
                    this.plusCash = 0;
                this.plusCash = (parseInt(this.plusCash.toString().replace(/[^0-9]/g, '')) + _cash);
            },
            _plusCashClear: function(){
                this.plusCash = '';
            },
            _exec_get: function() {
                showLoading();
                axios.get(`${location.pathname}?page=` + this.userdata.subData.meta.current_page)
                .then((response)=>{

                    if(response.data.meta){
                        this.userdata.subData = response.data;
                    }

                    hideLoading();
                })
            },
            _execDeposit: function(){

                var _plusCash = parseInt(this.plusCash.toString().replace(/[^0-9]/g, ''));

                if(!_plusCash || _plusCash < 5000)
                    Swal.fire({icon: 'info', title: '충전금액은 5천원 이상이어야 합니다.'});
                else if(_plusCash % 1000 != 0)
                    Swal.fire({icon: 'info', title: '천원단위로 입력해 주세요.'});
                else{
                    showLoading();
                    axios.post(`/deposit?page=${this.userdata.subData.meta.current_page}`, { plusCash: _plusCash, isBonus: this.isBonus })
                    .then((response)=>{
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.meta){
                            Swal.fire({icon: 'success', title: '충전신청이 완료 되었습니다.'});
                            this.userdata.subData = response.data;
                        }
                    })
                    hideLoading();
                }
            },
            _showTime: function (_time) {
                return datetimeToMDHM(_time);
            },
            _showUpdatedTime: function (_data) {
                if(_data && _data.status != 0)
                    return datetimeToMDHM(_data.updatedAt);
                else
                    return '';
            },
            _showIsBonus: function (_isBonus) {
                if(_isBonus == 0)
                    return '보너스 미적용';
                else
                    return '보너스 적용';
            },
            _showStatus: function (_status) {
                if(_status == 3)
                    return '충전완료';
                else if(_status == 2)
                    return '충전거절';
                else
                    return '대기중';
            },
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _selectAll: function() {
                this.selected = [];
                if (!this.selectAll) {
                    this.selected = _.map(this.userdata.subData.data, 'ID');
                }
            },
            _execDeleteAll: function() {
                this.selected = [];
                this.selected = _.map(this.userdata.subData.data, 'ID');
                this._execDelete();
            },
            _execDelete: function() {
                showLoading();
                axios.delete('/deposit', { data: {depositIDs: this.selected} })
                .then((response)=>{
                    if(response.data.result == 0){
                        Swal.fire({icon: 'error', title: response.data.msg})
                    }
                    else if(response.data.meta){
                        Swal.fire({icon: 'success', title: '삭제 되었습니다.'});
                        this.userdata.subData = response.data;
                    }
                })
                hideLoading();
            },
            request_account : function(){
                showLoading();
                axios.post(`/inquery/deposit`)
                .then((response)=>{
                    //console.log(response);
                    if(response.data.result == 0){
                        Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.'})
                    }
                    else if(response.data.result == 1){
                        Swal.fire({icon: 'success', title: '입금계좌 요청이 완료되었습니다.\n 쪽지함을 확인하세요.'});
                    }
                })
                .finally(function () {
                    hideLoading();
                });

            },

        },
        watch:{
            plusCash(){
                return this.plusCash = this.plusCash.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
    }
</script>
