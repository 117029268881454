<template>

    <div v-if="data.games.length > 0" class="gamelist" :class="{'is-waiting' : data.isWaiting}">

        <div v-show="data.leagueName.name !== prevLeagueName || data.startTime !== prevStartTime" class="cross_league">
            <span class="crossLeg_name">
                <img width="12" :src="`/img/icon/sport/${data.sportID}.png`" />{{ _showSportName(data.sportID) }}
                <i class="fas fa-angle-double-right"></i>
                <div class="flag">
                    <img class="leg_img" width="18" :src="imageLeagueUrl" />
                    <div  class="flag-wave"></div>
                </div>
                {{ data.leagueName.name }}
            </span>
            <span class="time">{{ _startTime }}</span>
            <span></span>
        </div>

        <GameBetList v-for="bet in betGroup" :key="bet.betKind" :data="bet" :gameList="data" :isMainBet="true" :showBetName="true" :iconType="2"></GameBetList>
    </div>
</template>

<script>

    export default {
        props: ['data', 'prevLeagueName', 'prevStartTime', 'plusSec', 'maintype'],
        data: function () {
            return {

            }
        },
        methods: {
            _showSportName: function (_sport_id) {
                return getSportName(_sport_id);
            },
        },
        computed: {
            _startTime: function () {
                return getStartTime(this.data.startTime, this.data.sportID, this.plusSec[this.data.sportID]);
            },
            imageLeagueUrl: function(){
                return '/img/flag/league/' + ((this.data.leagueName.flag == null) ? 'world.svg' : this.data.leagueName.flag);
            },
            betGroup: function(){
                let _filter_betKinds = [];
                let _reject_betKinds = [];
                if(_.includes(this.maintype, '1')){
                    _filter_betKinds.push('01');
                    _filter_betKinds.push('04');
                }else{
                    _reject_betKinds.push('01');
                    _reject_betKinds.push('04');
                }
                if(_.includes(this.maintype, '2')){
                    _filter_betKinds.push('06');
                }else{
                    _reject_betKinds.push('06');
                }
                if(_.includes(this.maintype, '3')){
                    _filter_betKinds.push('08');
                }else{
                    _reject_betKinds.push('08');
                }

                let _get_games = [];
                if(_.includes(this.maintype, '4')){
                    _get_games = _.reject(this.data.games, function(_game) { return _.includes(_reject_betKinds, _game.betKind.toString().substr(2, 2)) })
                }else{
                    _get_games = _.filter(this.data.games, function(_game) { return _.includes(_filter_betKinds, _game.betKind.toString().substr(2, 2)) })
                }

                return _.chain(_get_games)
                .groupBy("betKind")
                .map((value, key) => ({ sort: _.head(value).sort, betKind: key, games: value }))
                .sortBy("sort")
                .value();
            },
        },
        components: {
        },
    }
</script>
