
window.getBetKind = function (sportID, betKind){
    if(betKind == 1006 || betKind == 1008 || betKind == 2006 || betKind == 2008 || betKind == 1007 || betKind == 1009 || betKind == 1108 || betKind == 1208 || betKind == 1308 || betKind == 1408 || betKind == 1106 || betKind == 1206 || betKind == 1306 || betKind == 1406 || betKind == 1506 || betKind == 1508 || betKind == 2106 || betKind == 2108 || betKind == 2206 || betKind == 2208 || betKind == 2306 || betKind == 2308 || betKind == 2406 || betKind == 2408 || betKind == 2506 || betKind == 2508
        || betKind == 1038 || betKind == 1039 || betKind == 1041 || betKind == 1042 || betKind == 2041 || betKind == 2042
        || betKind == 1118 || betKind == 1128 || betKind == 1218 || betKind == 1228 || betKind == 1318 || betKind == 1328 || betKind == 1418 || betKind == 1428)
        return 'point';
    else if(betKind == 1001 || betKind == 1101 || betKind == 1002 || betKind == 1201 || betKind == 1102 || betKind == 1161 || betKind == 1074 || betKind == 1301 || betKind == 1401
        || betKind == 1031 || betKind == 1026 || betKind == 1028)
        return 'wdl';
    else if(betKind == 1004 || betKind == 1104 || betKind == 1204 || betKind == 1304  || betKind == 1404 || betKind == 1504 || betKind == 9181 || betKind == 9183 || betKind == 9184 || betKind == 9188 || betKind == 1033
        || (betKind.toString().substr(0, 1) == '9' && (betKind.toString().substr(2, 2) == '91' || betKind.toString().substr(2, 2) == '92' || betKind.toString().substr(2, 2) == '93' || betKind.toString().substr(2, 2) == '94')))
        return 'wl';
    else if(betKind == 11001)
        return 'wdlp';
    else if(betKind == 1003 || betKind == 1103 || betKind == 1203 || betKind == 1011 || betKind == 1111 || betKind == 2011 || betKind == 1121 || betKind == 1122 || betKind == 1162 || betKind == 1163 || betKind == 1164 || betKind == 1165 || betKind == 1071 || betKind == 1072 || betKind == 1073)
        return 'yesno';
    else if(betKind == 1005 || betKind == 1105 || betKind == 1205 || betKind == 1015  || betKind == 1016 )
        return 'correctGoals';
    else if(betKind == 1010 || betKind == 1110)
        return 'correctScore';
}

window.getBetName = function (sportID, betKind, sportSubID = ''){

    if(sportID == 601 || sportID == 621 || sportID == 622){
        if(betKind == 1011)
            return '파워볼 홀짝';
        else if(betKind == 1111)
            return '일반볼 홀짝';
        else if(betKind == 1008)
            return '파워볼 언더오버';
        else if(betKind == 1108)
            return '일반볼 언더오버';
        else if(betKind == 1161)
            return '일반볼 소 | 중 | 대';
    }
    else if(sportID == 602 || sportID == 604){
        if(betKind == 1162)
            return '좌우 출발';
        else if(betKind == 1163)
            return '3줄 | 4줄';
        else if(betKind == 1164 || betKind == 1165)
            return '출줄 배팅';
    }
    else if(sportID == 611){
        if(betKind == 1011)
            return '파워프리킥 골/노골';
        else if(betKind == 1162)
            return '파워프리킥 방향';
        else if(betKind == 1163)
            return '파워프리킥 선수';
        else if(betKind == 1164 || betKind == 1165)
            return '파워프리킥 조합';
    }
    else if(sportID == 612){
        if(betKind == 1011)
            return '스피드홈런 홈런/아웃';
        else if(betKind == 1162)
            return '스피드홈런 방향';
        else if(betKind == 1163)
            return '스피드홈런 선수';
        else if(betKind == 1164 || betKind == 1165)
            return '스피드홈런 조합';
    }
    else if(sportID == 701){
        if(betKind == 1071)
            return '스피드룰렛 홀짝';
        else if(betKind == 1072)
            return '스피드룰렛 빨강검정';
        else if(betKind == 1073)
            return '스피드룰렛 언더오버';
        else if(betKind == 1074)
            return '스피드룰렛 소중대';
    }

    if(betKind == 1001)
        return '승무패';
    else if(betKind == 1002)
        return '더블찬스';
    else if(betKind == 1003)
        return '양팀 모두 득점';
    else if(betKind == 1004)
        return '승패';
    else if(betKind == 1005)
        return '정확한 골';
    else if(betKind == 1006){
        if(sportID == 2 || sportID == 3 || sportID == 18)
            return '핸디캡(연장포함)';
        else if(sportID == 4)
            return '핸디캡(정규세트)';
        else if(sportID == 25)
            return '핸디캡';
        else
            return '아시안 핸디캡';
    }else if(betKind == 2006)
        return '핸디캡(점수합계)';
    else if(betKind == 1007)
        return '아시안 핸디캡 추가기준점';
    else if(betKind == 1008){
        if(sportID == 2 || sportID == 3 || sportID == 18)
            return '언더오버(연장포함)';
        else if(sportID == 25)
            return '언더오버';
        else
            return '아시안 언더오버';
    }else if(betKind == 2008)
        return '언더오버(점수합계)';
    else if(betKind == 1009)
        return '언더오버 추가기준점';
    else if(betKind == 1010)
        return '정확한 스코어';
    else if(betKind == 1011){
        if(sportID == 2 || sportID == 3)
            return '홀짝(연장포함)';
        else if(sportID == 4)
            return '홀짝(정규세트)';
        else
            return '홀짝';
    }else if(betKind == 2011){
        if(sportID == 4)
            return '홀짝(점수합계)';
        else
            return '홀짝';
    }else if(sportID == 1){
        if(betKind == 1101)
            return '전반전 승무패';
        else if(betKind == 1106)
            return '전반전 핸디캡';
        else if(betKind == 1108)
            return '전반전 언더오버';
        else if(betKind == 1105)
            return '전반전 정확한 골';
        else if(betKind == 1103)
            return '전반전 양팀 모두 득점';
        else if(betKind == 1203)
            return '후반전 양팀 모두 득점';
        else if(betKind == 1205)
            return '후반전 정확한 골';
        else if(betKind == 1111)
            return '전반전 홀짝';
        else if(betKind == 1208)
            return '후반전 언더오버';
        else if(betKind == 1102)
            return '전반전 더블찬스';
        else if(betKind == 1201)
            return '후반전 승무패';
        else if(betKind == 1031)
            return '전반전/풀타임';
        else if(betKind == 1033)
            return '승무패/양팀득점';
        else if(betKind == 1026)
            return '승무패(핸디캡)';
        else if(betKind == 1038)
            return '승무패/언더오버';
        else if(betKind == 1039)
            return '언더오버/양팀득점';
        else if(betKind == 1041)
            return '홈-언더오버';
        else if(betKind == 1042)
            return '원정-언더오버';
    }
    else if(sportID == 2){
        if(betKind == 1101)
            return '1이닝 승무패';
        else if(betKind == 1121)
            return '1회초 득점 유무';
        else if(betKind == 1122)
            return '1회말 득점 유무';
        else if(betKind == 1306)
            return '3이닝 핸디캡';
        else if(betKind == 1308)
            return '3이닝 언더오버';
        else if(betKind == 1506)
            return '5이닝 핸디캡';
        else if(betKind == 1508)
            return '5이닝 언더오버';
        else if(betKind == 1706)
            return '7이닝 핸디캡';
        else if(betKind == 1708)
            return '7이닝 언더오버';
        else if(betKind == 9181)
            return '첫볼넷';
    }
    else if(sportID == 3){
        if(betKind == 1101)
            return '1쿼터 승무패';
        else if(betKind == 1201)
            return '2쿼터 승무패';
        else if(betKind == 1301)
            return '3쿼터 승무패';
        else if(betKind == 1401)
            return '4쿼터 승무패';
        else if(betKind == 1104)
            return '1쿼터 승패';
        else if(betKind == 1204)
            return '2쿼터 승패';
        else if(betKind == 1304)
            return '3쿼터 승패';
        else if(betKind == 1404)
            return '4쿼터 승패';
        else if(betKind == 1106)
            return '1쿼터 핸디캡';
        else if(betKind == 1206)
            return '2쿼터 핸디캡';
        else if(betKind == 1306)
            return '3쿼터 핸디캡';
        else if(betKind == 1406)
            return '4쿼터 핸디캡';
        else if(betKind == 1108)
            return '1쿼터 언더오버';
        else if(betKind == 1208)
            return '2쿼터 언더오버';
        else if(betKind == 1308)
            return '3쿼터 언더오버';
        else if(betKind == 1408)
            return '4쿼터 언더오버';
        else if(betKind == 1015)
            return '득점합계 3-way(연장포함)';
        else if(betKind == 1016)
            return '득점합계 8-way(연장포함)';
        else if(betKind == 9183)
            return '첫3점슛';
        else if(betKind == 9184)
            return '첫자유투';
        else if(betKind == 1118)
            return '1쿼터 홈 언더오버';
        else if(betKind == 1128)
            return '1쿼터 원정 언더오버';
        else if(betKind == 1218)
            return '2쿼터 홈 언더오버';
        else if(betKind == 1228)
            return '2쿼터 원정 언더오버';
        else if(betKind == 1318)
            return '3쿼터 홈 언더오버';
        else if(betKind == 1328)
            return '3쿼터 원정 언더오버';
        else if(betKind == 1418)
            return '4쿼터 홈 언더오버';
        else if(betKind == 1428)
            return '4쿼터 원정 언더오버';
    }
    else if(sportID == 4){
        if(betKind == 1104)
            return '1세트 승패';
        else if(betKind == 1106)
            return '1세트 핸디캡';
        else if(betKind == 1108)
            return '1세트 언더오버';
        else if(betKind == 1204)
            return '2세트 승패';
        else if(betKind == 1206)
            return '2세트 핸디캡';
        else if(betKind == 1208)
            return '2세트 언더오버';
        else if(betKind == 1304)
            return '3세트 승패';
        else if(betKind == 1306)
            return '3세트 핸디캡';
        else if(betKind == 1308)
            return '3세트 언더오버';
        else if(betKind == 1404)
            return '4세트 승패';
        else if(betKind == 1406)
            return '4세트 핸디캡';
        else if(betKind == 1408)
            return '4세트 언더오버';
        else if(betKind == 1504)
            return '5세트 승패';
        else if(betKind == 1506)
            return '5세트 핸디캡';
        else if(betKind == 1508)
            return '5세트 언더오버';
        else if(betKind == 1111)
            return '1세트 홀짝';
        else if(betKind == 1110)
            return '1세트 정확한 스코어';
        else if(betKind == 2041)
            return '홈-언더오버';
        else if(betKind == 2042)
            return '원정-언더오버';
    }
    else if(sportID == 9){
        if(betKind == 1104)
            return '1세트 승패';
        else if(betKind == 1110)
            return '1세트 정확한 스코어';
        else if(betKind == 1108)
            return '1세트 언더오버';
    }
    else if(sportID == 18){
        if(betKind == 1104)
            return '전반전 승패';
        else if(betKind == 1108)
            return '전반전 언더오버';
        else if(betKind == 1106)
            return '전반전 핸디캡';
    }
    else if(sportID == 21){
        if(betKind == 1104)
            return '1피리어드 승패';
        else if(betKind == 1101)
            return '1피리어드 승무패';
        else if(betKind == 1108)
            return '1피리어드 언더오버';
        else if(betKind == 1106)
            return '1피리어드 핸디캡';
        else if(betKind == 1102)
            return '1피리어드 더블찬스';
        else if(betKind == 1204)
            return '2피리어드 승패';
        else if(betKind == 1201)
            return '2피리어드 승무패';
        else if(betKind == 1208)
            return '2피리어드 언더오버';
        else if(betKind == 1206)
            return '2피리어드 핸디캡';
        else if(betKind == 1202)
            return '2피리어드 더블찬스';
        else if(betKind == 1304)
            return '3피리어드 승패';
        else if(betKind == 1301)
            return '3피리어드 승무패';
        else if(betKind == 1308)
            return '3피리어드 언더오버';
        else if(betKind == 1306)
            return '3피리어드 핸디캡';
        else if(betKind == 1302)
            return '3피리어드 더블찬스';
        else if(betKind == 9188)
            return '첫득점';
        else if(betKind == 1026)
            return '승무패(핸디캡)';
        else if(betKind == 1028)
            return '언더오버-3way';
        else if(betKind == 1118)
            return '1피리어드 홈 언더오버';
        else if(betKind == 1128)
            return '1피리어드 원정 언더오버';
        else if(betKind == 1218)
            return '2피리어드 홈 언더오버';
        else if(betKind == 1228)
            return '2피리어드 원정 언더오버';
        else if(betKind == 1318)
            return '3피리어드 홈 언더오버';
        else if(betKind == 1328)
            return '3피리어드 원정 언더오버';
    }
    else if(sportID == 25){
        if(betKind == 1104)
            return '1' + (sportSubID == 1 ? '경기' : '세트') + ' 승패';
        else if(betKind == 1204)
            return '2' + (sportSubID == 1 ? '경기' : '세트') + ' 승패';
        else if(betKind == 1304)
            return '3' + (sportSubID == 1 ? '경기' : '세트') + ' 승패';
        else if(betKind == 1404)
            return '4' + (sportSubID == 1 ? '경기' : '세트') + ' 승패';
        else if(betKind == 1504)
            return '5' + (sportSubID == 1 ? '경기' : '세트') + ' 승패';
        else if(betKind == 1106 || betKind == 2106)
            return '1' + (sportSubID == 1 ? '경기' : '세트') + ' 핸디캡(킬수 합)';
        else if(betKind == 1108 || betKind == 2108)
            return '1' + (sportSubID == 1 ? '경기' : '세트') + ' 언더오버(킬수 합)';
        else if(betKind == 1206 || betKind == 2206)
            return '2' + (sportSubID == 1 ? '경기' : '세트') + ' 핸디캡(킬수 합)';
        else if(betKind == 1208 || betKind == 2208)
            return '2' + (sportSubID == 1 ? '경기' : '세트') + ' 언더오버(킬수 합)';
        else if(betKind == 1306 || betKind == 2306)
            return '3' + (sportSubID == 1 ? '경기' : '세트') + ' 핸디캡(킬수 합)';
        else if(betKind == 1308 || betKind == 2308)
            return '3' + (sportSubID == 1 ? '경기' : '세트') + ' 언더오버(킬수 합)';
        else if(betKind == 1406 || betKind == 2406)
            return '4' + (sportSubID == 1 ? '경기' : '세트') + ' 핸디캡(킬수 합)';
        else if(betKind == 1408 || betKind == 2408)
            return '4' + (sportSubID == 1 ? '경기' : '세트') + ' 언더오버(킬수 합)';
        else if(betKind == 1506 || betKind == 2506)
            return '5' + (sportSubID == 1 ? '경기' : '세트') + ' 핸디캡(킬수 합)';
        else if(betKind == 1508 || betKind == 2508)
            return '5' + (sportSubID == 1 ? '경기' : '세트') + ' 언더오버(킬수 합)';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '91')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트') + ' 첫용';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '92')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트') + ' 첫바론';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '93')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트') + ' 첫타워';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '94')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트') + ' 첫킬';
    }
    return '';
}

window.getRoundName = function (sportID, betKind, sportSubID = ''){

    if(sportID == 1){
        if(betKind == 1101 || betKind == 1105 || betKind == 1103 || betKind == 1111 || betKind == 1102)
            return '전반전';
        else if(betKind == 1203 || betKind == 1205|| betKind == 1208 || betKind == 1201)
            return '후반전';
        }
    else if(sportID == 2){
        if(betKind == 1101)
            return '1이닝';
        else if(betKind == 1121)
            return '1회초';
        else if(betKind == 1122)
            return '1회말';
        else if(betKind == 1306 || betKind == 1308)
            return '3이닝';
        else if(betKind == 1506 || betKind == 1508)
            return '5이닝';
        else if(betKind == 1706 || betKind == 1708)
            return '7이닝';
    }
    else if(sportID == 3){
        if(betKind == 1101 || betKind == 1104 || betKind == 1106 || betKind == 1108 || betKind == 1118 || betKind == 1128)
            return '1쿼터';
        else if(betKind == 1201 || betKind == 1206 || betKind == 1208 || betKind == 1218 || betKind == 1228)
            return '2쿼터';
        else if(betKind == 1301 || betKind == 1306 || betKind == 1308 || betKind == 1318 || betKind == 1328)
            return '3쿼터';
        else if(betKind == 1401 || betKind == 1406 || betKind == 1408 || betKind == 1418 || betKind == 1428)
            return '4쿼터';
    }
    else if(sportID == 4){
        if(betKind == 1111 || betKind == 1101 || betKind == 1104 || betKind == 1110 || betKind == 1106 || betKind == 1108)
            return '1세트';
        else if(betKind == 1201 || betKind == 1204 || betKind == 1206 || betKind == 1208)
            return '2세트';
        else if(betKind == 1301 || betKind == 1304 || betKind == 1306 || betKind == 1308)
            return '3세트';
        else if(betKind == 1401 || betKind == 1404 || betKind == 1406 || betKind == 1408)
            return '4세트';
        else if(betKind == 1501 || betKind == 1504 || betKind == 1506 || betKind == 1508)
            return '5세트';
    }
    else if(sportID == 9){
        if(betKind == 1104 || betKind == 1110 || betKind == 1108)
            return '1세트';
    }
    else if(sportID == 18){
        if(betKind == 1104 || betKind == 1106 || betKind == 1108)
            return '전반전';
    }
    else if(sportID == 21){
        if(betKind == 1101 || betKind == 1104 || betKind == 1102 || betKind == 1106 || betKind == 1108)
            return '1피리어드';
    }
    else if(sportID == 25){
        if(betKind == 1104 || betKind == 1106 || betKind == 1108 || betKind == 2106 || betKind == 2108)
            return '1' + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind == 1204 || betKind == 1206 || betKind == 1208 || betKind == 2206 || betKind == 2208)
            return '2' + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind == 1304 || betKind == 1306 || betKind == 1308 || betKind == 2306 || betKind == 2308)
            return '3' + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind == 1404 || betKind == 1406 || betKind == 1408 || betKind == 2406 || betKind == 2408)
            return '4' + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind == 1504 || betKind == 1506 || betKind == 1508 || betKind == 2506 || betKind == 2508)
            return '5' + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '91')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '92')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '93')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트');
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '94')
            return betKind.toString().substr(1, 1) + (sportSubID == 1 ? '경기' : '세트');
    }
    return '';
}

window.getKindName = function (sportID, betKind, sportSubID = ''){

    if(sportID == 601 || sportID == 621 || sportID == 622){
        if(betKind == 1011)
            return '파워볼 홀짝';
        else if(betKind == 1111)
            return '일반볼 홀짝';
        else if(betKind == 1008)
            return '파워볼 언더오버';
        else if(betKind == 1108)
            return '일반볼 언더오버';
        else if(betKind == 1161)
            return '일반볼 소 | 중 | 대';
    }
    else if(sportID == 602 || sportID == 604){
        if(betKind == 1162)
            return '좌우 출발';
        else if(betKind == 1163)
            return '3줄 | 4줄';
        else if(betKind == 1164 || betKind == 1165)
            return '출줄 배팅';
    }
    else if(sportID == 611){
        if(betKind == 1011)
            return '파워프리킥 골/노골';
        else if(betKind == 1162)
            return '파워프리킥 방향';
        else if(betKind == 1163)
            return '파워프리킥 선수';
        else if(betKind == 1164 || betKind == 1165)
            return '파워프리킥 조합';
    }
    else if(sportID == 612){
        if(betKind == 1011)
            return '스피드홈런 홈런/아웃';
        else if(betKind == 1162)
            return '스피드홈런 방향';
        else if(betKind == 1163)
            return '스피드홈런 선수';
        else if(betKind == 1164 || betKind == 1165)
            return '스피드홈런 조합';
    }

    if(betKind == 1001)
        return '승무패';
    else if(betKind == 1002)
        return '더블찬스';
    else if(betKind == 1003)
        return '양팀 모두 득점';
    else if(betKind == 1004)
        return '승패';
    else if(betKind == 1005)
        return '정확한 골';
    else if(betKind == 1006){
        if(sportID == 2 || sportID == 3 || sportID == 18)
            return '핸디캡(연장포함)';
        else if(sportID == 4)
            return '핸디캡(정규세트)';
        else if(sportID == 25)
            return '핸디캡';
        else
            return '아시안 핸디캡';
    }else if(betKind == 2006)
        return '핸디캡(점수합계)';
    else if(betKind == 1007)
        return '아시안 핸디캡 추가기준점';
    else if(betKind == 1008){
        if(sportID == 2 || sportID == 3 || sportID == 18)
            return '언더오버(연장포함)';
        else if(sportID == 25)
            return '언더오버';
        else
            return '아시안 언더오버';
    }else if(betKind == 2008)
        return '언더오버(점수합계)';
    else if(betKind == 1009)
        return '언더오버 추가기준점';
    else if(betKind == 1010)
        return '정확한 스코어';
    else if(betKind == 1011){
        if(sportID == 2 || sportID == 3)
            return '홀짝(연장포함)';
        else if(sportID == 4)
            return '홀짝(정규세트)';
        else
            return '홀짝';
    }else if(betKind == 2011){
        if(sportID == 4)
            return '홀짝(점수합계)';
        else
            return '홀짝';
    }else if(sportID == 1){
        if(betKind == 1101)
            return '승무패';
        else if(betKind == 1105)
            return '정확한 골';
        else if(betKind == 1103)
            return '양팀 모두 득점';
        else if(betKind == 1203)
            return '양팀 모두 득점';
        else if(betKind == 1205)
            return '정확한 골';
        else if(betKind == 1111)
            return '홀짝';
        else if(betKind == 1208)
            return '언더오버';
        else if(betKind == 1102)
            return '더블찬스';
        else if(betKind == 1201)
            return '승무패';
        else if(betKind == 1031)
            return '전반전/풀타임';
        else if(betKind == 1033)
            return '승무패/양팀득점';
        else if(betKind == 1026)
            return '승무패(핸디캡)';
        else if(betKind == 1038)
            return '승무패/언더오버';
        else if(betKind == 1039)
            return '언더오버/양팀득점';
        else if(betKind == 1041)
            return '홈-언더오버';
        else if(betKind == 1042)
            return '원정-언더오버';
    }
    else if(sportID == 2){
        if(betKind == 1101)
            return '승무패';
        else if(betKind == 1121 ||betKind == 1122)
            return '득점 유무';
        else if(betKind == 1306 || betKind == 1506 || betKind == 1706)
            return '핸디캡';
        else if(betKind == 1308|| betKind == 1508 || betKind == 1708)
            return '언더오버';
        else if(betKind == 9181)
            return '첫볼넷';
    }
    else if(sportID == 3){
        if(betKind == 1101 || betKind == 1201 || betKind == 1301|| betKind == 1401)
            return '승무패';
        else if(betKind == 1104)
            return '승패';
        else if(betKind == 1106 || betKind == 1206 || betKind == 1306 || betKind == 1406)
            return '핸디캡';
        else if(betKind == 1108 ||betKind == 1208 || betKind == 1308 || betKind == 1408 || betKind == 1118 || betKind == 1128 || betKind == 1218 || betKind == 1228 || betKind == 1318 || betKind == 1328 || betKind == 1418 || betKind == 1428)
            return '언더오버';
        else if(betKind == 1015)
            return '득점합계 3-way(연장포함)';
        else if(betKind == 1016)
            return '득점합계 8-way(연장포함)';
        else if(betKind == 9183)
            return '첫3점슛';
        else if(betKind == 9184)
            return '첫자유투';
    }
    else if(sportID == 4){
        if(betKind == 1111)
            return '홀짝';
        else if(betKind == 1110)
            return '정확한 스코어';
        else if(betKind == 1108)
            return '언더오버';
        else if(betKind == 1106)
            return '핸디캡';
        else if(betKind == 2041)
            return '홈-언더오버';
        else if(betKind == 2042)
            return '원정-언더오버';
    }
    else if(sportID == 9){
        if(betKind == 1104)
            return '승패';
        else if(betKind == 1110)
            return '정확한 스코어';
        else if(betKind == 1108)
            return '언더오버';
    }
    else if(sportID == 18){
        if(betKind == 1104)
            return '승패';
        else if(betKind == 1108)
            return '언더오버';
        else if(betKind == 1106)
            return '핸디캡';
    }
    else if(sportID == 21){
        if(betKind == 1104)
            return '승패';
        else if(betKind == 1101)
            return '승무패';
        else if(betKind == 1108)
            return '언더오버';
        else if(betKind == 1106)
            return '핸디캡';
        else if(betKind == 1102)
            return '더블찬스';
        else if(betKind == 9188)
            return '첫득점';
        else if(betKind == 1026)
            return '승무패(핸디캡)';
        else if(betKind == 1028)
            return '언더오버-3way';
    }
    else if(sportID == 25){
        if(betKind == 1104 ||betKind == 1304 || betKind == 1404 || betKind == 1504)
            return '승패';
        else if(betKind == 1106 ||betKind == 1206 || betKind == 1306 || betKind == 1406 || betKind == 1506 || betKind == 2106 ||betKind == 2206 || betKind == 2306 || betKind == 2406 ||betKind == 2506)
            return '핸디캡(킬수 합)';
        else if(betKind == 1108 ||betKind == 1208 || betKind == 1308 || betKind == 1408 || betKind == 1508 || betKind == 2108 ||betKind == 2208 || betKind == 2308 || betKind == 2408 || betKind == 2508)
            return '언더오버(킬수 합)';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '91')
            return ' 첫용';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '92')
            return ' 첫바론';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '93')
            return ' 첫타워';
        else if(betKind.toString().substr(0, 1) == '9' && betKind.toString().substr(2, 2) == '94')
            return ' 첫킬';
    }
    return '';
}

window.getOddsName = function (sportID, betKind, betTeam, homeName, awayName, point, etc){

    if(sportID == 601 || sportID == 621 || sportID == 622){
        if(betKind == 1008){
            if(betTeam == 1)
                return "파워볼 - 언더";
            else if(betTeam == 3)
                return "파워볼 - 오버";
        }
        else if(betKind == 1108){
            if(betTeam == 1)
                return "일반볼 - 언더";
            else if(betTeam == 3)
                return "일반볼 - 오버";
        }
        else if(betKind == 1011){
            if(betTeam == 1)
                return "파워볼 - 홀";
            else if(betTeam == 3)
                return "파워볼 - 짝";
        }
        else if(betKind == 1111){
            if(betTeam == 1)
                return "일반볼 - 홀";
            else if(betTeam == 3)
                return "일반볼 - 짝";
        }
        else if(betKind == 1161){
            if(betTeam == 1)
                return "일반볼 - 소 [15-64]";
            else if(betTeam == 2)
                return "일반볼 - 중 [65-80]";
            else if(betTeam == 3)
                return "일반볼 - 대 [81-130]";
        }
    }
    else if(sportID == 602 || sportID == 604){
        if(betKind == 1162){
            if(betTeam == 1)
                return "좌 출발";
            else if(betTeam == 3)
                return "우 출발";
        }
        else if(betKind == 1163){
            if(betTeam == 1)
                return "3줄";
            else if(betTeam == 3)
                return "4줄";
        }
        else if(betKind == 1164){
            if(betTeam == 1)
                return "좌4 홀";
            else if(betTeam == 3)
                return "우3 홀";
        }
        else if(betKind == 1165){
            if(betTeam == 1)
                return "좌3 짝";
            else if(betTeam == 3)
                return "우4 짝";
        }
    }
    else if(sportID == 611){
        if(betKind == 1011){
            if(betTeam == 1)
                return "골";
            else if(betTeam == 3)
                return "노골";
        }
        else if(betKind == 1162){
            if(betTeam == 1)
                return "좌 방향";
            else if(betTeam == 3)
                return "우 방향";
        }
        else if(betKind == 1163){
            if(betTeam == 1)
                return "1번 선수";
            else if(betTeam == 3)
                return "2번 선수";
        }
        else if(betKind == 1164){
            if(betTeam == 1)
                return "좌-2번";
            else if(betTeam == 3)
                return "우-1번";
        }
        else if(betKind == 1165){
            if(betTeam == 1)
                return "좌-1번";
            else if(betTeam == 3)
                return "우-2번";
        }
    }
    else if(sportID == 612){
        if(betKind == 1011){
            if(betTeam == 1)
                return "홈런";
            else if(betTeam == 3)
                return "아웃";
        }
        else if(betKind == 1162){
            if(betTeam == 1)
                return "좌 방향";
            else if(betTeam == 3)
                return "우 방향";
        }
        else if(betKind == 1163){
            if(betTeam == 1)
                return "1번 선수";
            else if(betTeam == 3)
                return "2번 선수";
        }
        else if(betKind == 1164){
            if(betTeam == 1)
                return "좌-2번";
            else if(betTeam == 3)
                return "우-1번";
        }
        else if(betKind == 1165){
            if(betTeam == 1)
                return "좌-1번";
            else if(betTeam == 3)
                return "우-2번";
        }
    }
    else if(sportID == 701){
        if(betKind == 1071){
            if(betTeam == 1)
                return "홀";
            else if(betTeam == 3)
                return "짝";
        }
        else if(betKind == 1072){
            if(betTeam == 1)
                return "빨강";
            else if(betTeam == 3)
                return "검정";
        }
        else if(betKind == 1073){
            if(betTeam == 1)
                return "언더";
            else if(betTeam == 3)
                return "오버";
        }
        else if(betKind == 1074){
            if(betTeam == 1)
                return "소[1-12]";
            else if(betTeam == 2)
                return "중[13-24]";
            else if(betTeam == 3)
                return "대[25-36]";
        }
    }

    if(betKind == 1001 || betKind == 1101 || betKind == 1201 || betKind == 1301 || betKind == 1401 || betKind == 1004 || betKind == 1104 || betKind == 1204 || betKind == 1304 || betKind == 1404 || betKind == 1504 || betKind == 1026){
        if(betTeam == 1)
            return homeName + (homeName == "홈" ? ' 승' : '');
        else if(betTeam == 2)
            return '무승부';
        else if(betTeam == 3)
            return awayName + (awayName == "원정" ? ' 승' : '');
    }
    if(betKind == 1002 || betKind == 1102){
        if(betTeam == 1)
            return homeName + ' 승, 무';
        else if(betTeam == 2)
            return homeName + ', ' + awayName + ' 승';
        else if(betTeam == 3)
            return awayName + ' 승, 무';
    }
    if(betKind == 1003 || betKind == 1103 || betKind == 1203 || betKind == 1121 || betKind == 1122){
        if(betTeam == 1)
            return '예';
        else if(betTeam == 3)
            return '아니오';
    }
    else if(betKind == 1006 || betKind == 2006 || betKind == 1007 || betKind == 1106 || betKind == 1206 || betKind == 1306 || betKind == 1406 || betKind == 1506 || betKind == 1706 || betKind == 2106 || betKind == 2206 || betKind == 2306 || betKind == 2406 || betKind == 2506){
        if(betTeam == 1)
            return homeName + ' 승';
        else if(betTeam == 2)
            return '무승부';
        else if(betTeam == 3)
            return awayName + ' 승';
    }
    else if(betKind == 1008 || betKind == 2008 || betKind == 1009 || betKind == 1108 || betKind == 1208 || betKind == 1308 || betKind == 1408 || betKind == 1508 || betKind == 1708 || betKind == 2108 || betKind == 2208 || betKind == 2308 || betKind == 2408 || betKind == 2508 || betKind == 1041 || betKind == 1042 || betKind == 2041 || betKind == 2042
        || betKind == 1118 || betKind == 1128 || betKind == 1218 || betKind == 1228 || betKind == 1318 || betKind == 1328 || betKind == 1418 || betKind == 1428){
        if(betTeam == 1)
            return '언더';
        else if(betTeam == 3)
            return '오버';
    }
    else if(betKind == 1028){
        if(betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') +'언더';
        else if(betTeam == 2)
            return '타이';
        else if(betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') +'오버';
    }
    else if(betKind == 1011 || betKind == 1111 || betKind == 2011){
        if(betTeam == 1)
            return '홀';
        else if(betTeam == 3)
            return '짝';
    }
    else if(betKind == 1005 || betKind == 1105 || betKind == 1205){
        if(betTeam == 1)
            return point + '골';
    }
    else if(betKind == 1015 || betKind == 1016){
        if(betTeam == 1)
            return point;
    }
    else if(betKind == 1010 || betKind == 1110){
        if(betTeam == 1 || betTeam == 2)
            return point.replace( '-', ':' );
        else if(betTeam == 3){
            var pointSplit = point.split('-');
            if(pointSplit.length == 2)
                return pointSplit[1] + ':' + pointSplit[0];
        }
    }
    else if(betKind == 1031){
        if(etc == 1 && betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') + '홈/홈';
        else if(etc == 1 && betTeam == 2)
            return '홈/무승부';
        else if(etc == 1 && betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') +  '홈/원정';
        else if(etc == 2 && betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') + '무승부/홈';
        else if(etc == 2 && betTeam == 2)
            return '무승부/무승부';
        else if(etc == 2 && betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') + '무승부/원정';
        else if(etc == 3 && betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') + '원정/홈';
        else if(etc == 3 && betTeam == 2)
            return '원정/무승부';
        else if(etc == 3 && betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') + '원정/원정';
    }
    else if(betKind == 1033){
        if(etc == 1 && betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') + '홈/네';
        else if(etc == 1 && betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') + '홈/아니오';
        else if(etc == 2 && betTeam == 1)
            return '무승부/네';
        else if(etc == 2 && betTeam == 3)
            return '무승부/아니오';
        else if(etc == 3 && betTeam == 1)
            return (homeName == '홈' ? '' : homeName + '-') + '원정/네';
        else if(etc == 3 && betTeam == 3)
            return (awayName == '원정' ? '' : awayName + '-') + '원정/아니오';
    }
    else if(betKind == 1038){
        if(etc == 1 && betTeam == 1)
            return '홈/언더';
        else if(etc == 1 && betTeam == 3)
            return '홈/오버';
        else if(etc == 2 && betTeam == 1)
            return '무/언더';
        else if(etc == 2 && betTeam == 3)
            return '무/오버';
        else if(etc == 3 && betTeam == 1)
            return '원정/언더';
        else if(etc == 3 && betTeam == 3)
            return '원정/오버';
    }
    else if(betKind == 1039){
        if(etc == 1 && betTeam == 1)
            return '언더/네';
        else if(etc == 1 && betTeam == 3)
            return '오버/네';
        else if(etc == 2 && betTeam == 1)
            return '언더/아니오';
        else if(etc == 2 && betTeam == 3)
            return '오버/아니오';
    }
    else if(betKind.toString().substr(0, 1) == '9' && (betKind.toString().substr(2, 2) == '91' || betKind.toString().substr(2, 2) == '92' || betKind.toString().substr(2, 2) == '93' || betKind.toString().substr(2, 2) == '94')){
        if(betTeam == 1)
            return homeName + (homeName == "홈" ? ' 승' : '');
        else if(betTeam == 3)
            return awayName + (awayName == "원정" ? ' 승' : '');
    }
    else if(betKind == 9181 || betKind == 9183 || betKind == 9184 || betKind == 9188){
        if(betTeam == 1)
            return homeName + (homeName == "홈" ? ' 승' : '');
        else if(betTeam == 3)
            return awayName + (awayName == "원정" ? ' 승' : '');
    }

    return '';
}

window.getSportName = function (sportID){

    if(sportID == 1)
        return '축구';
    else if(sportID == 2)
        return '야구';
    else if(sportID == 21)
        return '아이스하키';
    else if(sportID == 3)
        return '농구';
    else if(sportID == 12)
        return '핸드볼';
    else if(sportID == 4)
        return '배구';
    else if(sportID == 25)
        return 'E스포츠';
    else if(sportID == 16)
        return 'UFC';
    else if(sportID == 18)
        return '미식축구';
    else if(sportID == 9)
        return '테니스';
    else if(sportID == 6)
        return '탁구';
    else if(sportID == 601)
        return '파워볼';
    else if(sportID == 602)
        return '파워사다리';
    else if(sportID == 603)
        return '스피드키노';
    else if(sportID == 604)
        return '키노사다리';
    else if(sportID == 611)
        return '파워프리킥';
    else if(sportID == 621)
        return 'EOS파워볼5분';
    else if(sportID == 622)
        return 'EOS파워볼3분';
    else if(sportID == 626)
        return 'EOS스피드키노';
    else if(sportID == 612)
        return '스피드홈런';
    else if(sportID == 701)
        return '스피드룰렛';

    return '';
}

window.getTypeName = function (sportID, type=1){

    if(sportID == 1)
        return '해외형';
    else if(sportID == 2)
        return '크로스';
    else if(sportID == 3)
        return '스페셜';
    else if(sportID == 4){
        if(type == 2)
            return '실시간';
        else
            return '스페셜2';
    }

    else if(sportID == 21)
        return '인플레이';
    else if(sportID == 12)
        return '미니게임';

    return '';
}

window.getMiniGameName = function (path, sportID){

    if(path == 'powerball' || sportID == 601)
        return '엔트리 파워볼';
    else if(path == 'power_ladder' || sportID == 602)
        return '엔트리 파워사다리';
    else if(path == 'speedkeno' || sportID == 603)
        return '엔트리 스피드키노';
    else if(path == 'keno_ladder' || sportID == 604)
        return '엔트리 키노사다리';
    else if(path == 'eos_powerball5' || sportID == 621)
        return 'EOS 파워볼5분';
    else if(path == 'eos_powerball3' || sportID == 622)
        return 'EOS 파워볼3분';
    else if(path == 'eos_speedkeno5' || sportID == 626)
        return 'EOS 스피드키노';
    else if(path == 'powerball_freekick' || sportID == 611)
        return '파워 프리킥';
    else if(path == 'speedkeno_homerun' || sportID == 612)
        return '스피드 홈런';
    else if(path == 'roulette' || sportID == 701)
        return '스피드 룰렛';

    return '';
}

window.getSparkCasinoName = function (path, type){

    if(path == 'baccarat' || type == 1)
        return '바카라1';
    else if(path == 'baccarat2' || type == 2)
        return '바카라2';
    else if(path == 'baccarat_speed' || type == 3)
        return '스피드 바카라';
    else if(path == 'dragontiger' || type == 4)
        return '드래곤 타이거';
    else if(path == 'dice' || type == 5)
        return '주사위';
    else if(path == 'samchigi' || type == 6)
        return '삼치기';

    return '';
}

window.getGameStatus = function (status){

    if(status == 1)
        return '경기전';
    else if(status == 2)
        return '경기중';
    else if(status == 3)
        return '경기종료';
    else if(status == 4)
        return '경기재개';
    else if(status == 6)
        return '경기연기';
    else if(status == 7)
        return '경기취소';
    else if(status == 8)
        return '경기기권';
    else if(status == 9)
        return '경기중단';
    else if(status == 10)
        return '경기포기';
    else if(status == 11)
        return '경기삭제';
    else if(status == 21)
        return '배당고정';
    else if(status == 22)
        return '배당삭제';
    else if(status == 31)
        return '배당삭제';

    return '';
}

window.isHadycap = function (_betkind){
    if(_betkind == 1006 || _betkind == 1007 || _betkind == 1106 || _betkind == 1206 ||_betkind == 1306 ||_betkind == 1406 ||_betkind == 1506 || _betkind == 1706 || _betkind == 2106 || _betkind == 2206 || _betkind == 2306 || _betkind == 2406 || _betkind == 2506 || _betkind == 2006)
        return true;
    return false;
}

window.isunderover = function (_betkind){
    if(_betkind == 1008 || _betkind == 1009 || _betkind == 1108 || _betkind == 1208 ||_betkind == 1308 ||_betkind == 1408 ||_betkind == 1508 || _betkind == 1708 || _betkind == 2108 || _betkind == 2208 || _betkind == 2308 || _betkind == 2408 || _betkind == 2508 || _betkind == 1041 || _betkind == 1042 || _betkind == 2041 || _betkind == 2042 || _betkind == 2008 || _betkind == 1028
        || _betkind == 1118 || _betkind == 1128 || _betkind == 1218 || _betkind == 1228 || _betkind == 1318 || _betkind == 1328 || _betkind == 1418 || _betkind == 1428) // || _betkind == 1038 || _betkind == 1039
        return true;
    return false;
}

window.isWinner = function(_betkind) {
    if(_betkind == 1001 || _betkind == 1004 || _betkind == 1101 || _betkind == 1104 || _betkind == 1201 || _betkind == 1204 || _betkind == 1301 || _betkind == 1304 || _betkind == 1401 || _betkind == 1404 || _betkind == 1501 || _betkind == 1504)
        return true;
    return false;
}
