<template>
    <div class="nav-side-menu">
        <ul v-if="tops" class="left-top">
            <li v-for="(top, idx) in tops" :key="idx">
                <a :href="top.href">
                    <img :src="top.image" :alt="top.title" />
                </a>
            </li>
        </ul>
        <!-- <div class="box-header-left"><a @click.stop="" :href="`/inplay`"><i class="fab fa-korvue"></i> 인플레이 </a></div>
          <div class="menu-list">
            <ul class="menu-content">
                <SidebarSub v-for="(sport, index) in inplay" :key="index" :index="index" :type="21" :sport="sport">
                </SidebarSub>
            </ul>
        </div> -->
        <div class="box-header-left"><a @click.stop="" :href="`/cross`"><i class="fab fa-korvue"></i> 크로스 - 국내형 </a></div>
          <div class="menu-list">
            <ul class="menu-content">
                <SidebarSub v-for="(sport, index) in cross" :key="index" :index="index" :type="2" :sport="sport">
                </SidebarSub>
            </ul>
        </div>
        <div class="box-header-left"><a @click.stop="" :href="`/overseas`"><i class="fas fa-globe-americas"></i> 해외형 배팅 <span class="right"></span></a></div>
        <div class="menu-list">
            <ul class="menu-content">
                <SidebarSub v-for="(sport, index) in overseas" :key="index" :index="index" :type="1" :sport="sport">
                </SidebarSub>
            </ul>
        </div>
        <div class="box-header-left">
            <a @click.stop="" :href="`/special`">
                <i class="fas fa-star"></i> 스페셜 - 국내형
                <span class="menu-badge">{{ specialCount }} </span>
            </a>
        </div>
        <div class="box-header-left">
            <a @click.stop="" :href="`/special2`">
                <i class="fas fa-star"></i> 스페셜2 - 국내형
                <span class="menu-badge">{{ special2Count }} </span>
            </a>
        </div>

        <!-- <div class="menu-list">
            <ul class="menu-content">
                <li class="main-menu" >
                    <div class="main-content">
                        <span class="menu-badge">
                            {{ specialCount }}
                        </span>
                        <p>
                            <i class="fas fa-globe"></i>
                            전체
                            <a @click.stop="" :href="`/special`">바로가기</a>
                        </p>
                    </div>
                </li>
            </ul>
        </div> -->

        <!-- <div class="menu-list">
            <ul class="menu-content">
                <li class="main-menu" >
                    <div class="main-content">

                        <p>
                            <i class="fas fa-globe"></i>
                            전체
                            <a @click.stop="" :href="`/special2`">바로가기</a>
                        </p>
                    </div>
                </li>
            </ul>
        </div> -->
    </div>
</template>

<script>
    import SidebarSub from './LeftMenuSub.vue'

    export default {
        data: function () {
            return { sidebar : sidebar.menus, tops : LeftMenuTop }
        },
        computed: {
            inplay: function () {
                let _temp = { 1 : [], 2 : [], 3 : [], 4 : [], 21 : []}
                let _filter = _.filter(this.sidebar, function(item) { return item.gameType == 21; });
                let _menus = _.groupBy(_filter, function(side) { return side.sportID; });
                // if(!_.includes(_menus, 9))
                //     _menus.push(9);
                return _.assign(_temp, _menus);
            },
            overseas: function () {
                let _temp = { 1 : [], 2 : [], 3 : [], 4 : [], 6 : [], 9 : [], 12 : [], 16 : [], 18 : [], 21 : [], 25 : []}
                let _filter = _.filter(this.sidebar, function(item) { return item.gameType == 1; });
                let _menus = _.groupBy(_filter, function(side) { return side.sportID; });
                // if(!_.includes(_menus, 9))
                //     _menus.push(9);
                return _.assign(_temp, _menus);
            },
            cross: function () {
                let _temp = { 1 : [], 2 : [], 3 : [], 4 : [], 6 : [], 9 : [], 12 : [], 16 : [], 18 : [], 21 : [], 25 : []}
                let _filter = _.filter(this.sidebar, function(item) { return item.gameType == 2; });
                let _menus = _.groupBy(_filter, function(side) { return side.sportID; });
                // if(!_.includes(_menus, 9))
                //     _menus.push(9);
                return _.assign(_temp, _menus);
            },
            specialCount: function () {
                let _filter = _.filter(this.sidebar, function(item) { return item.gameType == 3; });
                return _.sumBy(_filter, function(sport) { return sport.count; })
            },
            special2Count: function () {
                let _filter = _.filter(this.sidebar, function(item) { return item.gameType == 4; });
                return _.sumBy(_filter, function(sport) { return sport.count; })
            },
        },
        methods: {

        },
        components: {
            SidebarSub
        },
    }
</script>
