window.CasinoGetLink = async function (_id){
    if(_id == 'SABONG'){
        let _get_balance = await CasinoGetCash(_id);
        if(_get_balance != undefined){
            let _title = "";
            if(_id == 'SABONG')
                _title = "사봉";
            Swal.fire({
                title: '<i class="fas fa-exchange-alt"></i> ' +  _title + ' 머니전환',
                confirmButtonText: '<i class="fas fa-exchange-alt"></i> 전환',
                cancelButtonText: '<i class="fas fa-times"></i> 취소',
                denyButtonText: '<i class="fas fa-play"></i> 게임시작',
                showCancelButton: true,
                showDenyButton: true,
                html:
                    '<div class="radios">' +
                    '현재 ' + _title + '머니 : ' + numberWithComma(_get_balance) + '원' +
                    '</div>' +
                    '<div class="radios">' +
                    '   <label><input type="radio" class="swal2-radio1" id="swal-radio1" name="swal-radio" checked> 사이트 <i class="fas fa-arrow-right"></i> ' + _title + '머니</label>' +
                    '   <label><input type="radio" class="swal2-radio2" id="swal-radio2" name="swal-radio"> ' + _title + '머니 <i class="fas fa-arrow-right"></i> 사이트</label>' +
                    '</div>' +
                    '<div class="rangs">' +
                    '   <output id="range_val">전환머니 ' + numberWithComma(parseInt(userData.cash / 100) * 100) + '원</output>' +
                    '   <input id="swal-input2" type="range" class="swal2-input" name="swal-range" max="' + userData.cash + '" value="' + userData.cash + '" oninput="range_val.value = `전환머니 ` + numberWithComma(this.value)+ `원`">' +
                    '</div>',
                focusConfirm: false,
                customClass: {
                    container: 'casino-transition',
                    icon: 'no-border'
                },
                willOpen: () => {
                    const inputRadio1 = Swal.getHtmlContainer().querySelector('#swal-radio1')
                    const inputRadio2 = Swal.getHtmlContainer().querySelector('#swal-radio2')
                    const inputRange = Swal.getHtmlContainer().querySelector('#swal-input2')
                    const range_val = Swal.getHtmlContainer().querySelector('#range_val')

                    inputRadio1.addEventListener('change', () => {
                        if(inputRadio1.checked){
                            inputRange.max = userData.cash
                            inputRange.value = userData.cash
                            range_val.value = '전환머니 ' + numberWithComma(parseInt(userData.cash / 100) * 100) + '원'
                        }
                    })
                    inputRadio2.addEventListener('change', () => {
                        if(inputRadio2.checked){
                            inputRange.max = _get_balance
                            inputRange.value = _get_balance
                            range_val.value =  '전환머니 ' + numberWithComma(parseInt(_get_balance / 100) * 100) + '원'
                        }
                    })
                },
                preConfirm: () => {
                    return [
                        document.getElementById('swal-radio1').checked ? 1 : 2,
                        document.getElementById('swal-input2').value
                    ]
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    CasinoExchangeCash(_id, { kind : result.value[0], cash: result.value[1] });
                } else if (result.isDenied) {
                    CasinoJoinLink(_id, { kind : result.value[0], cash: result.value[1] });
                }
            })
        }
    }
    else{
        CasinoJoinLink(_id);
    }
}

window.CasinoJoinLink = function(_id, _extra = null){
    showLoading();
    var winRef = window.open("","_blank");
    axios.post(`/casino/join/${_id}`, _extra)
    .then((response)=>{
        if(response.data.result == 0){
            winRef.close()
            Swal.fire({icon: 'error', title: response.data.msg})
        }
        else if(response.data.result == 1){
            if(response.data.link)
                winRef.location = response.data.link;
        }
    })
    .finally(() => {
        hideLoading();
    });
},

window.CasinoExchangeCash = function(_id, _extra = null){
    showLoading();
    axios.post(`/casino/exchange/${_id}`, _extra)
    .then((response)=>{
        if(response.data.result == 0){
            Swal.fire({icon: 'error', title: response.data.msg})
        }
        else if(response.data.result == 1){
            Swal.fire({icon: 'success', title: response.data.msg, timer: 1500})
            .then(() => {
                if(_extra.kind == 1)
                    CasinoGetLink(_id);
            })
        }
    })
    .finally(() => {
        hideLoading();
    });
},

window.CasinoGetCash = async function(_id){
    showLoading();
    let _ret = null
    await axios.get(`/casino/balance/${_id}`)
    .then((response)=>{
        if(response.data.result == 0){
            Swal.fire({icon: 'error', title: response.data.msg})
        }else if(response.data.result == 1){
            _ret = response;
        }
    })
    .finally(() => {
        hideLoading();
    });
    if(_ret.status == 200 && _ret.data.result == 1)
        return _ret.data.balance;
},

window.SlotJoinLink = function(_id, _extra = null){
    showLoading();
    var winRef = window.open("","_blank");
    axios.post(`/slot/join/${_id}`, _extra)
    .then((response)=>{
        if(response.data.result == 0){
            winRef.close()
            Swal.fire({icon: 'error', title: response.data.msg})
        }
        else if(response.data.result == 1){
            if(response.data.link)
                winRef.location = response.data.link;
        }
    })
    .finally(() => {
        hideLoading();
    });
}
