<template>
    <div>
        <a :href="url" class="title">
            <span>{{ title }}</span> <b>바로가기</b>
        </a>
        <!-- <a class="close" @click="props.close">
            <i class="fa fa-fw fa-close"></i>
        </a> -->
    </div>
</template>

<script>
    export default {
        props: ['title', 'url'],
    };
</script>
