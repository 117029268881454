window.addCart = function (game, betTeam, gameList) {

    if(isAuth){
        let _odds;
        if(betTeam == 1)
            _odds = game.oddsHome;
        else if(betTeam == 2)
            _odds = game.oddsDraw;
        else if(betTeam == 3)
            _odds = game.oddsAway;

        if(_odds == null || _odds == 0)
            return;

        let path = location.pathname.split('/')[1];

        console.log(gameList.sportID.toString().length);

        if(_.some(cartData.cart, { gameID : game.ID, betTeam : betTeam })){

            const idx = cartData.cart.findIndex((cart) => cart.gameID == game.ID);
            if (idx > -1)
                cartData.cart.splice(idx, 1);

            if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay'){
                axios.delete(`/cart/${path}/${game.ID}`)
                .then((response)=>{
                    //this.console.log(response);
                })
                withDelete = true;
            }
            return;
        }
        else if(_.some(cartData.cart, { gameID : game.ID })){

            const idx = cartData.cart.findIndex((cart) => cart.gameID == game.ID);
            if (idx > -1)
                cartData.cart.splice(idx, 1);

            if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay'){
                axios.delete(`/cart/${path}/${game.ID}`)
                .then((response)=>{
                    //this.console.log(response);
                })
            }
        }
        else if(gameList.sportID.toString().length == 3 && gameList.sportID.toString()[0] == '6'){ //미니게임일때
            cartData.cart.splice(0, cartData.cart.length);
        }

        if(!addCartArrow(game, betTeam, gameList))
        {

        }
        else
        {
            if(_.some(cartData.cart, { gameListID : gameList.ID, betKind : game.betKind })){
                const idx = cartData.cart.findIndex((cart) => cart.gameID == game.ID);
                if (idx > -1)
                    cartData.cart.splice(idx, 1);
            }
            cartData.cart.push({ gameListID : gameList.ID, sportID : gameList.sportID, sportSubID : gameList.sportSubID, gameID : game.ID, game : game, homeName : gameList.homeName, awayName : gameList.awayName, betTeam : betTeam, odds : _odds});

            let path = location.pathname.split('/')[1];
            if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay'){

                axios.post(`/cart/${path}`, { gameListID: gameList.ID, gameID: game.ID, betTeam : betTeam, odds : _odds})
                .then((response)=>{
                    //this.console.log(response);
                    if(response.data.result == 1){
                        //cartData.cart.push(response.data.data);
                    }
                    else if(response.data.result == 0){
                        Swal.fire({icon: 'error', title: response.data.msg});
                        if(response.data.action == "delete"){
                            response.data.data.forEach(value => {
                                const idx = cartData.cart.findIndex((_cart) => _cart.gameID == value);
                                if (idx > -1)
                                    cartData.cart.splice(idx, 1);
                            });
                        }

                        if(response.data.closeData){
                            response.data.closeData.forEach(value => {
                                const idx = data.data.findIndex((_data) => _data.ID == value);
                                if (idx > -1)
                                    data.data.splice(idx, 1);
                            });
                        }
                    }
                })
            }

        }
    }else{
        Swal.fire({icon: 'error', title: '로그인후 이용 가능합니다.'})
    }

}

window.addCartArrow = function (game, betTeam, gameList) {
    let path = location.pathname.split('/')[1];

    if(path == 'overseas' || path == 'inplay' || path == 'minigame'){
        if(_.some(cartData.cart, { gameListID : gameList.ID })){
            Swal.fire({icon: 'error', title: '동일 경기는 중복 배팅이 불가합니다.'})
            return false;
        }
    }else if(path == 'cross' || path == 'special' || path == 'special2') {
        if(_.some(cartData.cart, { gameListID : gameList.ID })){

            let getGameCombis = _.filter(gameCombis, { type : 2, sportID: gameList.sportID });
            let getCarts = _.filter(cartData.cart,  { gameListID : gameList.ID });

            if(getGameCombis){
                let return0 = _.some(getCarts, (_cart) => {
                    let return2 = _.some(getGameCombis, (_gameCombi) => {
                        if(_gameCombi.bet1 && _gameCombi.bet2){
                            if(_gameCombi.bet1.kind == game.betKind.toString().slice(-2) && (_gameCombi.bet2.kind == _cart.game.betKind.toString().slice(-2)
                                || (_gameCombi.bet2.kind.length == 1 && _gameCombi.bet2.kind == _cart.game.betKind.toString().slice(0, 1)))){
                                if((_gameCombi.bet1.draw == true || betTeam != 2) && (_gameCombi.bet2.draw == true || _cart.betTeam != 2))
                                    return true;
                            }else if(_gameCombi.bet1.kind == _cart.game.betKind.toString().slice(-2) && (_gameCombi.bet2.kind == game.betKind.toString().slice(-2)
                                || (_gameCombi.bet2.kind.length == 1 && _gameCombi.bet2.kind == game.betKind.toString().slice(0, 1)))){
                                if((_gameCombi.bet2.draw == true || betTeam != 2) && (_gameCombi.bet1.draw == true || _cart.betTeam != 2))
                                    return true;
                            }
                        }
                    });
                    if(!return2)
                        return true;
                });

                if(!return0)
                    return true;
                else{
                    let getCombiMsg = _.find(gameCombisMsg, { type : 2, sportID: gameList.sportID });
                    if(getCombiMsg){
                        Swal.fire({icon: 'error', title: getCombiMsg.msg})
                        return false;
                    }
                }
            }

            Swal.fire({icon: 'error', title: '동일 게임은 묶음 배팅 할 수 없습니다.'})
            return false;
        }
    }

    return true;
}

window.gameCombis = [
    // { type : 2, sportID : 1, bet1 : { kind : '08', draw : true}, bet2 : { kind : '01', draw : false}},
    { type : 2, sportID : 21, bet1 : { kind : '08', draw : true}, bet2 : { kind : '01', draw : true}},
    { type : 2, sportID : 21, bet1 : { kind : '08', draw : true}, bet2 : { kind : '06', draw : true}},
    { type : 2, sportID : 21, bet1 : { kind : '08', draw : true}, bet2 : { kind : '9', draw : true}},
    { type : 2, sportID : 2, bet1 : { kind : '08', draw : true}, bet2 : { kind : '04', draw : true}},
    { type : 2, sportID : 2, bet1 : { kind : '08', draw : true}, bet2 : { kind : '06', draw : true}},
    { type : 2, sportID : 2, bet1 : { kind : '08', draw : true}, bet2 : { kind : '9', draw : true}},
    { type : 2, sportID : 3, bet1 : { kind : '08', draw : true}, bet2 : { kind : '04', draw : true}},
    { type : 2, sportID : 3, bet1 : { kind : '08', draw : true}, bet2 : { kind : '06', draw : true}},
    { type : 2, sportID : 3, bet1 : { kind : '08', draw : true}, bet2 : { kind : '9', draw : true}},
    { type : 2, sportID : 25, bet1 : { kind : '08', draw : true}, bet2 : { kind : '04', draw : true}},
    { type : 2, sportID : 25, bet1 : { kind : '08', draw : true}, bet2 : { kind : '06', draw : true}},
    { type : 2, sportID : 25, bet1 : { kind : '08', draw : true}, bet2 : { kind : '9', draw : true}},
];

window.gameCombisMsg = [
    { type : 2, sportID : 1, msg: '동일경기 조합배팅이 불가합니다.'},
    { type : 2, sportID : 21, msg: '언더오버는 승무패, 핸디캡만 조합 가능합니다.'},
    { type : 2, sportID : 2, msg: '언더오버는 승패, 핸디캡만 조합 가능합니다.'},
    { type : 2, sportID : 3, msg: '언더오버는 승패, 핸디캡만 조합 가능합니다.'},
    { type : 2, sportID : 25, msg: '언더오버는 승패, 핸디캡만 조합 가능합니다.'},
];

window.getHangulLength = function(str, length) {
    var len = 0;
    for (var i = 0; i < str.length && i < length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }
    }
    return len;
}

window.strEllipsis = function(str, length){

    if(str.length + getHangulLength(str, length) > length)
    {
        let han_len = getHangulLength(str, length);
        let sub_len = Math.trunc(han_len / 2);
        return str.substring(0, length - sub_len) + "..";
    }
    return str;
}

Array.prototype.multiply = function (prop) {
    var total = 1
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
        total *= this[i][prop]

    }
    var total2 = total.toFixed(3) * 1;
    return total2.toFixed(2);
}

window.numberWithComma = function (str) {
    if(str){
        str = String(str);
        return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    }else{
        return 0;
    }
}

// 콤마( , )가 들어간 값에 콤마를 제거하는 함수
window.numberRemoveComma = function (str) {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
}


window.getStartTime = function(_startTime, _sportID, _plusSec){
    if(_plusSec && (_sportID == 6 || _sportID == 9 || _sportID == 16))
        return moment(_startTime).add(_plusSec, 'seconds').format('MM-DD HH:mm');
    else
        return moment(_startTime).format('MM-DD HH:mm');
}

window.getStartTimeY = function(_startTime, _sportID, _plusSec){
    if(_plusSec && (_sportID == 6 || _sportID == 9 || _sportID == 16))
        return moment(_startTime).add(_plusSec, 'seconds').format('YYYY-MM-DD HH:mm');
    else
        return moment(_startTime).format('YYYY-MM-DD HH:mm');
}

window.datetimeToMDHM = function(_startTime){
    // var d = new Date(_startTime),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         hour = '' + d.getHours(),
    //         minute = '' + d.getMinutes();

    // if (month.length < 2)
    //     month = '0' + month;
    // if (day.length < 2)
    //     day = '0' + day;
    // if (hour.length < 2)
    //     hour = '0' + hour;
    // if (minute.length < 2)
    //     minute = '0' + minute;

    // return [month, day].join('-') + " " + [hour, minute].join(':');
    return moment(_startTime).format('MM-DD HH:mm');
}

window.datetimeToYMDHM = function(_startTime){
    // var d = new Date(_startTime),
    //         year = '' + (d.getFullYear()),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         hour = '' + d.getHours(),
    //         minute = '' + d.getMinutes();

    // if (month.length < 2)
    //     month = '0' + month;
    // if (day.length < 2)
    //     day = '0' + day;
    // if (hour.length < 2)
    //     hour = '0' + hour;
    // if (minute.length < 2)
    //     minute = '0' + minute;

    // return [year, month, day].join('-') + " " + [hour, minute].join(':');
    return moment(_startTime).format('YYYY-MM-DD HH:mm');
}

window.datetimeToYMDHMS = function(_startTime){
    // var d = new Date(_startTime),
    //         year = '' + (d.getFullYear()),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         hour = '' + d.getHours(),
    //         minute = '' + d.getMinutes(),
    //         second = '' + d.getSeconds();

    // if (month.length < 2)
    //     month = '0' + month;
    // if (day.length < 2)
    //     day = '0' + day;
    // if (hour.length < 2)
    //     hour = '0' + hour;
    // if (minute.length < 2)
    //     minute = '0' + minute;
    // if (second.length < 2)
    //     second = '0' + second;

    // return [year, month, day].join('-') + " " + [hour, minute, second].join(':');
    return moment(_startTime).format('YYYY-MM-DD HH:mm:ss');
}

window.getParam = function(sname) {
    var params = location.search.substr(location.search.indexOf("?") + 1);
    var sval = "";
    params = params.split("&");
    for (var i = 0; i < params.length; i++) {
        temp = params[i].split("=");
        if ([temp[0]] == sname) { sval = temp[1]; }
    }
    return sval;
}

window.showLoading = function () {
    $('.loading-wrap').show();
}

window.hideLoading = function () {
    $('.loading-wrap').hide();
}

window.OverseasSportIDs = {
    0: '전체',
    1: '축구',
    2: '야구',
    3: '농구',
    4: '배구',
    6: '탁구',
    9: '테니스',
    12: '핸드볼',
    16: 'UFC',
    18: '미식축구',
    21: '아이스하키',
    25: 'E스포츠',
}

window.CrossSportIDs = {
    0: '전체',
    1: '축구',
    2: '야구',
    3: '농구',
    4: '배구',
    6: '탁구',
    9: '테니스',
    12: '핸드볼',
    16: 'UFC',
    18: '미식축구',
    21: '아이스하키',
    25: 'E스포츠',
}

window.InplaySportIDs = {
    0: '전체',
    1: '축구',
    2: '야구',
    3: '농구',
    4: '배구',
    21: '아이스하키',
}


window.WGameBetKind = {
    1: '해외형',
    2: '국내형',
    3: '스페셜',
    4: '스페셜2',
    12: '미니게임',
    15: '스카이파크',
    16: '카지노/슬롯',
    21: '인플레이',
}

