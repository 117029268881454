<template>
    <div class="slick-sport">
        <button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>
        <button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>
        <slick ref="slick" :options="slickOptions" class="menu-slider">
            <!-- <a :href="_get_href(0)" :class="_get_active(0)"><i class="fas fa-globe-americas"></i><span>전체</span></a> -->
            <template v-for="(sportName, index) in _sportsIDs">
                <a v-if="_is_Show(index)" :key="index" :href="_get_href(index)" :class="_get_active(index)">
                    <div class="slick-item">
                        <span class="slick-imgwrap"><img v-if="index != 0" :src="'/img/icon/sport/'+index+'.png'"><template v-else>ALL</template></span>
                        <span class="sport-name">{{ sportName }}</span>
                        <span class="sports-count">{{ _showCountSum(index) }}</span>
                    </div>
                </a>
            </template>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        data: function () {
            return {
                slickOptions: {
                    slidesToShow: 12,
                    slidesToScroll: 12,
                    variableWidth: true,
                    infinite: false,
                    nextArrow: '.slick-next',
                    prevArrow: '.slick-prev',
                    responsive: [
                        {
                            breakpoint: 1650,
                            settings: {
                                variableWidth: true,
                                slidesToShow: 6,
                                slidesToScroll: 5,
                            }
                        }
                    ],
                }, sidebar : sidebar.menus
            }
        },
        components: {
            Slick
        },
        computed: {
            _sportsIDs: function(){
                if(location.pathname.split('/')[1] == 'overseas')
                    return OverseasSportIDs;
                else if(location.pathname.split('/')[1] == 'inplay')
                    return InplaySportIDs;
                else
                    return CrossSportIDs;
            },
            _sports: function(){

                let _temp = { 0 : [],1 : [], 2 : [], 3 : [], 4 : [], 6 : [], 9 : [], 12 : [], 16 : [], 18 : [], 21 : [], 25 : []};

                let _filter = null;
                if(location.pathname.split('/')[1] == 'overseas')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 1; });
                else if(location.pathname.split('/')[1] == 'cross')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 2; });
                else if(location.pathname.split('/')[1] == 'special')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 3; });
                else if(location.pathname.split('/')[1] == 'special2')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 4; });
                else if(location.pathname.split('/')[1] == 'inplay')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 21; });

                let _menus = _.groupBy(_filter, function(side) { return side.sportID; });
                // if(!_.includes(_menus, 9))
                //     _menus.push(9);
                return _.assign(_temp, _menus);
            },
            allCount: function () {
                let _filter = null;
                if(location.pathname.split('/')[1] == 'overseas')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 1; });
                 if(location.pathname.split('/')[1] == 'cross')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 2; });
                 if(location.pathname.split('/')[1] == 'special')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 3; });
                 if(location.pathname.split('/')[1] == 'special2')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 4; });
                if(location.pathname.split('/')[1] == 'inplay')
                    _filter = _.filter(this.sidebar, function(item) { return item.gameType == 21; });

                 return _.sumBy(_filter, function(sport) { return sport.count; })
            },

        },
        methods: {
            _showCountSum: function (_index) {
                if(_index == 0)
                    return this.allCount;
                else
                    return _.sumBy(this._sports[_index], function(sport) { return sport.count; })
            },
            _get_href: function(_path) {
                if(_path == 0)
                    return '/' + location.pathname.split('/')[1];
                else
                    return '/' + location.pathname.split('/')[1] + '/' + _path;
            },
            _get_active: function(_path) {
                if(_path != 0)
                    return location.pathname.split('/')[2] == _path ? 'active' : '';
                else
                    return location.pathname.split('/').length == 2 ? 'active' : '';
            },
            _is_Show: function(_index) {
                let _noSports = NoSports.split(',');
                if(_noSports.includes(_index))
                    return false;
                return true;
            },
        },
        mounted: function(){

            let path = location.pathname.split('/')[2];
            let index = 0;
            if(path == 3)
                index = 3;
            else if(path == 4)
                index = 4;
            else if(path == 6)
                index = 5;
            else if(path == 9)
                index = 6;
            else if(path == 12)
                index = 7;
            else if(path == 16)
                index = 8;
            else if(path == 18)
                index = 9;
            else if(path == 21)
                index = 10;
            else if(path == 25)
                index = 11;
            this.$refs.slick.goTo(index);
        },
        created: function () {

        }
    }
</script>
