<template>
   <div class="isMobile">

        <div @click="userinfoShow = !userinfoShow" class="mobile-cart-button">
            <!-- <div class="cart-count">{{ _cartCount }}</div> -->
           <i class="fas fa-user"></i>
        </div>

        <transition name="slide-cart">
            <div v-show="userinfoShow" class="mobile-side-cart-wrap" @click="userinfoShow = false">
                <div class="mobile-side-cart" @click.stop="">
                   <sidebar-userinfo  is-mobile="true"></sidebar-userinfo>
                </div>
            </div>
        </transition>

        <!-- <transition name="slide-userinfo">
            <div v-show="userinfoShow" class="mobile-side-userinfo-wrap" @click="userinfoShow = false">
                <div class="mobile-side-userinfo" @click.stop="">
                    <sidebar-userinfo></sidebar-userinfo>
                </div>
            </div>
        </transition> -->
<!--
         <transition name="bottom-button">
            <div v-show="cartBottomButtonVisible" @click="userinfoShow = !userinfoShow" class="mobile-cart-bottom-button">
                <div class="cart-count">{{ _cartCount }}</div>
               <i class="fas fa-address-book"></i>
            </div>
        </transition> -->
     <!-- <div @click="cartShow = !cartShow" class="mobile-cart-button">
            <div class="cart-count">{{ _cartCount }}</div>
            <i class="fas fa-shopping-cart"></i>
        </div>

        <transition name="slide-cart">
            <div v-show="cartShow" class="mobile-side-cart-wrap" @click="cartShow = false">
                <div class="mobile-side-cart" @click.stop="">
                    <cart></cart>
                </div>
            </div>
        </transition>

         <transition name="bottom-button">
            <div v-show="cartBottomButtonVisible" @click="cartShow = !cartShow" class="mobile-cart-bottom-button">
                <div class="cart-count">{{ _cartCount }}</div>
                <i class="fas fa-shopping-cart"></i>
            </div>
        </transition> -->

    </div>
</template>

<style>
    .slide-cart-enter-active{
        transition: transform .2s ease-out;
    }
    .slide-cart-leave-active{
        transition: transform .2s ease-out;
    }
    .slide-cart-enter, .slide-cart-leave-to{
        width: 100%;
    }

    .slide-cart-enter-active .mobile-side-cart{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-cart-leave-active .mobile-side-cart{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-cart-enter .mobile-side-cart, .slide-cart-leave-to .mobile-side-cart{
        transform: translateX(260px);
    }

    .bottom-button-enter-active{
        transition: opacity .3s ease-out;
    }
    .bottom-button-leave-active{
        transition: opacity .3s ease-out;
    }
    .bottom-button-enter, .bottom-button-leave-to{
        opacity: 0;
    }
</style>

<script>
    export default {
        data: function () {
            return {
                cartShow : false, cart: cartData.cart, cartBottomButtonVisible : false, userinfoShow: false,
            }
        },
        computed: {
             _cartCount: function(){
                 return this.cart.length;
             }
        },
        methods: {
            handleScroll (event) {
                // console.log(window.scrollY);
                if(window.scrollY > 70)
                    this.cartBottomButtonVisible = true;
                else
                    this.cartBottomButtonVisible = false;
            },
        },
        components: {
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>
