<template>
<div class="">
    <!-- <div class="box-header"><i class="fas fa-gamepad"></i> 미니게임 - {{ _title }}</div> -->
    <div class="minigame-menu">
        <div class="minigame-main-menu">
            <ul>
                <li :class="{active: _mainMenu == 'eos'}">
                    <a href="/minigame/eos_powerball5"><img src="/img/icon/sport/eos.svg" width="14" /> EOS파워볼</a>
                </li>
                <li :class="{active: _mainMenu == 'ntry'}">
                    <a href="/minigame/powerball"><img src="/img/icon/sport/ntry.png" width="18" /> 엔트리</a>
                </li>
                <li v-if="ROULETTE_ENABLE" :class="{active: _mainMenu == 'powerballspeed'}">
                    <a href="/minigame/roulette"><img src="/img/icon/sport/powerballspeed.png" width="20" /> 스피드 룰렛</a>
                </li>
            </ul>
        </div>
        <div class="minigame-sub-menu">
            <ul v-if="_mainMenu == 'eos'" class="eos">
                <li :class="{active: _path2 == 'eos_powerball5'}"><a href="/minigame/eos_powerball5">5분 파워볼</a></li><!--
                --><li :class="{active: _path2 == 'eos_powerball3'}"><a href="/minigame/eos_powerball3">3분 파워볼</a></li><!--
                -->
                <!-- <li :class="{active: _path2 == 'speedkeno'}"><a href="/minigame/eos_speedkeno5">EOS 스피드키노</a></li> -->
            </ul>
            <ul v-if="_mainMenu == 'ntry'" class="ntry">
                <li :class="{active: _path2 == 'powerball'}"><a href="/minigame/powerball">파워볼</a></li><!--
                --><li :class="{active: _path2 == 'power_ladder'}"><a href="/minigame/power_ladder">파워사다리</a></li><!--
                --><li :class="{active: _path2 == 'speedkeno'}"><a href="/minigame/speedkeno">스피드키노</a></li><!--
                --><li :class="{active: _path2 == 'keno_ladder'}"><a href="/minigame/keno_ladder">키노사다리</a></li>
            </ul>
            <ul v-else-if="_mainMenu == 'livescore'" class="livescore">
                <li :class="{active: _path2 == 'powerball_freekick'}"><a href="/minigame/powerball_freekick">파워프리킥</a></li><!--
                --><li :class="{active: _path2 == 'speedkeno_homerun'}"><a href="/minigame/speedkeno_homerun">스피드홈런</a></li>
            </ul>
        </div>
    </div>
    <div class="live_frame_wrap">
        <iframe id="liveFrame" name="liveFrame" ref="liveFrame" :src="_iframe_src" :width="_iframe_width " :height="_iframe_height" scrolling="no" frameborder="0"></iframe>
    </div>

    <div v-if="data.gameLists" class="minigame">
        <div class="gamelist mini-game-list">

            <div class="league">
                <img width="12" :src="`/img/icon/sport/${_mainMenu}.png`" />
                <span>
                {{ _title }}
                    <i class="fas fa-angle-double-right"></i>
                    <span>{{ dateTime }} - {{ data.gameLists.sportSubID }} 회차</span>
                </span>
                <span @click="_get_gameList(true)" class="refresh">
                    <i class="fas fa-sync-alt"></i>
                        새로고침
                </span>
            </div>

            <GameBetList v-for="bet in betGroup" :key="bet.betKind" :data="bet" :gameList="data.gameLists" :isMainBet="false" :showBetName="false" :iconType="2"></GameBetList>
        </div>
    </div>
    <div class="minigame-beting-cart">
        <div class="minigame-cart-title">
            미니게임 배팅카트
            <span class="title">
                보유머니
                 <span class="now-cash">{{ _showCash(myData.cash) }}</span>
            </span>

        </div>
        <div class='minigame-info1'>
            <div class="minigame-title">
                <span>
                    {{ _title }}
                </span>
                <span class="betting-info">
                    게임선택
                    <b>{{ _cartOddsName }}</b>
                </span>
            </div>
            <div class="bet_info">
                <div class="dividend">배당
                    <span>
                        <b class="minigame-odds">{{ _odds }}</b>
                    </span>
                </div>
                <div class="div_pay">
                    적중금액
                    <span >{{ _showCash(_result_cash.toFixed(0)) }}원</span>
                </div>
            </div>
            <div class="bet_cash_box">
                배팅금액
                <div class="input_area">
                    <money-input type="text" :value-range="{max : myData.cash }" class="minigame-bet-cash" v-model="cartData.bet_cash" placeholder="0" >
                    </money-input>
                </div>
            </div>
        </div>
        <div class="minigame-info2">
            <div class="betting-info">
                <span>
                    게임선택
                </span>
                <b>{{ _cartOddsName }}</b>
            </div>
            <div class="plus-btns">
                <div><button  class="bet-plus-btn" type="button" @click="plusBetCash(5000)">+5,000</button></div>
                <div><button class="bet-plus-btn" type="button" @click="plusBetCash(10000)" >+10,000</button></div>
                <div><button class="bet-plus-btn" type="button" @click="plusBetCash(100000)" >+100,000</button></div>
                <div><button class="bet-plus-btn" type="button" @click="plusBetCash(300000)" >+300,000</button></div>
                <div><button class="bet-plus-btn" type="button" @click="plusBetCash(500000)" >+500,000</button></div>
                <div><button class="bet-plus-btn" type="button" @click="plusBetCash(1000000)">+1,000,000</button></div>
                <div><button class="bet-plus-btn" @click="cartData.bet_cash = 0">초기화</button></div>
                <div><button class="bet-plus-btn" type="button" @click="cartData.bet_cash = myData.cash">올인</button></div>
            </div>
            <button class="minigame-bet-btn" @click="exec_bet" >배팅하기</button>
        </div>
    </div>

    <MiniGameBetLists ref="betList" />
</div>
</template>

<script>
    import MiniGameBetLists from './BetLists';

    export default {
        data: function () {
            return {
                data, myData : userData, cartData : cartData, timer: null,
                ROULETTE_ENABLE,
            }
        },
        computed: {
            betGroup: function(){
                return _.chain(this.data.gameLists.games)
                // Group the elements of Array based on `color` property
                .groupBy("betKind")
                // `key` is group's name (color), `value` is the array of objects
                .map((value, key) => ({ betKind: key, games: value }))
                .sortBy([function(o) { return _.head(o.games).sort; }])
                .value();
            },
             _result_cash: function(){
                if(this.cartData.bet_cash == '' || !this._odds)
                    return 0;
                else if(Number.isInteger(this.cartData.bet_cash))
                    return this._odds * this.cartData.bet_cash;
                else
                    return this._odds * parseFloat(this.cartData.bet_cash.replaceAll(',', ''));
            },
            cdata: function () {
                if(cartData.cart.length > 0)
                    return cartData.cart[0];
            },
            _cartOddsName: function () {
                if(this.cdata)
                    return getOddsName(this.cdata.sportID, this.cdata.game.betKind, this.cdata.betTeam, '', '', this.cdata.game.point, this.cdata.game.etc);
            },
            _odds: function(){
                if(this.cdata)
                    return this.cdata.odds;
            },
            dateTime: function () {
                return datetimeToMDHM(this.data.gameLists.startTime);
            },
            _title: function(){
                let path = location.pathname.split('/')[2];
                return getMiniGameName(path, '');
            },
            _path2: function(){
                return location.pathname.split('/')[2];
            },
            _mainMenu: function(){
                let path = location.pathname.split('/')[2];

                if(path == 'eos_powerball5' || path == 'eos_powerball3' || path == 'eos_speedkeno5')
                    return 'eos';
                else if(path == 'powerball' || path == 'power_ladder' || path == 'speedkeno' || path == 'keno_ladder' || path == 'powerkeno_ladder')
                    return 'ntry';
                else if(path == 'powerball_freekick' || path == 'speedkeno_homerun')
                    return 'livescore';
                else if(path == 'roulette')
                    return 'powerballspeed';
            },
            _iframe_src: function(){
                if(this._path2 == 'eos_powerball5' || this._path2 == 'eos_powerball3' || this._path2 == 'eos_speedkeno5')
                    return `https://ntry.com/scores/${this._path2.slice(0, -1)}/${this._path2.slice(-1)}min/main.php`;
                else if(this._path2 == 'power_ladder' || this._path2 == 'keno_ladder' || this._path2 == 'powerball' || this._path2 == 'speedkeno')
                    return `https://ntry.com/scores/${this._path2}/live.php`;
                else if(this._mainMenu == 'livescore')
                    return `https://livescore.co.kr/sports/score_board/new_game/game.php?t=${this._path2}&link`;
                else if(this._path2 == 'roulette')
                    return `https://powerballspeed.com/game/${this._path2}/`;
            },
            _iframe_width: function(){
                if(this._path2 == 'eos_powerball5' || this._path2 == 'eos_powerball3' || this._path2 == 'eos_speedkeno5')
                    return 830;
                else if(this._path2 == 'power_ladder' || this._path2 == 'keno_ladder' || this._path2 == 'powerball' || this._path2 == 'speedkeno')
                    return 830;
                else if(this._mainMenu == 'livescore')
                    return 854;
                else if(this._path2 == 'roulette')
                    return 840;
            },
            _iframe_height: function(){
                if(this._path2 == 'eos_powerball5' || this._path2 == 'eos_powerball3' || this._path2 == 'eos_speedkeno5')
                    return 645;
                else if(this._path2 == 'power_ladder' || this._path2 == 'keno_ladder' || this._path2 == 'powerball' || this._path2 == 'speedkeno')
                    return 645;
                else if(this._mainMenu == 'livescore')
                    return 749;
                else if(this._path2 == 'roulette')
                    return 937;
            },
        },
        methods:{
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _addCart: function (game, betTeam) {
                addCart(game, betTeam, this.data.gameLists);
            },
            _betActive: function(_gameID, _betTeam){
                return _.some(cartData.cart, { gameID : _gameID, betTeam : _betTeam });
            },
            _classBet: function(_odds){
                return _odds != null && _odds != 0;
            },
            _getOddsName: function (_game, _betTeam) {
                //getOddsName = function (sportID, betKind, betTeam, homeName, awayName, point){
                return getOddsName(this.data.gameLists.sportID, _game.betKind, _betTeam, '홈', '원정', _game.point, _game.etc);
            },
            live_frame_wrap_resize: function(){
                // console.log($('.live_frame_wrap').width());
                if(this._iframe_width > $('.live_frame_wrap').width()){
                    let minus = this._iframe_width - $('.live_frame_wrap').width();
                    let result = 1 - (minus / this._iframe_width);
                    $('.live_frame_wrap iframe').css('transform', `scale(${result})`);
                    $('.live_frame_wrap').css('height', this._iframe_height * result);
                }
                else{
                    $('.live_frame_wrap iframe').css('transform', `scale(1.0)`);
                    $('.live_frame_wrap').css('height', this._iframe_height);
                }
            },
            plusBetCash: function (plusCash) {
                if(parseInt(this.cartData.bet_cash) + parseInt(plusCash) > parseInt(this.myData.cash))
                    this.cartData.bet_cash = this.myData.cash;
                else
                    this.cartData.bet_cash = parseInt(this.cartData.bet_cash) + parseInt(plusCash);
            },
            exec_bet: function(){
                let path = location.pathname.split('/')[2];
                if(path == 'powerball' || path == 'power_ladder' || path == 'speedkeno' || path == 'keno_ladder' || path == "eos_powerball5" || path == "eos_powerball3" || path == "eos_speedkeno5" || path == "roulette"){
                    if(this.cartData.cart != null && this.cartData.cart.length > 0)
                    {
                        Swal.fire({icon: 'warning', title: '배팅 하시겠습니까?',
                            showCancelButton: true,
                            confirmButtonColor: '#005aa7', cancelButtonColor: '#c53434',
                            confirmButtonText: '네', cancelButtonText: '아니오'
                        }).then((result) => {
                            if (result.value) {
                                showLoading();
                                // console.log({ data: this.cart })
                                axios.post(`/betting/${path}`, { data: this.cartData.cart, betCash: this.cartData.bet_cash })
                                .then((response)=>{
                                    //this.console.log(response);
                                    if(response.data.result == 0){

                                        if(response.data.odds_diff){
                                            Swal.fire({icon: 'error', title: '변경된 배당이 있습니다.'})
                                            response.data.data.forEach(value => {
                                                if(value.newOdds){
                                                    let _game = cartData.cart.find((_cart) => _cart.gameID == value.gameID);
                                                    if (_game){
                                                        if(_game.betTeam == 1)
                                                            _game.game.oddsHome = value.newOdds;
                                                        else if(_game.betTeam == 2)
                                                            _game.game.oddsDraw = value.newOdds;
                                                        else if(_game.betTeam == 3)
                                                            _game.game.oddsAway = value.newOdds;

                                                        _game.odds = value.newOdds;
                                                    }
                                                }
                                            });

                                        }else{

                                            if(response.data.action == "refresh"){
                                                Swal.fire({icon: 'error', title: response.data.msg}).then((result) => {
                                                    location.reload();
                                                });
                                            }
                                            else{
                                                Swal.fire({icon: 'error', title: response.data.msg})
                                                if(response.data.action == "delete"){
                                                    response.data.data.forEach(value => {
                                                        const idx = this.cartData.cart.findIndex((_cart) => _cart.gameID == value);
                                                        if (idx > -1)
                                                            this.cartData.cart.splice(idx, 1);
                                                    });
                                                }
                                            }
                                        }

                                    }
                                    else if(response.data.result == 1){
                                        Swal.fire({icon: 'success', title: '배팅 완료!'});
                                        this.myData.cash = parseInt(this.myData.cash) - parseInt(this.cartData.bet_cash);
                                        this.cart_clear();
                                        this.$refs.betList._exec_get();
                                    }
                                })
                                .finally(function () {
                                    hideLoading();
                                });
                            }
                        });
                    }else{
                        Swal.fire({icon: 'error', title: '배팅 장바구니가 비어 있습니다.'})
                    }
                }
            },
            cart_clear: function(){
                this.cartData.cart.splice(0, this.cartData.cart.length);
                this.cartData.bet_cash = 0;
            },
            _get_gameList: function(_frame_refresh){
                showLoading();
                if(_frame_refresh == true)
                    this.$refs.liveFrame.src = this.$refs.liveFrame.src
                this.cart_clear();
                axios.get(`${location.pathname}/gamelist`)
                .then((response)=>{
                    if(response.data.gameLists)
                        data.gameLists = response.data.gameLists;
                })
                .finally(function () {
                    hideLoading();
                });
            },
            _refresh_interval: function(){
                if(this._path2 == 'powerball' || this._path2 == 'power_ladder' || this._path2 == 'powerball_freekick'){
                    if(moment(clock).format('mm:ss').indexOf('4:45') !== -1 || moment(clock).format('mm:ss').indexOf('9:45') !== -1){
                        this._get_gameList(false);
                        this.$refs.betList._exec_get();
                    }
                }else if(this._path2 == 'speedkeno' || this._path2 == 'keno_ladder' || this._path2 == 'speedkeno_homerun'){
                    if(moment(clock).format('mm:ss').indexOf('2:15') !== -1 || moment(clock).format('mm:ss').indexOf('7:15') !== -1){
                        this._get_gameList(false);
                        this.$refs.betList._exec_get();
                    }
                }else if(this._path2 == 'eos_powerball5' || this._path2 == 'eos_speedkeno'){
                    if(moment(clock).format('mm:ss').indexOf('4:58') !== -1 || moment(clock).format('mm:ss').indexOf('9:58') !== -1){
                        this._get_gameList(false);
                        this.$refs.betList._exec_get();
                    }
                }else if(this._path2 == 'eos_powerball3'){
                    if(moment(clock).format('mm:ss').indexOf('02:58') !== -1 || moment(clock).format('mm:ss').indexOf('05:58') !== -1 || moment(clock).format('mm:ss').indexOf('08:58') !== -1 || moment(clock).format('mm:ss').indexOf('11:58') !== -1
                    || moment(clock).format('mm:ss').indexOf('14:58') !== -1 || moment(clock).format('mm:ss').indexOf('17:58') !== -1 || moment(clock).format('mm:ss').indexOf('20:58') !== -1 || moment(clock).format('mm:ss').indexOf('23:58') !== -1
                    || moment(clock).format('mm:ss').indexOf('26:58') !== -1 || moment(clock).format('mm:ss').indexOf('29:58') !== -1 || moment(clock).format('mm:ss').indexOf('32:58') !== -1 || moment(clock).format('mm:ss').indexOf('35:58') !== -1
                    || moment(clock).format('mm:ss').indexOf('38:58') !== -1 || moment(clock).format('mm:ss').indexOf('41:58') !== -1 || moment(clock).format('mm:ss').indexOf('44:58') !== -1 || moment(clock).format('mm:ss').indexOf('47:58') !== -1
                    || moment(clock).format('mm:ss').indexOf('50:58') !== -1 || moment(clock).format('mm:ss').indexOf('53:58') !== -1 || moment(clock).format('mm:ss').indexOf('56:58') !== -1 || moment(clock).format('mm:ss').indexOf('59:58') !== -1){
                        this._get_gameList(false);
                        this.$refs.betList._exec_get();
                    }
                }else if(this._path2 == 'roulette'){
                    if(this.data.gameLists){
                        if(moment(clock).format(':ss').indexOf(':06') !== -1 && moment(clock).subtract(5, 'seconds').isAfter(this.data.gameLists.startTime)){
                            this._get_gameList(false);
                            this.$refs.betList._exec_get();
                        }
                    }
                    else if(moment(clock).format(':ss').indexOf(':06') !== -1){
                        this._get_gameList(false);
                        this.$refs.betList._exec_get();
                    }
                }
            },
        },
        created: function () {
            window.addEventListener('resize',this.live_frame_wrap_resize);
            this.live_frame_wrap_resize();
            this.timer = setInterval(this._refresh_interval, 1000);
        },
        mounted: function () {
            this.live_frame_wrap_resize();
        },
        destroyed() {
            window.removeEventListener("resize", this.live_frame_wrap_resize);
            clearInterval(this.timer);
        },
        components: {
            MiniGameBetLists,
        },
    }
</script>
