<template>
    <div>
        <transition name="login-fade">
            <div v-show="code_visible" class="login-form">
                <h3>Register Code</h3>
                <div class="form-control">
                    <span><i class="fas fa-qrcode"></i></span>
                    <input v-model="form.rCode" type="text" placeholder="Code" :class="{'is-invalid': form.errors.has('rCode')}" @focus="form.errors.clear('rCode')">
                    <has-error :form="form" field="rCode"></has-error>
                </div>
                <div class="btns">
                    <button class="login" @click="_exec_code">다음</button>
                </div>
                <div class="register_wrap">
                     <button class="register" @click="_codeBack">뒤로가기</button>
                </div>
            </div>
        </transition>
        <transition name="login-fade">
            <div v-show="register_visible" class="login-form">
                <h3>Member Register</h3>
                <div class="form-control">
                    <span>아이디</span>
                    <input v-model="form.userid" type="text" placeholder="ID" :class="{'is-invalid': form.errors.has('userid')}" @focus="form.errors.clear('userid')">
                    <has-error :form="form" field="userid"></has-error>
                </div>
                <div class="form-control">
                    <span>패스워드</span>
                    <input v-model="form.password" type="password" placeholder="Password" :class="{'is-invalid': form.errors.has('password')}" @focus="form.errors.clear('password')">
                    <has-error :form="form" field="password"></has-error>
                </div>
                <div class="form-control">
                    <span>닉네임</span>
                    <input v-model="form.nickName" type="text" placeholder="Nick Name" :class="{'is-invalid': form.errors.has('nickName')}" @focus="form.errors.clear('nickName')">
                    <has-error :form="form" field="nickName"></has-error>
                </div>
                <div class="form-control">
                    <span>폰번호</span>
                    <input v-model="form.phoneNumber" type="text" placeholder="Phone Number" :class="{'is-invalid': form.errors.has('phoneNumber')}" @focus="form.errors.clear('phoneNumber')">
                    <has-error :form="form" field="phoneNumber"></has-error>
                </div>
                <div class="form-control">
                    <span>은행명</span>
                    <select v-model="form.bankName" alt="Bank Name" :class="{'no-selected' : form.bankName == '', 'is-invalid': form.errors.has('bankName')}" @focus="form.errors.clear('bankName')">
                        <option value="" selected disabled>Bank Name</option>
                        <option value="기업">기업</option>
                        <option value="국민">국민</option>
                        <option value="외환">외환</option>
                        <option value="수협">수협</option>
                        <option value="농협">농협</option>
                        <option value="우리">우리</option>
                        <option value="하나">하나</option>
                        <option value="신한">신한</option>
                        <option value="카카오뱅크">카카오뱅크</option>
                        <option value="K뱅크">K뱅크</option>
                        <option value="SC제일">SC제일</option>
                        <option value="대구">대구</option>
                        <option value="부산">부산</option>
                        <option value="광주">광주</option>
                        <option value="제주">제주</option>
                        <option value="전북">전북</option>
                        <option value="경남">경남</option>
                        <option value="산업">산업</option>
                        <option value="우체국">우체국</option>
                        <option value="수출입">수출입</option>
                        <option value="새마을금고">새마을금고</option>
                        <option value="신협">신협</option>
                        <option value="상호저축은행">상호저축은행</option>
                        <option value="한국씨티">한국씨티</option>
                        <option value="산림조합">산림조합</option>
                        <option value="외국은행">외국은행</option>
                        <option value="모간스탠리">모간스탠리</option>
                        <option value="HSBC">HSBC</option>
                        <option value="도이치은행">도이치은행</option>
                        <option value="에이비엔암로은행">에이비엔암로은행</option>
                        <option value="JP모간체이스">JP모간체이스</option>
                        <option value="미즈호코퍼레이트은행">미즈호코퍼레이트은행</option>
                        <option value="미쓰비시도쿄UFJ은행">미쓰비시도쿄UFJ은행</option>
                        <option value="B.O.A">B.O.A</option>
                    </select>
                    <has-error :form="form" field="bankName"></has-error>
                </div>
                <div class="form-control">
                    <span>계좌번호</span>
                    <input v-model="form.bankNumber" type="text" placeholder="Bank Number" :class="{'is-invalid': form.errors.has('bankNumber')}" @focus="form.errors.clear('bankNumber')">
                    <has-error :form="form" field="bankNumber"></has-error>
                </div>
                <div class="form-control">
                    <span>예금자명</span>
                    <input v-model="form.bankAccountName" type="text" placeholder="Account Name" :class="{'is-invalid': form.errors.has('bankAccountName')}" @focus="form.errors.clear('bankAccountName')">
                    <has-error :form="form" field="bankAccountName"></has-error>
                </div>
                <div class="form-control">
                    <span>환전비번</span>
                    <input v-model="form.exchangePassword" type="password" placeholder="Exchange Password" :class="{'is-invalid': form.errors.has('exchangePassword')}" @focus="form.errors.clear('exchangePassword')">
                    <has-error :form="form" field="exchangePassword"></has-error>
                </div>
                <div class="btns">
                    <button class="login" @click="_exec_register">가입하기</button>
                </div>
                <div  class="register_wrap">
                     <button class="register" @click="_cancel">취소</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<style>
.login-fade-enter-active {
    transition: all .5s ease-out;
}
.login-fade-enter {
    transform: translateY(-50px);
    opacity: 0;
}
</style>

<script>
    export default {
        data: function () {
            return {
                code_visible : true,
                register_visible : false,
                form: new Form({
                    userid: '',
                    password : '',
                    nickName : '',
                    phoneNumber : '',
                    bankName : '',
                    bankNumber : '',
                    bankAccountName : '',
                    exchangePassword : '',
                    rCode : '',
                }),
            }
        },
        computed: {

        },
        watch:{

        },
        mounted: function(){
        },
        methods: {
            _exec_code: function(){
                if(this.form.rCode.length < 4)
                    this.form.errors.set('rCode', '코드는 최소 4자 이상이어야 합니다.');
                else if(this.form.rCode.length > 255)
                    this.form.errors.set('rCode', '코드는 최소 255자 이하이어야 합니다.');
                else{
                    showLoading();

                    axios.get(`register/code?rCode=${this.form.rCode}`)
                    .then((response)=>{
                        //console.log(response);
                        if(response.data.result == 0){
                            this.form.errors.set('rCode', '존재하지 않는 코드입니다.');
                            this.code_visible = true;
                        }
                        else if(response.data.result == 1){
                            this.code_visible = false;
                            this.register_visible = true;
                        }
                    })
                    .finally(() => {
                        hideLoading();
                    });
                }
            },
            _exec_register: function(){
                var regexID = /^[A-Za-z0-9+]*$/;

                if(this.form.userid.length < 4)
                    this.form.errors.set('userid', '아이디는 최소 4자 이상이어야 합니다.');
                else if(this.form.userid.length > 30)
                    this.form.errors.set('userid', '아이디는 최소 30자 이하이어야 합니다.');
                else if(!regexID.test(this.form.userid))
                    this.form.errors.set('userid', '아이디는 영어, 숫자만 입력 가능합니다.');
                else if(this.form.password.length < 4)
                    this.form.errors.set('password', '패스워드는 최소 4자 이상이어야 합니다.');
                else if(this.form.password.length > 30)
                    this.form.errors.set('password', '패스워드는 최소 30자 이하이어야 합니다.');
                else if(this.form.nickName.length < 3)
                    this.form.errors.set('nickName', '닉네임은 최소 3자 이상이어야 합니다.');
                else if(this.form.nickName.length > 15)
                    this.form.errors.set('nickName', '닉네임은 최소 15자 이하이어야 합니다.');
                else if(this.form.phoneNumber.length < 10)
                    this.form.errors.set('phoneNumber', '폰번호는 최소 10자 이상이어야 합니다.');
                else if(this.form.phoneNumber.length > 15)
                    this.form.errors.set('phoneNumber', '폰번호는 최소 15자 이하이어야 합니다.');
                else if(this.form.bankName.length < 2)
                    this.form.errors.set('bankName', '은행명은 최소 2자 이상이어야 합니다.');
                else if(this.form.bankName.length > 20)
                    this.form.errors.set('bankName', '은행명은 최소 20자 이하이어야 합니다.');
                else if(this.form.bankNumber.length < 8)
                    this.form.errors.set('bankNumber', '계좌번호는 최소 8자 이상이어야 합니다.');
                else if(this.form.bankNumber.length > 20)
                    this.form.errors.set('bankNumber', '계좌번호는 최소 20자 이하이어야 합니다.');
                else if(this.form.bankAccountName.length < 2)
                    this.form.errors.set('bankAccountName', '예금자명은 최소 8자 이상이어야 합니다.');
                else if(this.form.bankAccountName.length > 20)
                    this.form.errors.set('bankAccountName', '예금자명은 최소 15자 이하이어야 합니다.');
                else if(this.form.exchangePassword.length < 4)
                    this.form.errors.set('exchangePassword', '환전비번은 최소 4자 이상이어야 합니다.');
                else if(this.form.exchangePassword.length > 30)
                    this.form.errors.set('exchangePassword', '환전비번은 최소 30자 이하이어야 합니다.');
                else{
                    showLoading();

                    this.form.post(`register`)
                    .then((response)=>{
                        //console.log(response);
                        if(response.data.result == 0){
                            Swal.fire({icon: 'error', title: response.data.msg})
                        }
                        else if(response.data.result == 1){
                            Swal.fire({icon: 'info', title: '회원가입이 완료 되었습니다. 관리자 승인 후 로그인 가능합니다.'})
                            this._codeBack();
                        }
                    })
                    .finally(() => {
                        hideLoading();
                    });
                }
            },
            _codeBack: function(){
                this.$emit('registerBack');
            },
            _cancel: function(){
                this.code_visible = true;
                this.register_visible = false;
            },
        },
        mounted () {
            let _code = '';
            if(window.location.host == "mz-11.com"){
                _code = '3579';
            }else{
                let urlParams = new URLSearchParams(window.location.search);
                _code = urlParams.get('r');
            }

            if(_code){
                this.code_visible = false;
                this.form.rCode = _code;
                this._exec_code();
            }
        },
        beforeDestroy () {

        },
        components: {
        },
    }
</script>
