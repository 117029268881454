<template>

<div class="cart-box">

    <div class="box-header"><i class="fas fa-shopping-cart"></i>betting cart</div>
    <div class="box-content">
        <div class="cart-list">
            <ul>
                <CartList v-for="(d) in cartData.cart" :key="d.gameID" :cdata="d" v-on:changeOdds="_showChangeOdds"></CartList>
            </ul>
        </div>
        <div v-show="_bonus_text" class="bonus">
            <span>{{ _bonus_text }}</span>
        </div>
        <div v-show="_show_no_folder" class="no-folder">
            <span>1.30 배당 이하의 경기는 폴더로 인정되지 않습니다.</span>
        </div>

        <transition name="fade">
            <div v-show="showChangeOdds" class="cart-change-odds">
                <i @click="showChangeOdds = false" class="fas fa-times"></i> 변경된 배당이 있습니다.
            </div>
        </transition>
        <div class="cart-etc">
            <div class="cart-cash">
                <div>
                    <p>
                        보유머니
                        <span class="cart-right">{{ _cash }} 원</span>
                    </p>
                </div>
                <div>
                    <p>
                        배당금액
                        <input type="number" ref="bet_cash_input" class="cart-right cart-input" @input="updateBetCash($event.target.value)" placeholder="0" :value="cartData.bet_cash == 0 ? '' : cartData.bet_cash">
                    </p>
                </div>
                <div>
                    <p>
                        총 배당률
                        <span  class="cart-right">X {{ _total_odds }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        예상 당첨금
                        <span  class="cart-right">{{ _total_prize }}</span>
                    </p>
                </div>
            </div>
            <div class="price-control clearfix">
                <div class="clearfix">
                    <button @click="plusBetCash(5000)">+5,000</button>
                    <button @click="plusBetCash(30000)">+30,000</button>
                </div>
                <div>
                    <button @click="plusBetCash(50000)">+50,000</button>
                    <button @click="plusBetCash(100000)">+100,000</button>
                </div>
                <div>
                    <button @click="plusBetCash(500000)">+500,000</button>
                    <button class="price-clear" @click="updateBetCash(0)">정정하기</button>
                </div>
            </div>
            <div>
                <button class="bet-btn" @click="exec_bet">배팅하기</button>
                <div class="max-bet">
                    <button @click="updateBetCash(-1)">최대 배팅</button>
                </div>
                <button class="clear-btn" @click="cartAllRemove">전체삭제</button>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
    import CartList from './List';

    export default {
        data: function () {
            return {
                cartData : cartData, myData : userData, showChangeOdds : false, count : 0,
            };
        },
        components: {
            CartList,
        },
        methods: {
            updateBetCash: function (value) {

                if(value == -1)
                {
                    this.cartData.bet_cash = this.myData.cash;
                }
                else if(value > this.myData.cash)
                {
                    this.cartData.bet_cash = this.myData.cash;

                    if (this.cartData.bet_cash !== value)
                        this.$refs.bet_cash_input.value = this.cartData.bet_cash;
                }
                else
                    this.cartData.bet_cash = value;
            },
            plusBetCash: function (plusCash) {
                if(parseInt(this.cartData.bet_cash) + parseInt(plusCash) > parseInt(this.myData.cash))
                    this.cartData.bet_cash = this.myData.cash;
                else
                    this.cartData.bet_cash = parseInt(this.cartData.bet_cash) + parseInt(plusCash);
            },
            cartAllRemove: function(){
                let path = location.pathname.split('/')[1];
                if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay'){
                     axios.delete(`/carts/${path}`)
                    .then((response)=>{
                        //cartData.cart = response.data;
                    })
                }
                this.cart_clear();
            },
            exec_bet: function(){
                let path = location.pathname.split('/')[1];
                if(path == 'minigame')
                    path = location.pathname.split('/')[2];
                if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2' || path == 'inplay' || path == 'powerball' || path == 'power_ladder' || path == 'speedkeno' || path == 'keno_ladder' || path == "eos_powerball5" || path == "eos_powerball3" || path == "roulette"){
                    if(this.cartData.cart != null && this.cartData.cart.length > 0)
                    {
                        Swal.fire({icon: 'warning', title: '배팅 하시겠습니까?',
                            showCancelButton: true,
                            confirmButtonColor: '#005aa7', cancelButtonColor: '#c53434',
                            confirmButtonText: '네', cancelButtonText: '아니오'
                        }).then((result) => {
                            if (result.value) {
                                showLoading();
                                // console.log({ data: this.cart })
                                axios.post(`/betting/${path}`, { data: this.cartData.cart, betCash: this.cartData.bet_cash })
                                .then((response)=>{
                                    //this.console.log(response);
                                    if(response.data.result == 0){

                                        if(response.data.odds_diff){
                                            Swal.fire({icon: 'error', title: '변경된 배당이 있습니다.'})
                                            response.data.data.forEach(value => {
                                                if(value.newOdds){
                                                    let _game = cartData.cart.find((_cart) => _cart.gameID == value.gameID);
                                                    if (_game){
                                                        if(_game.betTeam == 1)
                                                            _game.game.oddsHome = value.newOdds;
                                                        else if(_game.betTeam == 2)
                                                            _game.game.oddsDraw = value.newOdds;
                                                        else if(_game.betTeam == 3)
                                                            _game.game.oddsAway = value.newOdds;

                                                        _game.odds = value.newOdds;
                                                    }
                                                }
                                            });

                                        }else{

                                            if(response.data.action == "refresh"){
                                                Swal.fire({icon: 'error', title: response.data.msg}).then((result) => {
                                                    location.reload();
                                                });
                                            }
                                            else{
                                                Swal.fire({icon: 'error', title: response.data.msg})
                                                if(response.data.action == "delete"){
                                                    response.data.data.forEach(value => {
                                                        const idx = this.cartData.cart.findIndex((_cart) => _cart.gameID == value);
                                                        if (idx > -1)
                                                            this.cartData.cart.splice(idx, 1);
                                                    });
                                                }
                                            }
                                        }

                                    }
                                    else if(response.data.result == 1){
                                        Swal.fire({icon: 'success', title: '배팅 완료!'});
                                        this.myData.cash = parseInt(this.myData.cash) - parseInt(this.cartData.bet_cash);
                                        this.cart_clear();
                                    }
                                })
                                .finally(function () {
                                    hideLoading();
                                });
                            }
                        });
                    }else{
                        Swal.fire({icon: 'error', title: '배팅 장바구니가 비어 있습니다.'})
                    }
                }
            },
            cart_clear: function(){
                this.cartData.cart.splice(0, this.cartData.cart.length);
                this.cartData.bet_cash = 0;
            },
            _showChangeOdds: function(){
                this.$data.showChangeOdds = true;
                setTimeout(() => this.showChangeOdds = false, 10000);
            },
        },
        created: function () {
            if(this.cartData.delCount > 0){
                Swal.fire({icon: 'info', title: `마감된 경기가 있어 배팅카트에서 ${this.cartData.delCount}개가 삭제됩니다.`})
                this.cartData.delCount = 0;
            }
        },
        computed: {
            _total_odds: function(){
                this.count++;
                if(this.cartData.cart != null && this.cartData.cart.length > 0)
                {
                    let total_odds = this.cartData.cart.multiply('odds');
                    var bonusCount = _.filter(this.cartData.cart, function(_cart) { if (_cart.odds >= 1.3) return _cart }).length;
                    if(bonusCount >= 7)
                        total_odds *= parseFloat(this.cartData.bonus[7]);
                    else if(bonusCount >= 5)
                        total_odds *= parseFloat(this.cartData.bonus[5]);
                    else if(bonusCount >= 3)
                        total_odds *= parseFloat(this.cartData.bonus[3]);
                    return  parseFloat(total_odds).toFixed(2);
                }
                else
                {
                    return 0;
                }
            },
            _bonus_text: function(){
                var bonusCount = _.filter(this.cartData.cart, function(_cart) { if (_cart.odds >= 1.3) return _cart }).length;
                if(bonusCount >= 7)
                    return `7폴더 보너스 배당 x${this.cartData.bonus[7]} 적용 됩니다.`;
                else if(bonusCount >= 5)
                    return `5폴더 보너스 배당 x${this.cartData.bonus[5]} 적용 됩니다.`;
                else if(bonusCount >= 3)
                    return `3폴더 보너스 배당 x${this.cartData.bonus[3]} 적용 됩니다.`;
                return null;
            },
             _show_no_folder: function(){
                return _.some(this.cartData.cart, function(_cart) { return _cart.odds < 1.3 });
            },
            _total_prize: function(){
                // console.log(parseFloat(this.cartData.total_odds) * parseInt(this.cartData.bet_cash))
                if(this.cartData.bet_cash == "" || this.cartData.bet_cash == NaN)
                    this.cartData.bet_cash = 0;
                return numberWithComma((parseFloat(this._total_odds) * parseInt(this.cartData.bet_cash)).toFixed(0));
            },
            _cash: function(){
                return numberWithComma(this.myData.cash);
            },
        },
        mounted() {
            // if(isAuth){
            //     let path = location.pathname.split('/')[1];
            //     if(path == 'overseas' || path == 'cross' || path == 'special' || path == 'special2'){
            //         axios.get(`/cart/${path}`)
            //         .then((response)=>{
            //             if(response.data.delCount > 0)
            //                 Swal.fire({icon: 'info', title: `마감된 경기가 있어 배팅카트에서 ${response.data.delCount}개가 삭제됩니다.`})
            //             cartData.cart = response.data.cart;
            //         })
            //     }
            // }

        },
    }
</script>
