<template>
   <div class="login-box">
       <template v-if="myData.level">
            <!-- <div class="box-header-right">회원정보</div> -->
            <div class="user-info">
                <div class="mb_id_box">
                     <img class="level" :src="`/img/icon/level/${myData.level}.png`"/> <span>{{ myData.nickName }}</span>
                </div>
                <div class="money">
                    <i class="fas fa-coins"></i>보유머니
                    <span class="right-content user-cash">
                        {{ _numberWithComma(myData.cash) }} 원
                    </span>
                </div>
                <div  class="money">
                    <i class="fas fa-coins"></i>보유포인트
                    <span class="right-content">
                        {{ _numberWithComma(myData.point) }} P
                    </span>
                </div>
                <div class="btn_list_log">
                    <div OnClick="location.href ='/inquery'">
                        <a>
                            <span>쪽지함</span>
                            <span class="right-content inquery-badge">{{ myData.messageCount }}</span>
                        </a>
                    </div>
                    <div>
                        <a href="/attendance"> 출석체크</a>
                    </div>
                    <div>
                        <a href="/recommend">추천인내역</a>
                    </div>
                    <div>
                        <a href="/userinfo">마이페이지</a>
                    </div>
                    <div>
                        <a href="/bettinglist">배팅내역</a>
                    </div>
                    <div>
                        <span @click="point_exchange"> 포인트전환</span>
                    </div>
                    <!-- <div>
                        <a class="red" href="/inquery"><i for="password" class="fas fa-comment"></i> 고객센터</a>
                    </div> -->
                </div>

            </div>
            <div class="login-etc">
                 <span class="logout" onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                    <i class="fas fa-sign-out-alt"></i>로그아웃

                    <form id="logout-form" action="/logout" method="POST" style="display: none;">
                        <input type="hidden" name="_token" :value="csrf">
                    </form>
                </span>
                <a class="blue" href="/deposit"><i for="password" class="fas fa-plus-square"></i> 충전신청</a>
                <a class="red" href="/withdraw"><i for="password" class="fas fa-minus-square"></i> 환전신청</a>
            </div>
        </template>
        <template v-else>
            <div class="box-header-right">Mener Login</div>
            <div class="login-form">
                <form method="POST" action="/login">
                    <div class="form-group">
                        <input id="userid" type="text" placeholder="아이디" name="userid" required autocomplete="userid" autofocus>
                    </div>

                    <div class="form-group">
                        <input id="password" type="password" placeholder="패스워드" name="password" required autocomplete="current-password">
                    </div>

                    <!-- <div class="form-submit">
                        <button type="submit" class="btn-submit">로그인</button>
                    </div>
                    <input type="hidden" name="_token" :value="csrf"> -->
                </form>
            </div>
                <div class="form-submit">
                            <button type="submit" class="btn-submit">로그인</button>
                </div>
                <input type="hidden" name="_token" :value="csrf">
                <div class="form-submit">
                <button class="btn-submit">회원가입</button>
                <!-- <span class="red"><i for="password" class="fas fa-question"></i> 비회원문의</span> -->
            </div>
            <div class="btn_list">
                <button onclick="login_open();">쪽지 <span>00</span></button>
                <button onclick="login_open();">복권 <span>00</span></button>
                <button onclick="login_open();">룰렛 <span>00</span></button>
                <button onclick="login_open();">쿠폰 <span>00</span></button>
                <button onclick="login_open();">출석부</button>
                <button onclick="login_open();">추천인</button>
                <!--<button onClick="login_open();">1:1문의</button>
                <button onClick="location.href='/bbs/logout.php'">로그아웃</button>-->
            </div>
        </template>

    </div>
</template>


<script>
    export default {
        props: ['isMobile'],
        data: function () {
            return {
                myData : userData,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                stickyY : 0,
            }
        },
        computed: {

        },
        methods: {
           _numberWithComma: function(_number){
               return numberWithComma(_number);
           },
           point_exchange: function(){
                showLoading();
                axios.post(`point/exchange`)
                .then((response)=>{
                    if(response.data.result == 1){
                        Swal.fire({icon: 'success', title: '캐쉬로 전환 되었습니다.'});

                        if(response.data.cash)
                            userData.cash = response.data.cash;
                        if(response.data.point || response.data.point === 0)
                            userData.point = response.data.point;
                    }
                    else
                    {
                        Swal.fire({icon: 'error', title: response.data.msg});
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[001]'})
                })
                .finally(function () {
                    hideLoading();
                });
            },
            handleScroll () {
                console.log(window.scrollY);
                if (window.scrollY > this.stickyY) {
                    $('.right-sidebar').first().addClass("sticky");
                } else {
                    $('.right-sidebar').first().removeClass("sticky");
                }
            }
        },
        components: {
        },
        created () {
            if(this.isMobile == false)
                window.addEventListener('scroll', this.handleScroll);
        },
        mounted(){

            if(this.isMobile == false){
                this.handleScroll();
                // this.stickyY = $('.right-sidebar').first().position().top;
            }
        },
        destroyed () {
            if(this.isMobile == false)
                window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>
