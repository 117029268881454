<template>
   <div class="isMobile">
        <div @click="menuShow = !menuShow" class="mobile-menu-button">
            <i class="fas fa-bars"></i>
        </div>

        <transition name="slide-menu">
            <div v-show="menuShow" class="mobile-side-menu-wrap" @click="menuShow = false">
                <div class="mobile-side-menu" @click.stop="">
                    <div class="mobile-userButtom">
                        <div class="buttom-wrap">
                            <a href="/deposit" class="buttom">
                                <i for="password" class="fas fa-plus-square"></i>
                                충천신청
                            </a>
                            <a href="/withdraw" class="buttom">
                                <i for="password" class="fas fa-minus-square"></i>
                                환전신청
                            </a>
                            <a href="/bettinglist" class="buttom">
                                <i class="fas fa-list"></i>
                                배팅내역
                            </a>
                        </div>
                        <div class="buttom-wrap">
                            <a href="/inquery" class="buttom">
                                <i class="fas fa-comment"></i>
                                고객센터
                            </a>
                            <a @click="point_exchange" class="buttom">
                                <i class="fas fa-coins"></i>
                                포인트전환
                            </a>
                            <a href="/recommend" class="buttom">
                                <i class="fas fa-users"></i>
                                추천인
                            </a>
                        </div>
                    </div>
                    <ul>
                        <li v-for="(menu, key) in menus" :key="key" :class="{active: path1 == key}">
                            <a :href="menu.href"><i :class="menu.icon"></i>{{ menu.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<style>
    .slide-menu-enter-active{
        transition: transform .2s ease-out;
    }
    .slide-menu-leave-active{
        transition: transform .2s ease-out;
    }
    .slide-menu-enter, .slide-menu-leave-to{
        width: 100%;
    }

    .slide-menu-enter-active .mobile-side-menu{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-menu-leave-active .mobile-side-menu{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-menu-enter .mobile-side-menu, .slide-menu-leave-to .mobile-side-menu{
        transform: translateX(-260px);
    }
</style>

<script>
    export default {
        data: function () {
            return {
                menuShow : false,
                menus : MenuLists
            }
        },
        computed: {
            path1: function(){
                return location.pathname.split('/')[1];
            },
        },
        methods: {
            _popup_repair: function(){
                Swal.fire({icon: 'warning', title: '점검중 입니다.'})
            },
            point_exchange: function(){
                showLoading();
                axios.post(`point/exchange`)
                .then((response)=>{
                    if(response.data.result == 1){
                        Swal.fire({icon: 'success', title: '캐쉬로 전환 되었습니다.'});
                        if(response.data.cash)
                            userData.cash = response.data.cash;
                        if(response.data.point || response.data.point === 0)
                            userData.point = response.data.point;
                    }
                    else
                    {
                        Swal.fire({icon: 'error', title: response.data.msg});
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    Swal.fire({icon: 'error', title: '에러가 발생했습니다. 관리자에게 문의 바랍니다.[001]'})
                })
                .finally(function () {
                    hideLoading();
                });
            },
        },
        components: {
        },
    }
</script>
