<template>

    <div v-if="mainBet" class="gamelist" :class="data.isWaiting == true ? 'is-waiting' : ''">

        <div v-show="data.leagueName.name !== prevLeagueName || mainBetName" class="league">
            <img width="12" :src="`/img/icon/sport/${data.sportID}.png`" />{{ _showSportName(data.sportID) }}
            <i class="fas fa-angle-double-right"></i>
            <div class="flag">
                <img class="leg_img" width="18" :src="imageLeagueUrl" />
                <div  class="flag-wave"></div>
            </div>
            {{ data.leagueName.name }}
            <span v-show="mainBetName" class="main-bet-name"> [{{ mainBetName }}]</span>
        </div>

        <GameBetList v-if="mainBet" :data="mainBet" :gameList="data" :isMainBet="true" :showStartTime="true" :showEtc="true" :showGames="showBetList" @get_games="_exec_get_games" :plusSec="plusSec" :iconType="2" class="mainGameList"></GameBetList>

        <transition name="fade">
            <div class="bet-list-wrap" v-if="showBetList">
                <!-- <GameBetLists :data="data.games" :sportID="data.sportID" v-show="showBetList"></GameBetLists> -->
                <GameBetList v-for="bet in betGroup" :key="bet.betKind" :data="bet" :gameList="gameListInfo" :iconType="2"></GameBetList>
                <div class="bet-list-divider"></div>
            </div>
        </transition>
    </div>

</template>

<script>

    export default {
        props: ['data', 'prevLeagueName', 'plusSec', 'maintype'],
        data: function () {
            return {
                showBetList: false, games: [],
            }
        },
        methods: {
            _showSportName: function (_sport_id) {
                return getSportName(_sport_id);
            },
            _exec_get_games: function() {
                if(this.showBetList)
                    this.showBetList = false;
                else{
                    showLoading();
                    axios.get(`/games/${this.data.ID}`)
                        .then((response)=>{
                            //console.log(response);
                            if(response.data.result == 1){
                                this.data.games = response.data.data;
                                this.showBetList = true;
                            }else {
                                Swal.fire({icon: 'error', title: '데이터를 가져오지 못했습니다.'})
                            }

                        })
                        .finally(() => {
                            hideLoading();
                        });
                }
            },
        },
        computed: {
            mainBet: function () {
                let _betKind1 = '';
                let _betKind2 = '';
                if(this.maintype == 1){
                    _betKind1 = '01';
                    _betKind2 = '04';
                }else if(this.maintype == 2){
                    _betKind1 = '06';
                    _betKind2 = '06';
                }else if(this.maintype == 3){
                    _betKind1 = '08';
                    _betKind2 = '08';
                }
                return _.head(_.chain(_.filter(this.data.games, function(_game) { return _game.betKind.toString().substr(2, 2) == _betKind1 || _game.betKind.toString().substr(2, 2) == _betKind2; }))
                .groupBy("betKind")
                .map((value, key) => ({ betKind: key, games: value }))
                .value());
            },
            mainBetName: function(){
                if(this.mainBet.betKind == 1001 || this.mainBet.betKind == 1004)
                    return null;
                else{
                    return getBetName(this.data.sportID, this.mainBet.betKind);
                }
            },
            gameListInfo : function(){
                return _.pull(this.data, 'games');
            },
            imageLeagueUrl: function(){
                return '/img/flag/league/' + ((this.data.leagueName.flag == null) ? 'world.svg' : this.data.leagueName.flag);
            },
            betGroup: function(){
                return _.chain(_.reject(this.data.games, {'betKind' : this.mainBet.betKind}))
                // Group the elements of Array based on `color` property
                .groupBy("betKind")
                // `key` is group's name (color), `value` is the array of objects
                .map((value, key) => ({ betKind: key, games: value }))
                .value();
            },

        },
        components: {
        },
    }
</script>
