<template>
    <div class="minigame-bet-lists">
        <div class="box-header">
            <i class="fas fa-list"></i> {{ _title }} 배팅내역
            <span @click="_exec_get" class="refresh">
                <i class="fas fa-sync-alt"></i>
                새로고침
            </span>
        </div>

        <div>
            <div class="minigame-bet-list title">
                <ul>
                    <li>배팅일자</li>
                    <li>종류</li>
                    <li>회차</li>
                    <li>배팅</li>
                    <li>배팅금액</li>
                    <li>결과</li>
                </ul>
            </div>
            <div v-for="(data, index) in betLists.data" :key="index" class="minigame-bet-list">
                <ul>
                    <li>{{ _showTime(data.createdAt) }}</li>
                    <li>{{ _showSportName(data.games[0].GameList.sportID) }}</li>
                    <li>{{ data.games[0].GameList.sportSubID }}회</li>
                    <li>{{ _showBet(data.games[0]) }} <span class="odds">({{ data.totalOdds }})</span></li>
                    <li>{{ _showCash(data.betCash) }}</li>
                    <li :class="_resultToClass(data.result)">{{ _showResult(data.result) }}</li>
                </ul>
            </div>
            <div class="empty" v-if="!isListLoaded">
                로딩중...
            </div>
            <div class="empty" v-else-if="betLists.data.length == 0">
                배팅내역이 없습니다.
            </div>
        </div>

        <pagination :pagination="this.betLists.meta" @paginate="_exec_get()"></pagination>
    </div>
</template>

<script>
    export default {

        data: function () {
            return {
                betLists: { data: [], meta: {current_page: 1}},
                isListLoaded : false,
            }
        },
        computed: {
            _title: function(){
                return getSparkCasinoName(this._path2, '');
            },
            _path2: function(){
                return location.pathname.split('/')[2];
            },
        },
        methods: {
            _showTime: function (_time) {
                return datetimeToMDHM(_time);
            },
            _showCash: function (_cash) {
                return numberWithComma(_cash);
            },
            _showBet: function (_game) {
                return getOddsName(_game.GameList.sportID, _game.betKind, _game.betTeam, '', '', _game.point, _game.etc);
            },
            _showSportName: function (_sportID) {
                return getSportName(_sportID);
            },
            _showResult: function(_result){
                if(_result == 1)
                    return '당첨';
                else if(_result == 2)
                    return '미당첨';
                else if(_result == 4)
                    return '무효';
                else if(_result == null)
                    return '대기중';
            },
            _resultToClass: function(_result){
                if(_result == null)
                    return "wait";
                else if(_result == 1){
                    return "hit";
                }else if(_result == 2){
                    return "nohit";
                }else if(_result == 4){
                    return "cancle";
                }else{
                    return "";
                }
            },
            _exec_get: function(isLoading = true) {
                if(isLoading)
                    showLoading();
                axios.get(`/${location.pathname.split('/')[1]}/betlist?page=` + this.betLists.meta.current_page)
                .then((response)=>{
                    //console.log(response);
                    this.betLists = response.data;
                    userData.cash = parseInt(response.data.cash);
                })
                .finally(() => {
                    hideLoading();
                    this.isListLoaded = true;
                });
            },
        },
        created: function(){
            this._exec_get(false);
        }
    }
</script>
