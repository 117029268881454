<template>
    <li class="main-menu" v-if="_isShow">
        <div class="main-content" @click.stop="visible = !visible">
            <span class="menu-badge">
                {{ _showCountSum(sport) }}
            </span>
            <p>
                <img :src="`/img/icon/sport/${index}.png`"/>
                {{ _showSportName(index) }}
                <!-- <a @click.stop="" :href="`/${_getPath1}/${index}`">바로가기</a>                                      -->
            </p>

        </div>
        <transition name="fade">
            <ul v-if="visible" >
                <li class="sub-menu" v-for="(league, index2) in sport" :key="index2">
                    <a :href="`/${_getPath1}/${index}/${league.leagueID}`">
                        <span class="menu-badge">
                            {{ league.count}}
                        </span>
                        <p>
                            <img width="18" :src="'/img/flag/league/' + (league.flag == null ? 'world.svg' : league.flag)" />
                            {{ league.leagueName }}
                        </p>
                    </a>
                </li>
            </ul>
        </transition>
    </li>
</template>


<script>
    export default {
        props: ['sport', 'index', 'type'],
        data: function () {
            return { visible : false, }
        },
        computed: {
             _getPath1: function () {
                if(this.type == 1)
                    return  'overseas';
                else if(this.type == 2)
                    return  'cross';
                else if(this.type == 3)
                    return  'special';
                else if(this.type == 4)
                    return  'special2';
                else if(this.type == 21)
                    return  'inplay';
            },
            _isShow: function(){
                let _noSports = NoSports.split(',');
                if(_noSports.includes(this.index))
                    return false;
                return true;
            },
        },
        methods: {
            _showSportName: function (_sport_id) {
                return getSportName(_sport_id);
            },
            _showCountSum: function (_sports) {
                return _.sumBy(_sports, function(sport) { return sport.count; })

            },
        },
        components: {
        },
    }
</script>
