<template>
<div class="gamelists">
    <div class="box-header"><i class="fas fa-stream"></i> {{ _title }} 배팅 리스트</div>
    <SlickSport></SlickSport>
    <div class="search">
        <div class="cboxs">
            <div class="cbox-wrap">
                <input type="checkbox" id="maintype1" value="1" v-model="maintype" @change="maintype_change">
                <label for="maintype1">승무패</label>
            </div>
            <div class="cbox-wrap">
                <input type="checkbox" id="maintype2" value="2" v-model="maintype" @change="maintype_change">
                <label for="maintype2">핸디캡</label>
            </div>
            <div class="cbox-wrap">
                <input type="checkbox" id="maintype3" value="3" v-model="maintype" @change="maintype_change">
                <label for="maintype3">언더오버</label>
            </div>
            <div class="cbox-wrap">
                <input type="checkbox" id="maintype4" value="4" v-model="maintype" @change="maintype_change">
                <label for="maintype4">기타</label>
            </div>
        </div>
        <input type="text" v-model.lazy="search_text" placeholder="리그명 또는 팀명"/>
        <button @click="_exec_get"><i class="fas fa-search"></i></button>
    </div>
    <div class="cross">
        <template v-for="(d, index) in _gameLists">
            <div :key="index" v-if="d.leagueName.name === (_gameLists[index - 1] == null ? '' : _gameLists[index - 1].leagueName.name) && d.startTime === (_gameLists[index - 1] == null ? '' : _gameLists[index - 1].startTime)" class="divider"></div>
            <GameList :key="d.ID" :data="d" :maintype="maintype" :prevLeagueName="_gameLists[index - 1] == null ? '' : _gameLists[index - 1].leagueName.name" :prevStartTime="_gameLists[index - 1] == null ? '' : _gameLists[index - 1].startTime" :plusSec="plusSec"></GameList>
        </template>
        <div class="empty" v-show="_gameLists.length == 0">
            진행중인 경기가 없습니다
        </div>
    </div>

    <pagination :pagination="this.data.meta" @paginate="_exec_get()"></pagination>
</div>
</template>

<script>

    import GameList from './List';
    import SlickSport from '../SlickSport';

    export default {
        props: ['plusSec'],
        data: function () {
            return {
                data, search_text: '', getting: false, maintype: [],
            }
        },
        components: {
            GameList, SlickSport
        },
        computed: {
            _title: function(){
                let path = location.pathname.split('/')[1];
                if(path == 'overseas')
                    return '해외형';
                else if(path == 'cross')
                    return '국내형 - 크로스';
                else if(path == 'special')
                    return '국내형 - 스페셜';
                else if(path == 'special2')
                    return '국내형 - 스페셜2';
            },
            _gameLists: function(){
                return _.filter(this.data.data, function(gameList) { return gameList.game_count >= 0; });;
            },
        },
        methods: {
             _exec_get: function() {
                if(!this.getting){
                    this.getting = true;
                    showLoading();
                    axios.get(`${location.pathname}?page=${this.data.meta.current_page}${this.search_text ? '&search_text='+this.search_text : ''}`)
                    .then((response)=>{
                        if(response.data.meta)
                            this.data = response.data;
                    })
                    .finally(() => {
                        this.getting = false;
                        hideLoading();
                        window.scrollTo(null, 0);
                    });
                }
            },
            maintype_change: function() {
                this.$cookie.set('cross.maintype', this.maintype);
            },
        },
        watch: {
            search_text: {
                handler: _.throttle(function() {
                    this.data.meta.current_page = 1;
                    this._exec_get();
                }, 150),
            },
        },
        created: function () {
            this.maintype = this.$cookie.get('cross.maintype');
            if(this.maintype == null)
                this.maintype = ['1', '2', '3', '4'];
            else
                this.maintype = this.maintype.split(',');

            let path = location.pathname.split('/')[1];
            let listenPath = null;
            if(path == 'overseas'){
                listenPath = '.overseas';
            }else if(path == 'cross' || path == 'special' || path == 'special2'){
                listenPath = '.cross';
            }

            if(listenPath){
                Echo.channel('bet.updated')
                .listen(listenPath, (e) => {
                    console.log(e);
                    _.forEach(e.games, (value) => {
                        console.log(value);
                        // let gameList =_.find(this.data.data, function(list) {
                        //     return _.some(list.games, function(game) {
                        //         return game.ID == value.ID;
                        //     });
                        // });
                        // if(gameList){
                        //     let game = _.find(gameList.games, function(game) { return game.ID == value.ID; });
                        //     game.oddsHome = value.oddsHome;
                        //     game.oddsDraw = value.oddsDraw;
                        //     game.oddsAway = value.oddsAway;
                        // }
                        // console.log(value);
                        _.forEach(this.data.data, (list1) => {
                            if(list1.game && list1.game.ID == value.ID){
                                list1.game.oddsHome = typeof value.oddsHome == 'number' ? value.oddsHome.toFixed(2) : value.oddsHome;
                                list1.game.oddsDraw = typeof value.oddsDraw == 'number' ? value.oddsDraw.toFixed(2) : value.oddsDraw;
                                list1.game.oddsAway = typeof value.oddsAway == 'number' ? value.oddsAway.toFixed(2) : value.oddsAway;
                            }
                            if(list1.games){
                                _.forEach(list1.games, function(list2) {
                                    if(list2.ID == value.ID){
                                        list2.oddsHome = typeof value.oddsHome == 'number' ? value.oddsHome.toFixed(2) : value.oddsHome;
                                        list2.oddsDraw = typeof value.oddsDraw == 'number' ? value.oddsDraw.toFixed(2) : value.oddsDraw;
                                        list2.oddsAway = typeof value.oddsAway == 'number' ? value.oddsAway.toFixed(2) : value.oddsAway;
                                    }
                                });
                            }
                        });
                        _.forEach(cartData.cart, function(list) {
                            if(list.game && list.game.ID == value.ID){
                                list.game.oddsHome = typeof value.oddsHome == 'number' ? value.oddsHome.toFixed(2) : value.oddsHome;
                                list.game.oddsDraw = typeof value.oddsDraw == 'number' ? value.oddsDraw.toFixed(2) : value.oddsDraw;
                                list.game.oddsAway = typeof value.oddsAway == 'number' ? value.oddsAway.toFixed(2) : value.oddsAway;
                            }
                        });
                    });
                });
            }

        }
    }
</script>
