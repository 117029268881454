<template>
    <div class="bottom-menu-mobile-wrap">
       <ul class="foot_menu">
			<li>
                <span class="ico_customer">
                    <a :href="this.$telegram" target="_blank">
                        <i v-if="this.$telegram.includes('kakao')" class="fas fa-comment"></i>
                        <i v-else class="fab fa-telegram"></i>
                    </a>
                </span>
            </li>
			<li><span class="ico_customer"><a href="/inquery"><i class="fas fa-comments"></i></a></span></li>
            <li @click="cartShow = !cartShow" ><span class="ico_customer" ><i class="fas fa-shopping-cart"></i></span> <span :class="_cartCount_length">{{ _cartCount }}</span></li>

             <!-- <div class="cart-count">{{ _cartCount }}</div> -->
            <li @click="sideMenuShow = !sideMenuShow">
                <span class="ico_customer">
                    <i class="fas fa-list-ul"></i>
                </span>
            </li>
		</ul>
        <transition name="slide-cart">
            <div v-show="cartShow" class="mobile-side-cart-wrap" @click="cartShow = false">
                <div class="mobile-side-cart" @click.stop="">
                    <cart></cart>
                </div>
            </div>
        </transition>
        <transition name="slide-cart">
            <div v-show="sideMenuShow" class="mobile-side-menu-wrap" @click="sideMenuShow = false">
                <div class="mobile-side-menu" @click.stop="">
                    <sidebar-menu></sidebar-menu>
                </div>
            </div>
        </transition>
    </div>
</template>

<style>
    .slide-cart-enter-active{
        transition: transform .2s ease-out;
    }
    .slide-cart-leave-active{
        transition: transform .2s ease-out;
    }
    .slide-cart-enter, .slide-cart-leave-to{
        width: 100%;
    }

    .slide-cart-enter-active .mobile-side-cart{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-cart-leave-active .mobile-side-cart{
        transition: transform .2s cubic-bezier(0.0, 1.0, 1.0, 0.8);
    }
    .slide-cart-enter .mobile-side-cart, .slide-cart-leave-to .mobile-side-cart{
        transform: translateX(260px);
    }
</style>

<script>

    export default {
        data: function () {
                return {
                    cartShow : false, cartData: cartData, cartBottomButtonVisible : false, userinfoShow: false,
                    sideMenuShow : false,
                }
            },
        computed: {
            path1: function(){
                return location.pathname.split('/')[1];
            },
            _cartCount: function(){
                return this.cartData.cart.length;
            },
            _cartCount_length: function(){
                if(this.cartData.cart.length > 0){
                    return 'cart-count';
                }else if(this.cartData.cart.length == 0){
                    return 'cart-count-none';
                }
            },
        },
        watch:{
        },
        mounted: function(){
        },
        methods: {
        },
        created () {
        },
        beforeDestroy () {
        },
        components: {
        },
    }
</script>
